import { Crs } from "./Crs";
import { GeoUtils } from "../GeoUtils";
import { SimpleCrs } from "./SimpleCrs";
import { Epsg6708 } from "./Epsg6708";

type Constructor = new (...args: any[]) => Crs;

export class CrsFactory {

    public static registry: Map<string, Constructor> = new Map([
        [GeoUtils.cityvuCrs("simple"), SimpleCrs],
        [GeoUtils.epsg(6708), Epsg6708]
    ]);

    public static create(code: string): Crs {
        const cTor = CrsFactory.registry.get(code);
        if (cTor === undefined) {
            throw Error("Missing CRS in registry");
        }
        return new cTor();
    }

}