import { Box3, EventDispatcher, Object3D, Points, Sphere } from "three";
import { PointCloudOctreeGeometryNode } from "./point-cloud-octree-geometry-node";
import { IPointCloudTreeNode } from "./types";

export class PointCloudOctreeNode extends EventDispatcher implements IPointCloudTreeNode {
  public geometryNode: PointCloudOctreeGeometryNode;
  public sceneNode: Points;
  public pcIndex: number | undefined = undefined;
  public boundingBoxNode: Object3D | null = null;
  public readonly children: (IPointCloudTreeNode | null)[];
  public readonly loaded = true;
  public readonly isTreeNode: boolean = true;
  public readonly isGeometryNode: boolean = false;

  public constructor(geometryNode: PointCloudOctreeGeometryNode, sceneNode: Points) {
    super();

    this.geometryNode = geometryNode;
    this.sceneNode = sceneNode;
    this.children = geometryNode.children.slice();
  }

  public dispose(): void {
    this.geometryNode.dispose();
  }

  public traverse(cb: (node: IPointCloudTreeNode) => void, includeSelf?: boolean): void {
    this.geometryNode.traverse(cb, includeSelf);
  }

  public get id() {
    return this.geometryNode.id;
  }

  public get name() {
    return this.geometryNode.name;
  }

  public get level(): number {
    return this.geometryNode.level;
  }

  public get isLeafNode(): boolean {
    return this.geometryNode.isLeafNode;
  }

  public get numPoints(): number {
    return this.geometryNode.numPoints;
  }

  public get index() {
    return this.geometryNode.index;
  }

  public get boundingSphere(): Sphere {
    return this.geometryNode.boundingSphere;
  }

  public get boundingBox(): Box3 {
    return this.geometryNode.boundingBox;
  }

  public get spacing() {
    return this.geometryNode.spacing;
  }
}
