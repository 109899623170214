import on from "gis3d/wf/core/On";
import run from "gis3d/wf/util/RuntimeUtils";

export class Evented {

    public listen(type: string, listener: Function): Function {
        return on.listen(this, type, listener, false, (target: any, type: string) => {
            return run.after(target, "on" + type, listener);
        });
    }

    public emit(type: string, event: any = null, ...args: any[]): boolean {
        return on.emit(this, type, event, args);
    }
}