export class Ws {
    private _socket!: WebSocket;
    public onMessage?: (ev: MessageEvent) => void;
    public onClose?: (ev: CloseEvent) => void;
    public onOpen?: (ev: Event) => void;
    public onError?: (ev: Event) => void;

    public constructor(readonly url: string) {}

    public open(): void {
        this._socket = new WebSocket(this.url);
        this.socket.onerror = (ev: Event) => {
            if (this.onError) this.onError(ev);
        };
        this.socket.onmessage = (ev: MessageEvent) => {
            if (this.onMessage) this.onMessage(ev);
        };
        this.socket.onclose = (ev: CloseEvent) => {
            if (this.onClose) this.onClose(ev);
        };
        this.socket.onopen = (ev: Event) => {
            if (this.onOpen) this.onOpen(ev);
        };
    }

    public get socket(): WebSocket {
        return this._socket;
    }

    public send(data: string | SharedArrayBuffer | ArrayBuffer | Blob | ArrayBufferView): void {
        this.socket.send(data);
    }

    public close(): void {
        this.socket.close();
    }
}
