import { Vector3 } from "three";
import ThreeUtils from "../three/ThreeUtils";

export class WktBuilder {
    public static polygon(data: Array<Vector3>, decimals: number = 4): string {
        let wkt = "POLYGON((";
        data.forEach((p, i) => {
            wkt += ThreeUtils.v3str(p, decimals);
            wkt += ", ";
        });
        wkt += ThreeUtils.v3str(data[0], decimals);
        wkt += "))";
        return wkt;
    }

    public static linestring(data: Array<Vector3>, decimals: number = 4): string {
        let wkt = "LINESTRING(";
        data.forEach((p, i) => {
            wkt += ThreeUtils.v3str(p, decimals);
            if (i < data.length - 1) {
                wkt += ", ";
            }
        });
        wkt += ")";
        return wkt;
    }

    public static point(data: Array<Vector3>, decimals: number = 4): string {
        let wkt = "POINT(";
        wkt += ThreeUtils.v3str(data[0], decimals);
        wkt += ")";
        return wkt;
    }
}
