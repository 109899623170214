import dom from "gis3d/wf/util/DomUtils";
import ui from "gis3d/wf/ui/style/UiStyle";

import { BaseUiComponent } from "gis3d/wf/ui/BaseUiComponent";

export class Label extends BaseUiComponent {
    private _forId:string | null = null;
    private _formId:string | null = null;
    private _text:string = "";

    constructor() {
        super();
        this.domElementType = "label";
    }

    public render() : void {
        super.render();
        if (this.isInitialized()) {
            if (this.forId != null) {
                dom.setAttr(this.domNode!, "for", this.forId);
            } else {
                dom.removeAttr(this.domNode!, "for");
            }
            if (this.formId != null) {
                dom.setAttr(this.domNode!, "form", this.formId);
            } else {
                dom.removeAttr(this.domNode!, "form");
            }
            dom.set(this.domNode!, this.text);
        }
    }

	public get forId(): string  {
        return this._forId!;
	}
    
	public get formId(): string  {
		return this._formId!;
	}

	public get text(): string  {
		return this._text;
	}

	public set forId(value: string ) {
        this._forId = value;
        this.render();
    }

	public set formId(value: string ) {
        this._formId = value;
        this.render();
	}

	public set text(value: string ) {
        this._text = value;
        this.render();
	}
    
    
}