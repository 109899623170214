import { TreeNode } from "gis3d/wf/data/TreeNode";

export abstract class TreeStore<T extends TreeNode = TreeNode> {
    public abstract root() : Promise<T>;
    public abstract children(node:T) : Promise<Array<T>>;
    public abstract refresh() : void;

    public update(node:T) : Promise<void> {
        return new Promise<void>((resolve:Function, reject:Function) => {
            resolve();
        });
    }
    
    public onRefresh: () => void = () => {};
    public onGet: (value:T) => void = () => {};
}