import { Floating } from "gis3d/wf/ui/widget/Floating";

import ui from "gis3d/wf/ui/style/UiStyle";
import dom from "gis3d/wf/util/DomUtils";
import fa from "scss/font-awesome.scss";
import On from "gis3d/wf/core/On";
export class FloatingCard extends Floating {
    private _header!: HTMLElement;
    private _body!: HTMLElement;
    private _closeButton!: HTMLElement;
    private _title: string = "";
    private _closable: boolean = true;
    // close callback
    public onClose!: () => void;
    public prepareBuildOptions(): void {
        super.prepareBuildOptions();
        this.domElementOptions.classes!.push(ui.Card.card);
    }

    public build(): void {
        super.build();
        // header
        this.header = dom.el("h6", {
            classes: [ui.Card.cardHeader],
        });
        dom.append(this.domNode!, this.header);
        dom.setText(this.header, this.title);
        this.draggableElement = this.header;
        this.closeButton = dom.el("i", {
            classes: [fa.fa, fa.faTimes, ui.floatRight],
        });
        if (this.closable) {
            dom.append(this.header, this.closeButton);
        }
        On.listen(this.closeButton, "click", () => {
            this.displayed = false;
            if (this.onClose != null) {
                this.onClose();
            }
        });
        // body
        this.body = dom.el("div", {
            classes: [ui.Card.cardBody],
        });
        dom.append(this.domNode!, this.body);
        this.childrenHolderNode = this.body;
    }

    public get header(): HTMLElement {
        return this._header;
    }

    public set header(value: HTMLElement) {
        this._header = value;
    }

    public get title(): string {
        return this._title;
    }

    public set title(value: string) {
        this._title = value;
        if (this.header != null) {
            dom.setText(this.header, this.title);
        }
    }

    public get body(): HTMLElement {
        return this._body;
    }

    public set body(value: HTMLElement) {
        this._body = value;
    }

    public get closable(): boolean {
        return this._closable;
    }

    public set closable(value: boolean) {
        this._closable = value;
        if (this.header != null) {
            if (value) {
                dom.append(this.header, this.closeButton);
            } else {
                dom.remove(this.header, this.closeButton);
            }
        }
    }

    public get closeButton(): HTMLElement {
        return this._closeButton;
    }

    public set closeButton(value: HTMLElement) {
        this._closeButton = value;
    }
}
