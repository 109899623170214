import { SceneDefinitionDataSource } from "../scene/definition/SceneDefinitionDataSource";
import { DataSource } from "./DataSource";
import { DataSourceType } from "./DataSourceType";
import { PointCloudDataSource } from "./PointCloudDataSource";
import { TiledPointCloudDataSource } from "./TiledPointCloudDataSource";
import { PhotosphereDataSource } from "./PhotosphereDataSource";

export class DataSourceFactory {
    public static create(definition: SceneDefinitionDataSource): DataSource | null {
        let dataSource = null;
        switch (definition.type) {
            case DataSourceType.POINTCLOUD:
                dataSource = new PointCloudDataSource(definition);
                break;
            case DataSourceType.TILED_POINTCLOUD:
                dataSource = new TiledPointCloudDataSource(definition);
                break;
            case DataSourceType.PHOTOSPHERE:
                dataSource = new PhotosphereDataSource(definition);
                break;
        }
        return dataSource;
    }
}
