import { Mesh, MeshLambertMaterial, SphereGeometry, CubeGeometry, Color } from "three";

export class Pointer extends Mesh {
    public constructor() {
        super();
        this.geometry = new SphereGeometry(0.1, 8, 8, 8);
        this.material = new MeshLambertMaterial({
            color: new Color(0xFFFF00),
            emissive: new Color(0xFFFF00),
            emissiveIntensity: 0.3
        });
        this.visible = false;
    }
}
