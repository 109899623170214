import { BaseUiComponent } from "gis3d/wf/ui/BaseUiComponent";
import dom from "gis3d/wf/util/DomUtils";

export interface InputValue<V = any> {
    value: V | null;
    reset() : void;
}

export abstract class InputUiComponent<V, T extends HTMLElement = HTMLElement> extends BaseUiComponent<T> implements InputValue<V> {
    private _disabled: boolean = false;
    private _readonly: boolean = false;
    private _placeholder: string | null = null;

    public abstract value : V | null;
    public abstract reset() : void;
    
    public get disabled(): boolean {
        return this._disabled!;
    }

    public set disabled(disabled: boolean) {
        this._disabled = disabled;
        this.render();
    }

    public get readonly(): boolean {
        return this._readonly!;
    }

    public set readonly(readonly: boolean) {
        this._readonly = readonly;
        this.render();
    }

    public get placeholder(): string | null {
        return this._placeholder;
    }

    public set placeholder(value: string | null) {
        this._placeholder = value;
        this.render();
    }

    public render(): void {
        super.render();
        this.renderCommonItems();
    }

    protected renderCommonItems(): void {
        if (this.placeholder) {
            dom.setAttr(this.domNode!, "placeholder", this.placeholder);
        }

        if (this.readonly) {
            dom.setAttr(this.domNode!, "readonly");
        } else {
            dom.removeAttr(this.domNode!, "readonly");
        }
    }
}
