import dom from "gis3d/wf/util/DomUtils";
import runtime from "gis3d/wf/util/RuntimeUtils";

import { Box } from "gis3d/wf/ui/geom/Box";
import { Size } from "gis3d/wf/ui/geom/Size";
import { BaseUiComponent } from "gis3d/wf/ui/BaseUiComponent";
import { UiComponent } from "gis3d/wf/ui/UiComponent";
import { Viewport } from "gis3d/wf/ui/Viewport";

export abstract class UiContainer extends BaseUiComponent {
    protected childrenHolderNode! : HTMLElement;
    protected maxChildrenNumber : number = Infinity;
    public children : Array<UiComponent>;
 
    public constructor() {
        super();
        this.children = [];
    }

    public build() : void {
        super.build();
        this.childrenHolderNode = this._domNode!;
    }

    public startup() : void {
        if (this.isStarted()) {
            return;
        }
        super.startup();
        // startup children
        for (let child of this.children) {
            if (!child.isStarted()) {
                child.startup();
            }
        }        
        if (this.parent == null) {
            // we have no ancestors so we have to call resize ourselves when Viewport is resized
            Viewport.get().listen(Viewport.Event.Resize, runtime.bind(this, this.resize));
        }
    }

    public addChild(child:UiComponent, idx:number | null = null) : void {
        if (this.children.length >= this.maxChildrenNumber) {
            console.log("Cannot have more children than ", this.maxChildrenNumber);
            return;
        }
        if (!child.isInitialized()) {
            child.init();
        }
        if (idx == null) {
            this.children.push(child);
        } else {
            this.children.splice(idx, 0, child);
        }
        child.parent = this;
        child.attach(this.childrenHolderNode);
        
    }

    public removeChild(child:UiComponent) : void {
        this.children.forEach((item, index) => {
            if (item === child) {
                this.children.splice(index, 1);
                item.detach();
                item.parent = null;
            }
        });
    }

    public resize(box : Box | null = null) : Size | null {
        let sz : Size = super.resize(box)!;
        this.layout();
        // check children sizing
        const b = new Box();
        let maxH = sz.h!;
        let maxW = sz.w!;
        this.children.forEach(c => {
            dom.marginBox(c.domNode!, null, b, false);
            maxW = Math.max(maxW, b.w!);
            maxH = Math.max(maxH, b.h!);
        });
        sz.h = maxH;
        sz.w = maxW;

        return sz;
    }

    public layout() : void {
    }
}
