import { Crs } from "gis3d/cityvu/core/geo/crs/Crs";
import { Projection } from "gis3d/cityvu/core/geo/projection/Projection";
import { Vector3 } from "three";
import { AffineTransformation } from "../../geometry/AffineTransformation";
import { Bounds } from "../../geometry/Bounds";
import RuntimeUtils from "gis3d/wf/util/RuntimeUtils";
import { MeasurementUtils } from "../../measure/MeasurementUtils";

export abstract class BaseCrs implements Crs {
    private _projection!: Projection;
    private _code!: string;
    private _transformation!: AffineTransformation;
    private _projectedBounds!: Bounds;

    // from GEO to OPENGL
    public coordsToPoint(inp: Vector3, reuse?: Vector3): Vector3 {
        if (!reuse) {
            RuntimeUtils.useBufferMessage();
            reuse = new Vector3();
        }
        // reuse.set(inp.x, inp.z, -inp.y);
        this.transformation.transform(inp, reuse);
        return reuse;
    }

    // from OPENGL to GEO
    public pointToCoords(inp: Vector3, reuse?: Vector3): Vector3 {
        if (!reuse) {
            RuntimeUtils.useBufferMessage();
            reuse = new Vector3();
        }
        //reuse.set(inp.x, -inp.z, inp.y);

        this.transformation.untransform(inp, reuse);
        return reuse;
    }

    public project(inp: Vector3, reuse?: Vector3): Vector3 {
        return this.projection.project(inp, reuse);
    }

    public unproject(inp: Vector3, reuse?: Vector3): Vector3 {
        return this.projection.unproject(inp, reuse);
    }

    public distance(a: Vector3, b: Vector3): number {
        return MeasurementUtils.distance(a, b);
    }

    public get transformation(): AffineTransformation {
        return this._transformation;
    }

    public set transformation(value: AffineTransformation) {
        this._transformation = value;
    }

    public get code(): string {
        return this._code;
    }

    public set code(value: string) {
        this._code = value;
    }

    public get projection(): Projection {
        return this._projection;
    }

    public set projection(value: Projection) {
        this._projection = value;
    }

    public get projectedBounds(): Bounds {
        return this._projectedBounds;
    }

    public set projectedBounds(value: Bounds) {
        this._projectedBounds = value;
    }
}