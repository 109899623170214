import dom from "gis3d/wf/util/DomUtils";
import ui from "gis3d/wf/ui/style/UiStyle";

import jq from "jquery";

import { UiContainer } from "gis3d/wf/ui/UiContainer";
import { Button } from "gis3d/wf/ui/widget/Button";
import { Box } from "gis3d/wf/ui/geom/Box";
import { Size } from "gis3d/wf/ui/geom/Size";

export enum ModalSize {
    Normal = 500,
    Large = 1000,
    Small = 300,
}

export class Modal extends UiContainer {
    private _backdrop: boolean | "static" = true;
    private _hasHeader: boolean = true;
    private _hasFooter: boolean = false;
    private _title: string | null = null;
    private _hasCloseButton: boolean = true;
    private _centered: boolean = true;
    private _size: ModalSize = ModalSize.Normal;
    private _customSize: Size | null = null;
    private _fade: boolean = true;
    private _focus: boolean = true;
    private _closeWithEsc: boolean = true;

    public onHide = () : void => {};
    public onShow = () : void => {};

    protected modalContent!: HTMLElement;
    protected modalDialog!: HTMLElement;
    protected modalHeader!: HTMLElement | null;
    protected modalTitle!: HTMLElement | null;
    protected modalCloseButton!: Button;
    protected modalBody!: HTMLElement;
    protected modalFooter!: HTMLElement | null;

    public constructor() {
        super();
        this.maxChildrenNumber = 1;
    }

    public get title(): string | null {
        return this._title;
    }

    public set title(title: string | null) {
        this._title = title;
    }

    public get customSize(): Size | null {
        return this._customSize;
    }

    public set customSize(customSize: Size | null) {
        this._customSize = customSize;
    }

    public get hasCloseButton(): boolean {
        return this._hasCloseButton;
    }

    public set hasCloseButton(hasCloseButton: boolean) {
        this._hasCloseButton = hasCloseButton;
    }

    public get closeWithEsc(): boolean {
        return this._closeWithEsc;
    }

    public set closeWithEsc(closeWithEsc: boolean) {
        this._closeWithEsc = closeWithEsc;
    }

    public get focus(): boolean {
        return this._focus;
    }

    public set focus(focus: boolean) {
        this._focus = focus;
    }

    public get backdrop(): boolean | "static" {
        return this._backdrop;
    }

    public set backdrop(backdrop: boolean | "static") {
        this._backdrop = backdrop;
    }

    public get hasHeader(): boolean {
        return this._hasHeader;
    }

    public get centered(): boolean {
        return this._centered;
    }

    public set centered(centered: boolean) {
        this._centered = centered;
    }

    public get fade(): boolean {
        return this._fade;
    }

    public set fade(fade: boolean) {
        this._fade = fade;
    }

    public get size(): ModalSize {
        return this._size;
    }

    public set size(size: ModalSize) {
        this._size = size;
    }

    public set hasHeader(hasHeader: boolean) {
        this._hasHeader = hasHeader;

        if (this.isStarted()) {
            if (this.hasHeader) {
                this.createHeader();
            } else {
                this.destroyHeader();
            }
        }
    }

    public get hasFooter(): boolean {
        return this._hasFooter;
    }

    public set hasFooter(hasFooter: boolean) {
        this._hasFooter = hasFooter;
        if (this.isStarted()) {
            if (this.hasFooter) {
                this.createFooter();
            } else {
                this.destroyFooter();
            }
        }
    }

    public prepareBuildOptions(): void {
        this.domElementOptions = {
            classes: [ui.p("Modal"), ui.Modal.modal],
            attrs: new Map([
                ["role", "dialog"],
                ["tabindex", "-1"],
                ["data-backdrop", String(this.backdrop)],
                ["data-focus", String(this.focus)],
                ["data-keyboard", String(this.closeWithEsc)],
            ]),
        };
        if (this.fade) {
            this.domElementOptions.classes!.push(ui.fade);
        }
    }

    protected setPosition(): void {
        if (this.centered) {
            dom.addClass(this.modalDialog, ui.Modal.modalDialogCentered);
        }
    }

    public build(): void {
        super.build();
        this.modalDialog = dom.el("div", { classes: [ui.Modal.modalDialog], attrs: new Map([["role", "document"]]) });
        this.setPosition();
        switch (this.size) {
            case ModalSize.Large:
                dom.addClass(this.modalDialog, ui.Modal.modalLarge);
                break;
            case ModalSize.Small:
                dom.addClass(this.modalDialog, ui.Modal.modalSmall);
                break;
        }

        if (this.customSize && this.customSize.w) {
            dom.setCss(this.modalDialog, "maxWidth", this.customSize.w + "px");
        }
        dom.append(this.domNode!, this.modalDialog);

        this.modalContent = dom.el("div", { classes: [ui.Modal.modalContent] });
        dom.append(this.modalDialog!, this.modalContent);
        if (this.hasHeader) {
            this.createHeader();
        }
        this.createBody();
        if (this.hasFooter) {
            this.createFooter();
        }
    }

    protected createHeader(): void {
        this.modalHeader = dom.el("div", {
            classes: [ui.Modal.modalHeader],
        });

        this.modalTitle = dom.el("h5", {
            classes: [ui.Modal.modalTitle],
        });
        dom.append(this.modalHeader, this.modalTitle);
        if (this.title != null) {
            dom.set(this.modalTitle, this.title);
        }

        this.modalCloseButton = new Button("close");
        this.modalCloseButton.init();
        dom.setData(this.modalCloseButton.domNode!, "dismiss", "modal");
        let x = dom.el("span", {
            attrs: new Map([["aria-hidden", "true"]]),
        });
        dom.set(x, "&times;");
        dom.append(this.modalCloseButton.domNode!, x);
        if (this.hasCloseButton) {
            dom.append(this.modalHeader, this.modalCloseButton.domNode!);
        }

        dom.prepend(this.modalContent, this.modalHeader);
    }

    protected destroyHeader(): void {
        if (this.modalHeader) {
            dom.remove(this.modalContent, this.modalHeader);
            this.modalHeader = null;
        }
    }

    protected createBody(): void {
        this.modalBody = dom.el("div", {
            classes: [ui.Modal.modalBody],
        });

        if (this.customSize != null && this.customSize.h != null) {
            dom.setCss(this.modalBody, "minHeight", this.customSize.h + "px");
            dom.setCss(this.modalBody, "height", this.customSize.h + "px");
            dom.setCss(this.modalBody, "overflowY", "auto");
        }
        
        dom.append(this.modalContent, this.modalBody);
        this.childrenHolderNode = this.modalBody;
    }

    protected createFooter(): void {
        this.modalFooter = dom.el("div", {
            classes: [ui.Modal.modalFooter],
        });

        dom.append(this.modalContent, this.modalFooter);
    }

    protected destroyFooter(): void {
        if (this.modalFooter) {
            dom.remove(this.modalContent, this.modalFooter);
            this.modalFooter = null;
        }
    }

    public startup(): void {
        super.startup();
        this.startupModal();
    }

    protected startupModal(): void {
        let m = jq(this.domNode!);
        m.modal({
            show: false,
        });
        m.on("hide.bs.modal", e => {
            this.beforeHide(e);
        });
        m.on("show.bs.modal", e => {
            this.beforeShow(e);
        });
    }

    public show(): void {
        if (!this.isAttached()) {
            this.attach(dom.body());
        }
        if (!this.isStarted()) {
            this.startup();
        }
        this.showModal();
    }

    public hide(): void {
        if (this.isStarted()) {
            this.hideModal();
        }
    }

    protected beforeShow(e: any): void {
        this.onShow();
    }

    protected beforeHide(e: any): void {
        this.onHide();}

    protected hideModal(): void {
        jq(this.domNode!).modal("hide");
    }

    protected showModal(): void {
        jq(this.domNode!).modal("show");
    }

    protected canBeClosed(): boolean {
        return true;
    }

    public close(): void {
        if (!this.canBeClosed()) {
            return;
        }
    }

    protected resizeModal(): void {
        jq(this.domNode!).modal("handleUpdate");
    }

    public resize(box: Box | null = null): Size | null {
        if (box != null) {
            dom.setBox(this.domNode!, box);
        }
        this.resizeModal();
        return dom.marginBox(this.domNode!);
    }
}
