
export class Advices {
    public before : Array<Function> = [];
    public around : Array<Function> = [];
    public after : Array<Function> = [];
}

export interface AdvisedFunction extends Function {
    advices? : Advices;
    originalMethod? : Function;
}

export default class Aspect {

    protected static getAdvisedFunction(context: any, method: string) : AdvisedFunction {
        // if method does not exists create a placeholder
        if (context[method] == null) {
            context[method] = (...args : any[]) => {};
        }
        // if method is not advised
        if (context[method].advices == null) {
            // this is not an AdvisedFunction, let's wrap it
            const advisedFunction : AdvisedFunction = (...args : any[]) => {
                let result: any;
                let advices : Advices = advisedFunction.advices!;
                // before
                for (let beforeAdvice of advices.before) {
                    beforeAdvice(...args);
                }
                // around
                const aroundCallStack = [(...stackArgs: any[]) => advisedFunction.originalMethod!(...stackArgs)];
                for (let aroundAdvice of advices.around) {
                    const previousFunction = aroundCallStack[aroundCallStack.length - 1];
                    aroundCallStack.push((...stackArgs: any[]) => aroundAdvice(...stackArgs, previousFunction));
                }
                result = (aroundCallStack.pop()!)(...args);
                // after
                for (let afterAdvice of advices.after) {
                    const newResult = afterAdvice(...args, result);
                    // check for void
                    if (newResult !== undefined) {
                        result = newResult;
                    }
                }
                return result;

            };
            advisedFunction.advices = new Advices();
            advisedFunction.originalMethod = context[method].bind(context);
            // replace current method
            context[method] = advisedFunction;
        }
        return context[method];
    }

    protected static removeAdvice(advices: Array<Function>, advice: Function) : boolean {
        const adviceIndex = advices.indexOf(advice);
        if (adviceIndex !== -1) {
            advices.splice(adviceIndex, 1);
            return true;
        }
        return false;
    }


    public static readonly after = (context: any, method: string, advice: Function) : Function => {
        const advisedFunction = Aspect.getAdvisedFunction(context, method);
        let advices = advisedFunction.advices!.after;
        advices.push(advice);
        return () => {
            Aspect.removeAdvice(advices, advice);
        };
    }

    public static readonly around = (context: any, method: string, advice: Function) : Function => {
        const advisedFunction = Aspect.getAdvisedFunction(context, method);
        let advices = advisedFunction.advices!.around;
        advices.push(advice);
        return () => {
            Aspect.removeAdvice(advices, advice);
        };
    }

    public static readonly before = (context: any, method: string, advice: Function) : Function => {
        const advisedFunction = Aspect.getAdvisedFunction(context, method);
        let advices = advisedFunction.advices!.before;
        advices.push(advice);
        return () => {
            Aspect.removeAdvice(advices, advice);
        };
    }

}