import num from "gis3d/wf/util/NumberUtils";
import { DefaultGuiOptions, GuiOptions } from "./gui/GuiOptions";
import { StatsOptions } from "gis3d/wf/util/stats/StatsOptions";

export interface DataChannelOptions {
    enabled: boolean;
    autostart: boolean;
    signallingUrl?: string;
    canSendMeasure: boolean;

    userId?: string | number;
    app?: string;
    windowId?: string;

    connectToApp?: string;
    connectToUserId?: string | number;
    connectToWindowId?: string | null;
}

export type CityvuStatsOptions = {
    enabled: boolean;
    showPanel: boolean;
    options: StatsOptions;
};

export class CityvuOptions {
    public static APP_NAME: string = "cityvu";
    public canCopyMeasure: boolean = true;
    public language: string = "en";
    public token: string | null = null;

    public dataChannel: DataChannelOptions = {
        enabled: false,
        autostart: true,
        canSendMeasure: true,
        app: CityvuOptions.APP_NAME,
        windowId: num.createUuid(),
    };

    public stats: CityvuStatsOptions = {
        enabled: false,
        showPanel: true,
        options: {
            fps: true,
            timePerFrame: true,
            memory: true,
        },
    };

    public gui: GuiOptions = new DefaultGuiOptions();

    public static parse(data: string): CityvuOptions {
        const opts = new CityvuOptions();
        const user = JSON.parse(data) as CityvuOptions;
        // DATA CHANNEL
        if (user.dataChannel != null) {
            const dc = user.dataChannel;
            if (dc.enabled != null) {
                opts.dataChannel.enabled = dc.enabled;
            }
            if (dc.autostart != null) {
                opts.dataChannel.autostart = dc.autostart;
            }
            if (dc.signallingUrl != null) {
                opts.dataChannel.signallingUrl = dc.signallingUrl;
            }
            if (dc.canSendMeasure != null) {
                opts.dataChannel.canSendMeasure = dc.canSendMeasure;
            }
            if (dc.userId != null) {
                opts.dataChannel.userId = dc.userId;
            }
            if (dc.connectToApp != null) {
                opts.dataChannel.connectToApp = dc.connectToApp;
            }
            if (dc.connectToUserId != null) {
                opts.dataChannel.connectToUserId = dc.connectToUserId;
            }
            if (dc.connectToWindowId !== undefined) {
                opts.dataChannel.connectToWindowId = dc.connectToWindowId;
            }
        }

        // CONTROLS
        if (user.gui.controls != null) {
            opts.gui.controls = user.gui.controls;
        }
        if (user.gui.enabledControls != null && opts.gui.enabledControls) {
            const uec = user.gui.enabledControls;
            if (uec.constrained != null) {
                opts.gui.enabledControls.constrained = uec.constrained;
            }
            if (uec.earth != null) {
                opts.gui.enabledControls.earth = uec.earth;
            }
            if (uec.fps != null) {
                opts.gui.enabledControls.fps = uec.fps;
            }
            if (uec.orbit != null) {
                opts.gui.enabledControls.orbit = uec.orbit;
            }
        }
        if (user.gui.selectedControls != null) {
            opts.gui.selectedControls = user.gui.selectedControls;
        }
        // MEASUREMENTS
        if (user.gui.measurements != null) {
            opts.gui.measurements = user.gui.measurements;
        }
        if (user.gui.enabledMeasurements != null && opts.gui.enabledMeasurements) {
            const em = user.gui.enabledMeasurements;
            if (em.angle != null) {
                opts.gui.enabledMeasurements.angle = em.angle;
            }
            if (em.area != null) {
                opts.gui.enabledMeasurements.area = em.area;
            }
            if (em.info != null) {
                opts.gui.enabledMeasurements.info = em.info;
            }
            if (em.line != null) {
                opts.gui.enabledMeasurements.line = em.line;
            }
        }
        if (user.gui.userSettings != null) {
            opts.gui.userSettings = user.gui.userSettings;
        }
        // TOOLS
        if (user.gui.tools != null && opts.gui.tools) {
            const ut = user.gui.tools;
            if (ut.compass != null) {
                opts.gui.tools.compass = ut.compass;
            }
            if (ut.layerSelector != null) {
                opts.gui.tools.layerSelector = ut.layerSelector;
            }
            if (ut.navigator != null) {
                opts.gui.tools.navigator = ut.navigator;
            }
            if (ut.info != null) {
                opts.gui.tools.info = ut.info;
            }
        }

        if (user.canCopyMeasure != null) {
            opts.canCopyMeasure = user.canCopyMeasure;
        }

        if (user.language != null) {
            opts.language = user.language;
        }

        if (user.token != null) {
            opts.token = user.token;
        }

        if (user.stats != null) {
            const s = user.stats;
            if (s.enabled != null) {
                opts.stats.enabled = s.enabled;
            }

            if (s.showPanel != null) {
                opts.stats.showPanel = s.showPanel;
            }

            if (s.options != null) {
                const o = s.options;
                if (o.fps != null) {
                    opts.stats.options.fps = o.fps;
                }
                if (o.memory != null) {
                    opts.stats.options.memory = o.memory;
                }
                if (o.timePerFrame != null) {
                    opts.stats.options.timePerFrame = o.timePerFrame;
                }
            }
        }

        return opts;
    }
}
