import I18N from "gis3d/wf/i18n/I18N";
import MouseUtils from "gis3d/wf/util/MouseUtils";
import { AppEvent } from "../../event/AppEvent";
import { ClickData } from "../../event/ClickData";
import { InputManagerEvent } from "../../event/InputManager";
import { InfoMeasure } from "../../measure/InfoMeasure";
import { MeasureType } from "../../measure/MeasureType";
import { SortedPickResults } from "../scene/picker/SortedPickResults";
import { Scene } from "../scene/Scene";
import ThreeUtils from "../ThreeUtils";
import { EventedMeasurementTool } from "./EventedMeasurementTool";

export class InfoTool extends EventedMeasurementTool {
    public constructor(scene?: Scene) {
        super(scene);
        this.message = I18N.i("cityvu.core.measurementTool.infoTool");
    }

    public getMeasureType(): MeasureType {
        return MeasureType.INFO;
    }

    protected attachEvents(): void {
        super.attachEvents();
        this.addEventListener(this.click, InputManagerEvent.CLICK);
    }

    public click(appEvent: AppEvent): void {
        const clickData = appEvent.value as ClickData;

        if (this.hoveredMarker == null) {
            if (MouseUtils.isLeft(clickData.buttons)) {
                this.pickRequest.normalizedCoords = ThreeUtils.normalize2dCoordinates(clickData.point, this.scene.size.w!, this.scene.size.h!);
                const results = this.picker.pick(this.pickRequest);
                const hit = new SortedPickResults(results).first();
                if (hit) {
                    const pair = this.createMeasure<InfoMeasure>();
                    pair.measure.coords = [hit.intersection.point.clone()];
                    pair.measure.data = hit.intersection.data;

                    const marker = pair.measureObject.marker();
                    marker.position.copy(hit.intersection.point);
                    pair.measureObject.markers.add(marker);

                    this.measures3dScene.addMeasureObject(pair.measureObject);
                    this.broker.add(pair.measure);
                }
            }
        }
    }
}
