import { BasicScene } from "gis3d/cityvu/core/three/scene/BasicScene";
import { BoxGeometry, Color, Mesh, MeshLambertMaterial } from "three";
import { SimpleCrs } from "../core/geo/crs/SimpleCrs";
import { Layer3d } from "../core/three/scene/Layer3d";
import { Scene } from "../core/three/scene/Scene";
import { Experiment } from "./Experiment";
import { CrsFactory } from "../core/geo/crs/CrsFactory";
import { GeoUtils } from "../core/geo/GeoUtils";

class ExperimentScene extends BasicScene {
    private geometry!: BoxGeometry;
    private material!: MeshLambertMaterial;
    private obj!: Mesh;
    private rx: number = 0 * 0.001;
    private ry: number = 0 * 0.002;
    private vel: number[] = [0, 0, 0];

    constructor() {
        super();
        this.crs = new SimpleCrs();
    }

    public init(): void {
        super.init();
        this.crs = CrsFactory.create(GeoUtils.cityvuCrs("simple"));
        this.geometry = new BoxGeometry(30, 30, 30);

        this.material = new MeshLambertMaterial({
            color: new Color(0xff0000),
        });

        this.obj = new Mesh(this.geometry, this.material);

        const layer = new Layer3d();
        layer.name = "Cube";
        layer.add(this.obj);
        this.add(layer);

        this.obj.position.x = 0;
        this.obj.position.y = 50;
        this.obj.position.z = 0;
    }

    public updateScene(delta: number): void {
        super.updateScene(delta);

        this.obj.rotation.x += this.rx;
        this.obj.rotation.y += this.ry;
        this.obj.position.x += this.vel[0];
        this.obj.position.y += this.vel[1];
        this.obj.position.z += this.vel[2];
    }
}

export default class SimpleCubeExperiment implements Experiment {
    public scene: Scene = new ExperimentScene();
}
