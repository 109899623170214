import dom from "gis3d/wf/util/DomUtils";
import uiStyle from "gis3d/wf/ui/style/UiStyle";
import run from "gis3d/wf/util/RuntimeUtils";

import { UiContainer } from "gis3d/wf/ui/UiContainer";
import { UiComponent } from "gis3d/wf/ui/UiComponent";
import { Pane } from "gis3d/wf/ui/layout/Pane";
import { Region } from "gis3d/wf/ui/layout/Region";
import { Splitter } from "gis3d/wf/ui/layout/Splitter";
import { Box } from "gis3d/wf/ui/geom/Box";
import { Size } from "gis3d/wf/ui/geom/Size";
import { RegionAwareUiComponent } from "gis3d/wf/ui/layout/RegionAwareUiComponent";

export class Filler extends UiContainer {
    private readonly topBottomPriority: number;
    private readonly leftRightPriority: number;

    public constructor(readonly topFirst: boolean = true) {
        super();
        this.topBottomPriority = topFirst ? 1 : 0;
        this.leftRightPriority = topFirst ? 0 : 1;
    }

    public build() {
        this.domElementOptions.classes = [uiStyle.p("Filler"), uiStyle.Display.flex];
        super.build();
    }

    public startup() {
        this.createSplitters();
        super.startup();
    }

    protected createSplitters(): void {
        let childrenCopy: Array<UiComponent> = this.children.slice(0);
        for (let child of childrenCopy) {
            if (child instanceof Pane) {
                let pane: Pane = child;
                if (pane.resizable) {
                    const splitter = new Splitter(pane.region);
                    const paneIndex = this.children.indexOf(pane);
                    let splitterIndex = paneIndex + 1;
                    splitter.pane = pane;
                    splitter.live = pane.liveSplitter;
                    pane.splitter = splitter;
                    this.addChild(splitter, splitterIndex);
                }
            } else {
                console.warn("Found child in Filler which is not a Pane. Ignoring...");
            }
        }
    }

    protected isRegionAware(item: UiComponent): item is RegionAwareUiComponent {
        return (<RegionAwareUiComponent>item).region !== undefined;
    }

    public layout() {
        let items: Array<RegionAwareUiComponent> = [];
        for (let child of this.children) {
            if (this.isRegionAware(child) && child.displayed === true) {
                items.push(child);
            }
        }
        if (items.length > 0) {
            // order by region and priority
            let itemsWithPriority = items.map((item: RegionAwareUiComponent, idx: number) => {
                return {
                    item: item,
                    priority: [
                        item.region == Region.Center ? Infinity : (item.region > Region.Bottom ? this.topBottomPriority : this.leftRightPriority),
                        idx
                    ]
                };
            });
            itemsWithPriority.sort((a, b) => {
                for (let i = 0; i < a.priority.length; i++) {
                    if (a.priority[i] != b.priority[i]) {
                        return a.priority[i] - b.priority[i];
                    }
                }
                return 0;
            });

            let orderedItems = itemsWithPriority.map(p => p.item);
            // filter center
            let centerPane: RegionAwareUiComponent = orderedItems.pop()!;
            if (centerPane.region != Region.Center) {
                console.warn("Region for pane is not Center, forcing...");
                centerPane.region = Region.Center;
            }
            let box: Box = dom.marginBox(this.domNode!);
            // layout other region first
            for (let p of orderedItems) {
                let newBox: Box = new Box();
                let currentBox: Size = p.resize(null)!;
                let region: Region = p.region;

                switch (region) {
                    case Region.Left:
                        newBox.h = box.h!;
                        newBox.w = currentBox.w!;
                        newBox.x = box.x!;
                        newBox.y = box.y!;
                        box.w = box.w! - currentBox.w!;
                        box.x = box.x! + currentBox.w!;
                        break;
                    case Region.Right:
                        newBox.h = box.h!;
                        newBox.w = currentBox.w!;
                        box.w = box.w! - currentBox.w!;
                        newBox.y = box.y!;
                        newBox.x = box.x! + box.w!;
                        break;
                    case Region.Top:
                        newBox.w = box.w!;
                        newBox.h = currentBox.h!;
                        newBox.x = box.x!;
                        newBox.y = box.y!;
                        box.h = box.h! - currentBox.h!;
                        box.y = box.y! + currentBox.h!;
                        break;
                    case Region.Bottom:
                        newBox.w = box.w!;
                        newBox.h = currentBox.h!;
                        box.h = box.h! - currentBox.h!;
                        newBox.x = box.x!;
                        newBox.y = box.y! + box.h!;

                        break;
                }
                p.resize(newBox);
            }
            // layout center
            centerPane.resize(box);
        }
        super.layout();
    }
}
