import { Measure, ConcreteDataMeasure } from "../core/measure/Measure";
import { MeasureType } from "../core/measure/MeasureType";
import dom from "gis3d/wf/util/DomUtils";
import ui from "gis3d/wf/ui/style/UiStyle";
import fa from "scss/font-awesome.scss";
import I18N from "gis3d/wf/i18n/I18N";
// TODO MeasureDecorator should be customizable
export class MeasureDecorator {
    public unit(m: Measure): string {
        switch (m.type) {
            case MeasureType.INFO:
                return "";
            case MeasureType.ANGLE:
                return "deg";
            case MeasureType.AREA:
                return "mq";
            case MeasureType.LENGTH:
                return "m";
        }
        return "";
    }

    public value(m: Measure): string {
        return m.value.toFixed(2);
    }

    public dataNode(m: Measure, parent: HTMLElement): void {
        if (m instanceof ConcreteDataMeasure) {
            const cdm = m as ConcreteDataMeasure<any>;
            if (cdm.data != null) {
                const dataDom = dom.el("div", {
                    classes: ["measure-data", ui.Display.d(ui.Display.flex)],
                    parent: parent,
                });
                dom.el("i", {
                    classes: [fa.fa, fa.faDatabase, "data-icon"],
                    parent: dataDom,
                });

                const list = dom.el("dl", {
                    parent: dataDom,
                });
                if (cdm.data.intensity != null) {
                    const intensity = Math.floor((cdm.data.intensity / 65535) * 255);

                    dom.el("dt", {
                        classes: [],
                        html: I18N.i("measure.data.intensity"),
                        parent: list,
                    });
                    dom.el("dd", {
                        classes: [],
                        html: intensity.toString(),
                        parent: list,
                    });
                }
                if (cdm.data.rgb != null) {
                    const color = "rgba(" + cdm.data.rgb.join(",") + ")";
                    // ((cdm.data.rgb[0] << 16) & 0xff0000) | ((cdm.data.rgb[1] << 8) & 0x00ff00) | (cdm.data.rgb[2] & 0x0000ff);

                    dom.el("dt", {
                        classes: [],
                        html: I18N.i("measure.data.color"),
                        parent: list,
                    });
                    dom.el("dd", {
                        classes: [],
                        html: "<i class=\"fa fa-lg fa-square\" style=\"color: " + color + "\"></i> " + cdm.data.rgb.join(", "),
                        parent: list,
                    });
                }
            }
        }
    }
}
