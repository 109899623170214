import { Box3 } from "three";

export class Bounds extends Box3 {
    public get xSize(): number {
        return this.max.x - this.min.x;
    }

    public get ySize(): number {
        return this.max.y - this.min.y;
    }

    public get zSize(): number {
        return this.max.z - this.min.z;
    }
}
