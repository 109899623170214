export class Features {
    public static get cpuCores(): number {
        if (navigator.hardwareConcurrency) {
            return navigator.hardwareConcurrency;
        }
        return 4;
    }

    public static get pixelRatio(): number {
        return Math.round(window.devicePixelRatio || 1 );
    }
}