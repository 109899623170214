import run from "gis3d/wf/util/RuntimeUtils";

export default class I18N {
    private static default : {[key:string]:any} = {};

    public static init(defaultMessages:Array<object>) {
        I18N.default = run.extend({}, ...defaultMessages);
    }

    public static i(key:string, ...args:Array<any>) : string {        
        return <string>(I18N.default[key] || key);
    }
}