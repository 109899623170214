import { Layer3d } from "gis3d/cityvu/core/three/scene/Layer3d";
import { Layer3dType } from "gis3d/cityvu/core/three/scene/Layer3dType";
import { PointCloudDataSource } from "gis3d/cityvu/data/PointCloudDataSource";
import { PointCloudLayer3dOptions } from "./PointCloudLayer3dOptions";
import { PointCloudLoader } from "gis3d/cityvu/io/cloud/PointCloudLoader";
import { PointCloudChannel } from "./PointCloudChannel";
import { PointColorType } from "gis3d/cityvu/core/three/cloud/potree";
import { Vector3 } from "three";

export class PointCloudLayer3d extends Layer3d {
    public constructor(readonly options: PointCloudLayer3dOptions, readonly dataSource: PointCloudDataSource) {
        super();
        if (this.options.channel == null) {
            this.options.channel = PointCloudChannel.INTENSITY;
        }
    }

    protected onEngineAssigment(): void {
        const scene = this.engine.scene;
        const cloudEngine = this.engine.cloudEngine;
        const cloudLoader = new PointCloudLoader(cloudEngine);

        cloudLoader
            .load({
                filename: "cloud.js",
                baseUrl: this.dataSource.url,
            })
            .then(pc => {
                scene.crs.coordsToPoint(pc.position, pc.position);
                /*
                NO NEED TO DO THIS
                const pcSize = new Vector3();
                pc.pcoGeometry.tightBoundingBox.getSize(pcSize);
                pcSize.x /= 2;
                pcSize.y /= 2;
                pcSize.z = 0;
                pc.position.add(pcSize);
                */
                switch (this.options.channel) {
                    case PointCloudChannel.INTENSITY:
                        pc.material.pointColorType = PointColorType.INTENSITY;
                        break;
                    case PointCloudChannel.RGB:
                        pc.material.pointColorType = PointColorType.RGB;
                        break;
                }

                this.add(pc);
                cloudEngine.add(pc);
            });

        super.onEngineAssigment();
    }

    public get type(): Layer3dType {
        return Layer3dType.POINTCLOUD;
    }
}
