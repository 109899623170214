import { BaseUiComponent } from "../BaseUiComponent";
import UiStyle from "../style/UiStyle";
import DomUtils from "gis3d/wf/util/DomUtils";
import fa from "scss/font-awesome.scss";
import { Box } from "../geom/Box";
import { Size } from "../geom/Size";
import I18N from "gis3d/wf/i18n/I18N";

export class ConnectionStatus extends BaseUiComponent {
    public static readonly DISCONNECTED: number = -1;
    public static readonly CONNECTED: number = 1;
    public static readonly CONNECTING: number = 0;

    protected stack!: HTMLElement;
    protected icon!: HTMLElement;
    protected ban!: HTMLElement;

    protected _status!: number;

    public prepareBuildOptions(): void {
        super.prepareBuildOptions();
        if (this.domElementOptions.classes == null) {
            this.domElementOptions.classes = [];
        }

        this.domElementOptions.classes.push(UiStyle.p("ConnectionStatus"));
    }

    public build(): void {
        super.build();
        this.stack = DomUtils.el("span", {
            classes: [fa.fa, fa.faStack],
        });
        this.icon = DomUtils.el("i", {
            classes: [fa.fas, fa.faWifi, fa.faStack1X],
        });
        this.ban = DomUtils.el("i", {
            classes: [fa.fas, fa.faBan, fa.faStack2X],
        });

        DomUtils.append(this.domNode!, this.stack);
        DomUtils.append(this.stack, this.icon);
        DomUtils.append(this.stack, this.ban);

        this.status = ConnectionStatus.DISCONNECTED;
    }

    public set title(text: string) {
        DomUtils.setAttr(this.domNode!, "title", text);
    }

    public get status(): number {
        return this._status;
    }

    public set status(value: number) {
        this._status = value;
        DomUtils.setData(this.domNode!, "status", value);
        if (ConnectionStatus.CONNECTING == value) {
            this.title = I18N.i("wf.gui.connectionStatus.connecting");
        } else if (ConnectionStatus.CONNECTED == value) {
            this.title = I18N.i("wf.gui.connectionStatus.connected");
        } else if (ConnectionStatus.DISCONNECTED == value) {
            this.title = I18N.i("wf.gui.connectionStatus.disconnected");
        }
    }

    public resize(box: Box | null): Size | null {
        return DomUtils.marginBox(this.domNode!);
    }
}
