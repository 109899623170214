import { IndirectFactory, IndirectClassType } from "./IndirectFactory";

type PromiseResolver<T> = (value?: T | PromiseLike<T>) => void;
export class Pool<T> {
    protected items: Array<T>;
    private _infinite: boolean = true;
    protected waitingList : Array<PromiseResolver<T>> = [];

    public constructor(readonly classType: IndirectClassType<T>, readonly maxSpare: number, readonly minSpare: number = 0) {
        this.items = [];
        for (let index = 0; index < minSpare; index++) {
            this.items.push(IndirectFactory.create<T>(this.classType));
        }
    }
    
    public fetchWait(): Promise<T> {
        return new Promise<T>((resolve : PromiseResolver<T>, reject) => {
            const item = this.items.shift();
            if (item == null) {
                this.waitingList.push(resolve);
            } else {
                resolve(item);
            }
        });
    }

    public fetch(): T | undefined {
        const item = this.items.shift();
        if (item == null) {
            if (this.infinite) {
                return IndirectFactory.create<T>(this.classType);
            }
        }
        return item;
    }

    public release(item: T) {
        if (this.maxSpare > this.items.length) {
            if (this.waitingList.length > 0) {
                const promiseResolver = this.waitingList.shift();
                if (promiseResolver != null) {
                    promiseResolver(item);
                }
            } else {
                this.items.push(item);               
            }
        }
    }

    public get infinite(): boolean {
        return this._infinite;
    }

    public set infinite(value: boolean) {
        this._infinite = value;
    }
}
