import { BasicScene } from "gis3d/cityvu/core/three/scene/BasicScene";
import { BoxBufferGeometry, LinearFilter, LinearMipMapLinearFilter, Mesh, ShaderMaterial, Texture, TextureLoader, UniformsLib, UniformsUtils } from "three";
import { Engine } from "../Engine";
import GisCubeFs from "./GisCube.fs";
import GisCubeVs from "./GisCube.vs";
import { Layer3d } from "./Layer3d";
import { Layer3dState } from "./Layer3dState";

export class WaitingScene extends BasicScene {
    private cube!: Mesh;
    private layer!: Layer3d;

    constructor() {
        super();
        this.showGui = false;
        this.enableControls = false;
        this.enableMeasurementTools = false;
    }

    private gisCube(engine: Engine): void {
        const geometry = new BoxBufferGeometry(50, 50, 50);
        this.layer = new Layer3d();

        new TextureLoader().load("/assets/textures/3dgis.png", (texture: Texture) => {
            texture.magFilter = LinearFilter;
            texture.minFilter = LinearMipMapLinearFilter;
            texture.premultiplyAlpha = true;
            texture.anisotropy = engine.renderer.capabilities.getMaxAnisotropy();

            const material = new ShaderMaterial({
                fragmentShader: GisCubeFs,
                vertexShader: GisCubeVs,
                uniforms: UniformsUtils.merge([
                    UniformsLib["lights"],
                    {
                        texture: { type: "t", value: null },
                    },
                ]),
                lights: true,
            });
            material.uniforms.texture.value = texture;

            this.cube = new Mesh(geometry, material);
            this.layer.add(this.cube);
            this.layer.state = Layer3dState.READY;
            this.add(this.layer);
        });
    }
    public onEngineAssigment(): void {
        super.onEngineAssigment();
        this.pose.position.x = 0;
        this.pose.position.z = 0;
        this.pose.position.y = -120;
        if (this.layer == null) {
            this.gisCube(this.engine!);
        }
    }

    public updateScene(delta: number): void {
        super.updateScene(delta);
        if (this.cube) {
            this.cube.rotation.x += 0.005;
            this.cube.rotation.y += 0.01;
        }
    }
}
