import { DataSourceType } from "./DataSourceType";
import { SceneDefinitionDataSource } from "../scene/definition/SceneDefinitionDataSource";

export abstract class DataSource {
    public constructor(readonly definition: SceneDefinitionDataSource) {}

    public abstract get type(): DataSourceType;

    public get code(): string {
        return this.definition.code;
    }

    public get name(): string {
        return this.definition.name;
    }
}
