import { Object3D, Texture, LinearFilter, SpriteMaterial, Material, Sprite } from "three";
import DomUtils from "gis3d/wf/util/DomUtils";
import UiStyle from "gis3d/wf/ui/style/UiStyle";

export type Rgba = { r: number; g: number; b: number; a: number; };

export class TextSprite extends Object3D {
    private _material!: Material;
    private _sprite!: Sprite;
    private _borderThickness!: number;
    private _fontFace!: string;
    private _fontSize!: number;
    private _borderColor!: Rgba;
    private _backgroundColor!: Rgba;
    private _textColor!: Rgba;
    private _text!: string;
    private initialized: boolean;

    constructor(text?: string) {
        super();

        this.initialized = false;

        const texture = new Texture();
        texture.minFilter = LinearFilter;
        texture.magFilter = LinearFilter;

        const spriteMaterial = new SpriteMaterial({
            map: texture
        });

        this.material = spriteMaterial;
        this.sprite = new Sprite(spriteMaterial);
        this.add(this.sprite);

        this.borderThickness = 6;
        this.fontFace = UiStyle.systemFontFamily;
        this.fontSize = 30;
        this.borderColor = {
            r: 60,
            g: 60,
            b: 60,
            a: 1.0
        };
        this.backgroundColor = {
            r: 96,
            g: 96,
            b: 96,
            a: 1.0
        };
        this.textColor = {
            r: 255,
            g: 255,
            b: 255,
            a: 1.0
        };

        this.text = text || "";
        this.initialized = true;
        this.update();
    }

    public update(): void {
        if (this.initialized === false) {
            return;
        }
        const canvas = DomUtils.el("canvas") as HTMLCanvasElement;
        const context = canvas.getContext("2d");
        if (context == null) {
            return;
        }
        context.font = "Bold " + this.fontSize + "px " + this.fontFace;

        // get size data (height depends only on font size)
        let metrics = context.measureText(this.text);
        let textWidth = Math.ceil(metrics.width) + 8;
        let textHeight = this.fontSize + 8;
        let spriteWidth = textWidth + 2 * this.borderThickness;
        let spriteHeight = textHeight + 2 * this.borderThickness;

        context.canvas.width = spriteWidth;
        context.canvas.height = spriteHeight;
        context.font = "Bold " + this.fontSize + "px " + this.fontFace;

        // background color
        context.fillStyle = "rgba(" + this.backgroundColor.r + "," + this.backgroundColor.g + "," + this.backgroundColor.b + "," + this.backgroundColor.a + ")";
        // border color
        context.strokeStyle = "rgba(" + this.borderColor.r + "," + this.borderColor.g + "," + this.borderColor.b + "," + this.borderColor.a + ")";

        context.lineWidth = this.borderThickness;
        this.roundRect(context, 0, 0, spriteWidth, spriteHeight, 12);

        // text color
        context.textBaseline = "top";
        context.strokeStyle = "rgba(0, 0, 0, 1.0)";
        context.strokeText(this.text, this.borderThickness * 2, this.borderThickness / 2 + 8);

        context.fillStyle = "rgba(" + this.textColor.r + "," + this.textColor.g + "," + this.textColor.b + "," + this.textColor.a + ")";
        context.fillText(this.text, this.borderThickness * 2, this.borderThickness / 2 + 8);

        var texture = new Texture(canvas);
        texture.minFilter = LinearFilter;
        texture.magFilter = LinearFilter;
        texture.needsUpdate = true;

        (this.sprite.material as SpriteMaterial).map = texture;
        this.sprite.scale.set(spriteWidth * 0.01, spriteHeight * 0.01, 1);
    }


    protected roundRect(ctx: CanvasRenderingContext2D, x: number, y: number, w: number, h: number, r: number): void {
        ctx.beginPath();
        ctx.moveTo(x + r, y);
        ctx.lineTo(x + w - r, y);
        ctx.quadraticCurveTo(x + w, y, x + w, y + r);
        ctx.lineTo(x + w, y + h - r);
        ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
        ctx.lineTo(x + r, y + h);
        ctx.quadraticCurveTo(x, y + h, x, y + h - r);
        ctx.lineTo(x, y + r);
        ctx.quadraticCurveTo(x, y, x + r, y);
        ctx.closePath();
        //ctx.stroke();
        ctx.fill();
    }

    public get fontFace(): string {
        return this._fontFace;
    }
    public set fontFace(value: string) {
        this._fontFace = value;
        this.update();
    }
    public get fontSize(): number {
        return this._fontSize;
    }
    public set fontSize(value: number) {
        this._fontSize = value;
        this.update();
    }
    public get borderColor(): Rgba {
        return this._borderColor;
    }
    public set borderColor(value: Rgba) {
        this._borderColor = value;
        this.update();
    }
    public get backgroundColor(): Rgba {
        return this._backgroundColor;
    }
    public set backgroundColor(value: Rgba) {
        this._backgroundColor = value;
        this.update();
    }
    public get textColor(): Rgba {
        return this._textColor;
    }
    public set textColor(value: Rgba) {
        this._textColor = value;
        this.update();
    }
    public get borderThickness(): number {
        return this._borderThickness;
    }
    public set borderThickness(value: number) {
        this._borderThickness = value;
        this.update();
    }
    public get sprite(): Sprite {
        return this._sprite;
    }
    public set sprite(value: Sprite) {
        this._sprite = value;
    }
    public get material(): Material {
        return this._material;
    }
    public set material(value: Material) {
        this._material = value;
    }
    public get text(): string {
        return this._text;
    }
    public set text(value: string) {
        this._text = value;
        this.update();
    }
}
