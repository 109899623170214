import { Projection } from "gis3d/cityvu/core/geo/projection/Projection";
import { Bounds } from "gis3d/cityvu/core/geometry/Bounds";
import { Ellipsoid } from "gis3d/cityvu/core/geo/Ellipsoid";
import { Vector3 } from "three";

export class SimpleProjection implements Projection {
    private _bounds: Bounds = new Bounds(new Vector3(-180.0, -90.0, -Number.MAX_VALUE), new Vector3(180.0, 90.0, -Number.MAX_VALUE));

    public get name(): string {
        return "Geographic";
    }

    public get bounds(): Bounds {
        return this._bounds;
    }

    public project(inp: Vector3, reuse?: Vector3): Vector3 {
        if (reuse == null) {
            reuse = new Vector3();
        }
        reuse.copy(inp);
        return reuse;
    }

    public unproject(inp: Vector3, reuse?: Vector3): Vector3 {
        if (reuse == null) {
            reuse = new Vector3();
        }
        reuse.copy(inp);
        return reuse;
    }

}