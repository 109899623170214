export interface EnabledControls {
    earth : boolean;
    orbit : boolean;
    fps : boolean;
    constrained : boolean;
}

export interface EnabledTools {
    layerSelector : boolean;
    compass : boolean;
    navigator : boolean;
    info : boolean;
}

export interface EnabledMeasurements {
    info : boolean;
    line : boolean;
    area : boolean;
    angle : boolean;
}

export interface GuiOptions {
    controls : boolean;
    enabledControls? : EnabledControls;
    selectedControls? : string;
    measurements:boolean;
    enabledMeasurements? : EnabledMeasurements;    
    tools?:EnabledTools;
    userSettings:boolean;
}

export class DefaultGuiOptions implements GuiOptions {
    public controls:boolean = true;
    public enabledControls : EnabledControls = {
        earth : true,
        orbit : true,
        fps : true,
        constrained : true
    };
    public selectedControls? : string;

    public measurements:boolean = true;
    public enabledMeasurements : EnabledMeasurements = {
        info : true,
        line : true,
        area : true,
        angle : true
    };

    public tools : EnabledTools = {
        layerSelector : true,
        compass : true,
        navigator : true,
        info : true
    };

    public userSettings:boolean = true;
}