import { Vector3, PointLight } from "three";
import NumberUtils from "gis3d/wf/util/NumberUtils";

/**
 * Graham's Scan Convex Hull Algorithm
 */
export class GrahamScanConvexHull {
    private points: Array<Vector3> = [];
    private reverse: boolean = false;
    private anchorPoint?: Vector3 = undefined;

    protected findPolarAngle(a: Vector3, b: Vector3): number {
        const deltaX = b.x - a.x;
        const deltaY = b.y - a.y;

        if (deltaX == 0 && deltaY == 0) {
            return 0;
        }

        var angle = Math.atan2(deltaY, deltaX) * NumberUtils.RAD_2_DEG;

        if (this.reverse) {
            if (angle <= 0) {
                angle += 360;
            }
        } else {
            if (angle >= 0) {
                angle += 360;
            }
        }

        return angle;
    }

    protected sortPoints(): void {
        this.points.sort((a, b) => {
            const polarA = this.findPolarAngle(this.anchorPoint!, a);
            const polarB = this.findPolarAngle(this.anchorPoint!, b);

            if (polarA < polarB) {
                return -1;
            }

            if (polarA > polarB) {
                return 1;
            }

            return 0;
        });
    }

    protected checkPoints(p0: Vector3, p1: Vector3, p2: Vector3): boolean {
        const cwAngle = this.findPolarAngle(p0, p1);
        const ccwAngle = this.findPolarAngle(p0, p2);

        if (cwAngle > ccwAngle) {
            const difAngle = cwAngle - ccwAngle;

            return !(difAngle > 180);
        } else if (cwAngle < ccwAngle) {
            const difAngle = ccwAngle - cwAngle;

            return difAngle > 180;
        }

        return true;
    }

    public hull(inputPoints: Array<Vector3>): Array<Vector3> {
        this.anchorPoint = undefined;

        let hullPoints: Array<Vector3> = [];
        let anchorIndex = -1;

        // anchor selection
        for (let i = 0; i < inputPoints.length; i++) {
            const p = inputPoints[i];
            const newAnchor = this.anchorPoint === undefined || this.anchorPoint!.y > p.y || (this.anchorPoint!.y === p.y && this.anchorPoint!.x > p.x);

            if (newAnchor) {
                if (this.anchorPoint === undefined) {
                    this.anchorPoint = new Vector3();
                }
                this.anchorPoint.copy(p);
                anchorIndex = i;
            }
        }
        this.points = inputPoints.filter((v, i, arr) => i !== anchorIndex);

        this.reverse = this.points.every(function(point) {
            return point.x < 0 && point.y < 0;
        });

        this.sortPoints();
        let pointsLength = this.points.length;

        if (pointsLength < 3) {
            throw Error("grahamScan.lessThanTriangle");
        }

        // If there are less than 3 points, joining these points creates a correct hull.
        if (pointsLength == 3) {
            hullPoints.unshift(this.anchorPoint!);
            return hullPoints;
        }

        // move first two points to output array
        hullPoints.push(this.points.shift()!, this.points.shift()!);

        // scan is repeated until no concave points are present.
        while (true) {
            hullPoints.push(this.points.shift()!);

            const p0 = hullPoints[hullPoints.length - 3];
            const p1 = hullPoints[hullPoints.length - 2];
            const p2 = hullPoints[hullPoints.length - 1];

            if (this.checkPoints(p0, p1, p2)) {
                hullPoints.splice(hullPoints.length - 2, 1);
            }

            if (this.points.length == 0) {
                if (pointsLength == hullPoints.length) {
                    // check for duplicate anchorPoint edge-case, if not found, add the anchorpoint as the first item.
                    if (
                        !hullPoints.some(p => {
                            return p.x == this.anchorPoint!.x && p.y == this.anchorPoint!.y;
                        })
                    ) {
                        hullPoints.unshift(this.anchorPoint!);
                    }
                    return hullPoints;
                }
                this.points = hullPoints;
                pointsLength = this.points.length;
                hullPoints = [];
                hullPoints.push(this.points.shift()!, this.points.shift()!);
            }
        }
    }
}
