// sizzle augmentation
import * as sizzle from "sizzle";
namespace Sizzle {
    export function matchesSelector(element: Element, selector: string): boolean {
        return (sizzle as any).matchesSelector(element, selector);
    }
}
// end sizzle augmentation

import ui from "gis3d/wf/ui/style/UiStyle";
import nu from "gis3d/wf/util/NumberUtils";
import { Box } from "gis3d/wf/ui/geom/Box";
import { Size } from "gis3d/wf/ui/geom/Size";

export interface DomElementOptions {
    classes?: Array<string>;
    id?: string;
    attrs?: Map<string, any>;
    parent?: HTMLElement;
    html?: string;
}

export default class DomUtils {
    public static readonly body = () => document.body;
    public static readonly doc = () => document;
    public static readonly docElement = () => document.documentElement;
    public static readonly win = () => window;
    public static readonly query = (selector: string, parent: Document | DocumentFragment | HTMLElement = document) => sizzle.default(selector, parent);
    public static readonly match = (element: Element, selector: string) => Sizzle.matchesSelector(element, selector);
    public static readonly byId = (id: string) => document.getElementById(id);
    public static readonly id = (node: Element, id: string) => (node.id = id);
    public static readonly frag = () => document.createDocumentFragment();
    public static readonly el = <T extends HTMLElement = HTMLElement>(type: string, opts?: DomElementOptions) => {
        let e: HTMLElement = document.createElement(type);
        if (opts) {
            if (opts.classes) {
                DomUtils.addClasses(e, opts.classes);
            }
            if (opts.id) {
                DomUtils.id(e, opts.id);
            }
            if (opts.attrs) {
                opts.attrs.forEach((value: any, key: string) => {
                    e.setAttribute(key, value);
                });
            }
            if (opts.html) {
                DomUtils.set(e, opts.html);
            }
            if (opts.parent) {
                DomUtils.append(opts.parent, e);
            }
        }
        return <T>e;
    };
    public static readonly append = (parent: Element | DocumentFragment, childToAppend: Element | DocumentFragment) => parent.appendChild(childToAppend);
    public static readonly prepend = (parent: Element | DocumentFragment, childToPrepend: Element | DocumentFragment) => parent.insertBefore(childToPrepend, parent.firstChild);
    public static readonly before = (beforeThisNode: Element | DocumentFragment, elementToInsert: Element | DocumentFragment) =>
        beforeThisNode.parentElement!.insertBefore(elementToInsert, beforeThisNode);
    public static readonly after = (afterThisNode: Element | DocumentFragment, elementToInsert: Element | DocumentFragment) =>
        afterThisNode.parentElement!.insertBefore(elementToInsert, afterThisNode.nextSibling);
    public static readonly remove = (parent: Element | DocumentFragment, childToRemove: Element) => parent.removeChild(childToRemove);
    public static readonly empty = (element: Element) => {
        while (element.firstChild) {
            element.removeChild(element.firstChild);
        }
        element.textContent = "";
    };
    public static readonly set = (element: Element, html: string) => (element.innerHTML = html);
    public static readonly text = (element: Element) => element.textContent;
    public static readonly setText = (element: Element, txt: string) => (element.textContent = txt);
    public static readonly toDom = (html: string) => {
        let hDoc: HTMLDocument = document.implementation.createHTMLDocument();
        hDoc.body.innerHTML = html;
        return hDoc.body.children;
    };
    // attributes
    public static readonly attr = (element: Element, attribute: string) => element.getAttribute(attribute);
    public static readonly setAttr = (element: Element, attribute: string, value: any = "") => element.setAttribute(attribute, value);
    public static readonly removeAttr = (element: Element, attribute: string) => element.removeAttribute(attribute);
    public static readonly data = (element: HTMLElement, attribute: string) => element.dataset[attribute];
    public static readonly setData = (element: HTMLElement, attribute: string, value: any) => (element.dataset[attribute] = value);
    // classes & styles
    public static readonly addClass = (element: Element, className: string) => element.classList.add(className);
    public static readonly addClasses = (element: Element, classNames: Array<string>) => element.classList.add(...classNames);
    public static readonly removeClass = (element: Element, className: string) => element.classList.remove(className);
    public static readonly hasClass = (element: Element, className: string) => element.classList.contains(className);
    public static readonly toggleClass = (element: Element, className: string) => element.classList.toggle(className);
    public static readonly computedStyle = (element: Element): CSSStyleDeclaration => getComputedStyle(element);
    public static readonly css = (element: Element, property: string): string => getComputedStyle(element).getPropertyValue(property);
    public static readonly setCss = (element: HTMLElement, property: string, value: any = ""): void => (element.style[<any>property] = value);
    // sizing
    public static readonly marginExtents = (element: HTMLElement, style?: CSSStyleDeclaration | null, box?: Box, addPadding?: boolean) => {
        let cstyle = style || DomUtils.computedStyle(element);
        const padL = addPadding === true && cstyle.paddingLeft != null ? nu.toNumber(cstyle.paddingLeft) : 0;
        const padR = addPadding === true && cstyle.paddingRight != null ? nu.toNumber(cstyle.paddingRight) : 0;
        const padT = addPadding === true && cstyle.paddingTop != null ? nu.toNumber(cstyle.paddingTop) : 0;
        const padB = addPadding === true && cstyle.paddingBottom != null ? nu.toNumber(cstyle.paddingBottom) : 0;

        let x: number = nu.toNumber(cstyle.marginLeft);
        let y: number = nu.toNumber(cstyle.marginTop);
        let w = x + nu.toNumber(cstyle.marginRight) + padL + padR;
        let h = y + nu.toNumber(cstyle.marginBottom) + padB + padT;

        let b: Box = box || new Box();
        b.x = x;
        b.y = y;
        b.w = w;
        b.h = h;

        return b;
    };
    public static readonly marginBox = (element: HTMLElement, style?: CSSStyleDeclaration | null, box?: Box, addPadding?: boolean) => {
        let cstyle = style || DomUtils.computedStyle(element);
        let extents: Box = DomUtils.marginExtents(element, cstyle, box, addPadding);
        let x = element.offsetLeft - extents.x!;
        let y = element.offsetTop - extents.y!;
        let w = element.offsetWidth + extents.w!;
        let h = element.offsetHeight + extents.h!;

        let b: Box = box || new Box();
        b.x = x;
        b.y = y;
        b.w = w;
        b.h = h;
        return new Box(x, y, w, h);
    };
    public static readonly size = (element: HTMLElement, sz?: Size) => {
        if (sz == null) {
            sz = new Size();
        }
        sz.w = element.clientWidth;
        sz.h = element.clientHeight;
        return sz;
    };
    public static readonly setBox = (element: HTMLElement, box: Box) => {
        const u = "px";
        const style = element.style;
        if (!isNaN(box.x!)) {
            style.left = box.x! + u;
        }
        if (!isNaN(box.y!)) {
            style.top = box.y! + u;
        }
        if (!isNaN(box.w!)) {
            style.width = box.w! + u;
        }
        if (!isNaN(box.h!)) {
            style.height = box.h! + u;
        }
        if (!isNaN(box.minH!)) {
            style.minHeight = box.minH! + u;
        }
        if (!isNaN(box.minW!)) {
            style.minWidth = box.minW! + u;
        }
        if (!isNaN(box.maxH!)) {
            style.maxHeight = box.maxH! + u;
        }
        if (!isNaN(box.maxW!)) {
            style.maxWidth = box.maxW! + u;
        }
    };
    // events
    public static readonly on = (element: Element | Document | Window, eventName: string, eventHandler: EventListener, capture: boolean = false) =>
        element.addEventListener(eventName, eventHandler, capture);
    public static readonly off = (element: Element | Document | Window, eventName: string, eventHandler: EventListener, capture: boolean = false) =>
        element.removeEventListener(eventName, eventHandler, capture);
    public static readonly ready = (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            if (document.readyState === "complete" || document.readyState === "interactive") {
                resolve();
            } else {
                document.addEventListener("DOMContentLoaded", () => {
                    resolve();
                });
            }
        });
    };
    public static readonly load = (loadFunc: EventListener) => document.addEventListener("load", loadFunc);
    public static readonly trigger = (element: Element, customEventName: string, data?: any) => {
        let event = new CustomEvent(customEventName, { detail: data });
        element.dispatchEvent(event);
    };
    // other utils
    public static readonly removeLoader = (id?: string) => {
        let loader: Element | null = DomUtils.byId(id ? id : "loader");
        if (loader != null) {
            DomUtils.remove(DomUtils.body(), loader);
        }
    };
    public static readonly bsod = (message: string) => {
        const bsod = DomUtils.el("div", {
            classes: [ui.p("bsod")],
        });

        const bsodMessage = DomUtils.el("div", {
            classes: ["message"],
        });
        DomUtils.set(bsodMessage, message);
        DomUtils.append(bsod, bsodMessage);
        DomUtils.append(DomUtils.body(), bsod);
    };

    public static readonly copyToClipboard = (text: string): boolean => {
        let done = false;
        const textArea = DomUtils.el("textarea") as HTMLTextAreaElement;
        DomUtils.setCss(textArea, "position", "fixed");
        DomUtils.setCss(textArea, "top", "0");
        DomUtils.setCss(textArea, "left", "0");
        DomUtils.setCss(textArea, "width", "2em");
        DomUtils.setCss(textArea, "height", "2em");
        DomUtils.setCss(textArea, "padding", "0");
        DomUtils.setCss(textArea, "border", "none");
        DomUtils.setCss(textArea, "outline", "none");
        DomUtils.setCss(textArea, "boxShadow", "none");
        DomUtils.setCss(textArea, "background", "transparent");
        textArea.value = text;
        DomUtils.append(DomUtils.body(), textArea);
        textArea.select();

        try {
            let success = document.execCommand("copy");
            if (success) {
                done = true;
            } else {
                done = false;
                console.error("copy to clipboard failed");
            }
        } catch (err) {
            done = false;
            console.error("error while trying to copy to clipboard");
        }
        DomUtils.remove(DomUtils.body(), textArea);
        return done;
    };
}
