import { Layer3dBuilder } from "./Layer3dBuilder";
import { Layer3d } from "gis3d/cityvu/core/three/scene/Layer3d";
import { DataSource } from "gis3d/cityvu/data/DataSource";
import { DataSourceType } from "gis3d/cityvu/data/DataSourceType";
import { PointCloudLayer3dOptions } from "../pointcloud/PointCloudLayer3dOptions";
import { PointCloudLayer3d } from "../pointcloud/PointCloudLayer3d";
import { PointCloudDataSource } from "gis3d/cityvu/data/PointCloudDataSource";
import { TiledPointCloudLayer3d } from "../pointcloud/TiledPointCloudLayer3d";
import { TiledPointCloudDataSource } from "gis3d/cityvu/data/TiledPointCloudDataSource";

export class PointCloudLayer3dBuilder extends Layer3dBuilder {
    public static readonly allowedDatasource = new Set<DataSourceType>([
        //
        DataSourceType.POINTCLOUD,
        DataSourceType.TILED_POINTCLOUD,
    ]);

    public build(o: any, dataSource: DataSource): Layer3d {
        const options = o as PointCloudLayer3dOptions;
        if (!PointCloudLayer3dBuilder.allowedDatasource.has(dataSource.type)) {
            throw Error("layer3dBuilder.unallowedDataSource");
        }
        if (dataSource.type == DataSourceType.POINTCLOUD) {
            return new PointCloudLayer3d(options, dataSource as PointCloudDataSource);
        } else if (dataSource.type == DataSourceType.TILED_POINTCLOUD) {
            return new TiledPointCloudLayer3d(options, dataSource as TiledPointCloudDataSource);
        }
        throw Error("layer3dBuilder.unimplementedType");
    }
}
