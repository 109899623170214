import { Pane } from "../layout/Pane";
import { Region } from "../layout/Region";
import { UiContainer } from "../UiContainer";
import { Message, MessageType } from "./Message";

import NumberUtils from "gis3d/wf/util/NumberUtils";
import ui from "gis3d/wf/ui/style/UiStyle";
import dom from "gis3d/wf/util/DomUtils";

export class MessageBar extends Pane {
    private _messages: Array<Message> = [];
    protected list!: HTMLUListElement;


    constructor(top: boolean = true) {
        super(top ? Region.Top : Region.Bottom);
        if (!this.domElementOptions.classes) {
            this.domElementOptions.classes = [];
        }
        this.domElementOptions.classes!.push(ui.p("MessageBar"));
    }

    public init(): void {
        this.displayed = false;
        super.init();
    }

    public build(): void {
        super.build();
        this.list = dom.el("ul", {
            classes: [ui.listUnstyled, "m-0", "list"]
        });
        dom.append(this.domNode!, this.list);
    }

    public add(msg: Message): string {
        if (msg.id == null) {
            msg.id = NumberUtils.createUuid();
        }
        if (msg.type == null) {
            msg.type = MessageType.NORMAL;
        }
        if (msg.closable == null) {
            msg.closable = false;
        }
        this.messages.push(msg);
        this.messages.sort((a: Message, b: Message) => {
            return a.type - b.type;
        });
        this.render();
        return msg.id;
    }

    public remove(id: string): void {
        this._messages = this.messages.filter(m => m.id !== id);
        this.render();
    }

    public clear(): void {
        this._messages = [];
        this.render();
    }

    public get displayed(): boolean {
        return super.displayed;
    }

    public render(): Promise<void> | void {
        super.render();
        dom.empty(this.list);
        this.messages.forEach(m => {
            const li = dom.el("li", {
                classes: ["message"]
            });
            dom.set(li, m.content);
            dom.append(this.list, li);
        });
        this.displayed = this.messages.length > 0;
    }

    public get messages(): Array<Message> {
        return this._messages;
    }

    public set displayed(d: boolean) {
        super.displayed = d;
        // try to avoid abuse
        if (this.isStarted() && this.parent != null && this.parent.isStarted()) {
            (this.parent as UiContainer).layout();
        }
    }
}