import { BaseCrs } from "./BaseCrs";
import { AffineTransformation } from "../../geometry/AffineTransformation";
import { SimpleProjection } from "../projection/SimpleProjection";
import { GeoUtils } from "../GeoUtils";

/**
 * Does nothing CRS
 */
export class SimpleCrs extends BaseCrs {

    constructor() {
        super();
        this.code = GeoUtils.cityvuCrs("simple");
        this.projection = new SimpleProjection();
        this.transformation = new AffineTransformation();
    }
}
