export class UrlManager {
    public static _BaseUrl:string = "/";

    public static API = class {
        public static CONFIG = () => UrlManager.custom("api/config.json");
        public static ERROR = () => UrlManager.custom("api/error.json");
    };

    public static custom(path:string) : string {
        return UrlManager.BaseUrl + path;
    }

    public static get BaseUrl() : string {
        return UrlManager._BaseUrl;
    }

    public static set BaseUrl(base : string) {
        if (base.substr(-1) === "/") {
            UrlManager._BaseUrl = base;
        } else {
            UrlManager._BaseUrl = base + "/";
        }
    }
}