import { Loader, LoaderParameters } from "../Loader";
import { loadPOC } from "gis3d/cityvu/core/three/cloud/potree/loading/load-poc";
import { PointCloudOctree } from "gis3d/cityvu/core/three/cloud/potree";
import { CloudEngine } from "gis3d/cityvu/core/three/cloud/CloudEngine";

export class PointCloudLoader implements Loader<PointCloudOctree> {
    constructor(readonly engine: CloudEngine) {}

    public load(parameters: LoaderParameters): Promise<PointCloudOctree> {
        return loadPOC(
            parameters.filename,
            url => {
                return parameters.baseUrl + url;
            },
            (input: RequestInfo, init?: RequestInit) => fetch(input, init),
            this.engine.binaryDecoderPool
        ).then(geometry => {
            return new PointCloudOctree(this.engine.potree, geometry);
        });
    }
}
