import { Scene } from "gis3d/cityvu/core/three/scene/Scene";

export class Controls {
    protected _scene!: Scene;

    public enable(): void {
        throw new Error("Method not implemented.");
    }

    public disable(): void {
        throw new Error("Method not implemented.");
    }

    public stop(): void {

    }

    public update(delta: number): void {
        throw new Error("Method not implemented.");
    }

    public constructor(scene?: Scene) {
        if (scene) {
            this.scene = scene;
        }
    }


    public onSceneChange(scene: Scene): void {

    }

    public get scene(): Scene {
        return this._scene;
    }

    public set scene(scene: Scene) {
        this._scene = scene;
        this.onSceneChange(scene);
    }
}