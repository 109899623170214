import { Vector3, Plane } from "three";

export class MeasurementUtils {
    public static readonly angle = (commonPoint: Vector3, a: Vector3, b: Vector3): number => {
        const v1 = new Vector3().subVectors(a, commonPoint);
        const v2 = new Vector3().subVectors(b, commonPoint);
        return v1.angleTo(v2);
    };

    public static readonly distance = (a: Vector3, b: Vector3): number => {
        return a.distanceTo(b);
    };

    /**
     * The area of a triangle in 3D space is given by half the magnitude of the cross-product
     * of the vectors of two of its sides.
     *
     * So, the area of a polygon in 3D space is given by half of the magnitude of
     * the sum of cross-products, calculated looping on vertices keeping one fixed.
     */
    public static readonly area = (points: Array<Vector3>): number => {
        const b = new Vector3();
        const b1 = new Vector3();
        const b2 = new Vector3();
        const b3 = new Vector3();

        for (let i = 1; i < points.length; i++) {
            b1.subVectors(points[i], points[0]);
            b2.subVectors(points[(i + 1) % points.length], points[0]);
            b.add(b3.crossVectors(b1, b2));
        }

        return b.length() / 2;
    };

    public static readonly projectPointsOnPlane = (points: Array<Vector3>, plane: Plane): Array<Vector3> => {
        const buffer = new Vector3();
        points.forEach(p => {
            plane.projectPoint(p, buffer);
            p.copy(buffer);
        });
        return points;
    };

    public static readonly planeFromPointsLinearLeastSquares = (points: Array<Vector3>): Plane | null => {
        if (points.length < 3) {
            console.log("At least 3 points are needed");
            return null;
        }

        const centroid = new Vector3();
        for (let i = points.length - 1; i >= 0; i--) {
            centroid.add(points[i]);
        }
        centroid.divideScalar(points.length);

        // Calculate full 3x3 covariance matrix, excluding symmetries:
        let xx = 0.0,
            xy = 0.0,
            xz = 0.0,
            yy = 0.0,
            yz = 0.0,
            zz = 0.0;
        const r = new Vector3();
        for (let i = 0; i < points.length; i++) {
            r.copy(points[i]).sub(centroid);
            xx += r.x * r.x;
            xy += r.x * r.y;
            xz += r.x * r.z;
            yy += r.y * r.y;
            yz += r.y * r.z;
            zz += r.z * r.z;
        }

        const detX = yy * zz - yz * yz;
        const detY = xx * zz - xz * xz;
        const detZ = xx * yy - xy * xy;

        const detMax = Math.max(detX, detY, detZ);
        if (detMax <= 0.0) {
            console.log("Points don't span a plane");
            return null;
        }

        let direction = null;
        if (detMax === detX) {
            const a = (xz * yz - xy * zz) / detX;
            const b = (xy * yz - xz * yy) / detX;
            direction = new Vector3(1.0, a, b);
        } else if (detMax === detY) {
            const a = (yz * xz - xy * zz) / detY;
            const b = (xy * xz - yz * xx) / detY;
            direction = new Vector3(a, 1.0, b);
        } else {
            const a = (yz * xy - xz * yy) / detZ;
            const b = (xz * xy - yz * xx) / detZ;
            direction = new Vector3(a, b, 1.0);
        }
        direction.normalize();

        const constant = r.copy(centroid).dot(direction);
        // compute hesse normal form constant for plane
        return new Plane(direction, -constant);
    };
}
