import { SceneAware } from "../scene/SceneAware";
import { Scene } from "../scene/Scene";
import { MeasuresBroker } from "../../measure/MeasuresBroker";
import { Measure3dScene } from "../objects/Measure3dScene";
import { MeasureType } from "../../measure/MeasureType";
import { Measure } from "../../measure/Measure";

export abstract class MeasurementTool implements SceneAware {
    protected _scene!: Scene;
    protected _broker!: MeasuresBroker;
    protected _measures3dScene!: Measure3dScene;

    public abstract enable(): void;
    public abstract disable(): void;
    public abstract onSceneChange(scene: Scene): void;
    public abstract getMeasureType(): MeasureType;

    public get scene(): Scene {
        return this._scene;
    }

    public set scene(scene: Scene) {
        this._scene = scene;
        this.onSceneChange(scene);
    }

    public get broker(): MeasuresBroker {
        return this._broker;
    }

    public set broker(value: MeasuresBroker) {
        this._broker = value;
    }

    public get measures3dScene(): Measure3dScene {
        return this._measures3dScene;
    }

    public set measures3dScene(value: Measure3dScene) {
        this._measures3dScene = value;
    }

    public addMeasure(m: Measure): void {
    }

    public removeMeasure(m: Measure): void {
    }

    public clearMeasures(): void {
    }

    public highlightMeasure(m: Measure): void {
    }

    public dehighlightMeasure(m: Measure): void {
    }
}