import { Clouds } from "gis3d/cityvu/core/three/objects/Clouds";
import { Sky } from "gis3d/cityvu/core/three/objects/Sky";
import { BasicScene } from "gis3d/cityvu/core/three/scene/BasicScene";
import { Object3D } from "three";
import NumberUtils from "gis3d/wf/util/NumberUtils";

export class EmptyScene extends BasicScene {
    protected sky!: Sky;
    protected clouds!: Clouds;
    protected skyWrapper!: Object3D;

    public setupSky(): void {
        this.skyWrapper = new Object3D();
        this.scene3d.add(this.skyWrapper);

        this.sky = new Sky();
        this.sky.rotateX(NumberUtils.PI_OVER_2);
        this.sky.scale.multiplyScalar(10000);

        const u = this.sky.uniforms();
        u.turbidity.value = 100;
        u.rayleigh.value = 1.3;
        u.mieCoefficient.value = 0.001;
        u.mieDirectionalG.value = 0.8;
        u.luminance.value = 0.9;

        // inclination
        const theta = -0.25 * Math.PI;
        // azimuth (midday)
        const phi = Math.PI * -0.5;
        const distance = 10000;

        /*
        SUNSET
        u.turbidity.value = 1000;
        u.rayleigh.value = 2.3;
        u.mieCoefficient.value = 0.001;
        u.mieDirectionalG.value = 0.8;
        u.luminance.value = 0.9;

        // inclination
        const theta = -.0003 * Math.PI;
        */

        u.sunPosition.value.set(distance * Math.cos(phi), distance * Math.sin(phi) * Math.sin(theta), distance * Math.sin(phi) * Math.cos(theta));
        this.skyWrapper.add(this.sky);

        this.clouds = new Clouds();
        this.clouds.scale.multiplyScalar(2500);
        this.skyWrapper.add(this.clouds);
    }

    public updateScene(delta: number): void {
        super.updateScene(delta);
        this.clouds.update(delta);
        this.skyWrapper.position.copy(this.pose.position);
    }

    public init(): void {
        if (!this.initialized) {
            super.init();
            this.setupSky();
        }
    }
}
