import { FixedGrid } from "gis3d/cityvu/core/geometry/FixedGrid";
import { TiledPointCloudDataSource } from "gis3d/cityvu/data/TiledPointCloudDataSource";
import { PointCloudLoader } from "gis3d/cityvu/io/cloud/PointCloudLoader";
import { FixedGridLayer3d } from "../FixedGridLayer3d";
import { PointCloudChannel } from "./PointCloudChannel";
import { PointCloudLayer3dOptions } from "./PointCloudLayer3dOptions";
import { PointCloudTile, PointCloudTileState } from "./PointCloudTile";
import { PointColorType, PointCloudOctree } from "gis3d/cityvu/core/three/cloud/potree";
import { PromiseThrottler } from "gis3d/wf/thread/PromiseThrottler";
import { Layer3dType } from "gis3d/cityvu/core/three/scene/Layer3dType";
import { Tile } from "gis3d/cityvu/core/geometry/Tile";
import { Vector3, Box3 } from "three";

export class TiledPointCloudLayer3d extends FixedGridLayer3d {
    protected pointClouds: Map<string, PointCloudTile> = new Map();
    protected loader!: PointCloudLoader;
    protected throttler: PromiseThrottler<PointCloudOctree>;

    protected boxBuffer: Box3 = new Box3();
    protected buffer: Vector3 = new Vector3();
    protected sortComparisonFunction: (a: Tile, b: Tile) => number = (a, b) => {
        return (
            this.cameraLastPosition.distanceToSquared(this.grid.getTileCenter(a, this.buffer)) - this.cameraLastPosition.distanceToSquared(this.grid.getTileCenter(b, this.buffer))
        );
    };
    // TODO use a LRU cache for pointcloud objects as we want to avoid reistantiating if moving back and forth

    public constructor(readonly options: PointCloudLayer3dOptions, readonly dataSource: TiledPointCloudDataSource) {
        super();
        if (this.options.channel == null) {
            this.options.channel = PointCloudChannel.INTENSITY;
        }
        this.grid = new FixedGrid(dataSource.options.grid.bounds, dataSource.options.grid.level);
        this.throttler = new PromiseThrottler<PointCloudOctree>();
        this.cameraBuffer.set(400, 400, 800);
    }

    public get type(): Layer3dType {
        return Layer3dType.POINTCLOUD;
    }

    public onEngineAssigment(): void {
        this.loader = new PointCloudLoader(this.engine!.cloudEngine);
        super.onEngineAssigment();
    }

    protected onTileUpdate(tileChanged: boolean): void {
        if (tileChanged) {
            const grid = this.grid;
            const buf = this.buffer;
            const bufBox = this.boxBuffer;
            const frustum = this.engine.scene.frustum;
            const visibleTiles: Array<Tile> = [];

            for (const tile of this.activeTiles.items()) {
                // frustum filtering
                bufBox.makeEmpty();
                grid.getTilePoint(tile, 0, 0, buf);
                buf.z = -65000;
                bufBox.expandByPoint(buf);

                grid.getTilePoint(tile, 0, 1, buf);
                bufBox.expandByPoint(buf);

                grid.getTilePoint(tile, 1, 0, buf);
                bufBox.expandByPoint(buf);

                grid.getTilePoint(tile, 1, 1, buf);
                buf.z = 65000;
                bufBox.expandByPoint(buf);

                if (frustum.intersectsBox(bufBox)) {
                    visibleTiles.push(tile);
                } else {
                    let pcTile = this.pointClouds.get(tile.id);
                    if (pcTile != null && pcTile.state == PointCloudTileState.VISIBLE) {
                        pcTile!.state = PointCloudTileState.HIDDEN;
                        pcTile.cloud!.visible = false;
                    }
                }
            }
            // sort by distance
            visibleTiles.sort(this.sortComparisonFunction);

            // clouds to load
            for (const tile of visibleTiles) {
                let pcTile = this.pointClouds.get(tile.id);
                if (pcTile == null) {
                    // TODO check if in lru cache to restore
                    pcTile = new PointCloudTile();
                    pcTile.copy(tile);
                    this.pointClouds.set(pcTile.id, pcTile);
                }
                if (pcTile.state == PointCloudTileState.INITIALIZED) {
                    // QUEUE TILE FOR LOADING
                    pcTile.state = PointCloudTileState.WAITING;
                    this.throttler
                        .enqueue(
                            args => {
                                pcTile!.state = PointCloudTileState.LOADING;
                                return this.loader.load(args);
                            },
                            {
                                filename: "cloud.js",
                                baseUrl: this.dataSource.url + pcTile.id + "/",
                            },
                        )
                        .then(
                            pc => {
                                this.engine.scene.crs.coordsToPoint(pc.position, pc.position);
                                switch (this.options.channel) {
                                    case PointCloudChannel.INTENSITY:
                                        pc.material.pointColorType = PointColorType.INTENSITY;
                                        break;
                                    case PointCloudChannel.RGB:
                                        pc.material.pointColorType = PointColorType.RGB;
                                        break;
                                }

                                this.engine.cloudEngine.add(pc);
                                pcTile!.cloud = pc;
                                pcTile!.state = PointCloudTileState.VISIBLE;
                                this.add(pc);
                            },
                            err => {
                                pcTile!.state = PointCloudTileState.ERROR;
                            },
                        );
                } else if (pcTile.state == PointCloudTileState.HIDDEN) {
                    pcTile!.state = PointCloudTileState.VISIBLE;
                    pcTile.cloud!.visible = true;
                }
            }

            // clouds to dispose
            for (const tile of this.toBeRemovedTiles.items()) {
                const pcTile = this.pointClouds.get(tile.id);
                if (pcTile != null) {
                    pcTile.state = PointCloudTileState.DISPOSED;
                    if (pcTile.cloud != null) {
                        this.remove(pcTile.cloud);
                        this.engine.cloudEngine.remove(pcTile.cloud);
                    }
                    // TODO move to LRU instead of deletion
                    this.pointClouds.delete(tile.id);
                }
            }
        }
    }
}
