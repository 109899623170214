import proj4 from "proj4";

export class GeoUtils {
    public static readonly epsg = (code: string | number): string => "EPSG:" + code;
    public static readonly cityvuCrs = (code: string | number): string => "CITYVU:" + code;
    public static readonly cityvuCrsSimple = GeoUtils.cityvuCrs("simple");

    public static readonly proj4defs = (): void => {
        proj4.defs("EPSG:6708", "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
    };
}
