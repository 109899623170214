// TODO timed messages, message browsing, message importance
export enum MessageType {
    NORMAL,
    IMPORTANT
}

export interface Message {
    id: string;
    content: string;
    type: MessageType;
    closable: boolean;
}

export type MessageRemover = () => void;
