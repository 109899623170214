import dom from "gis3d/wf/util/DomUtils";
import { DomElementOptions } from "gis3d/wf/util/DomUtils";
import { Box } from "gis3d/wf/ui/geom/Box";
import { Size } from "gis3d/wf/ui/geom/Size";
import { UiComponent } from "gis3d/wf/ui/UiComponent";
import ui from "gis3d/wf/ui/style/UiStyle";

export abstract class BaseUiComponent<T extends HTMLElement = HTMLElement> implements UiComponent<T> {
    protected _parent: UiComponent | null = null;
    protected _domNode: T | null = null;
    protected _visible: boolean = true;
    protected _displayed: boolean = true;
    protected _identifier?: string;
    protected initialized: boolean = false;
    protected started: boolean = false;
    protected domElementType: string = "div";
    protected domElementOptions: DomElementOptions;

    public constructor() {
        this.domElementOptions = {};
    }

    public prepareBuildOptions(): void {

    }

    public build(): void {
        this.prepareBuildOptions();
        this._domNode = <T>dom.el(this.domElementType, this.domElementOptions);
        this.applyDisplayed();
        this.applyVisible();
    }

    protected attachEventListeners(): void {
    }

    protected detachEventListeners(): void {
    }

    public init(): void {
        this.build();
        this.attachEventListeners();
        this.initialized = true;
    }

    public startup(): Promise<void> | void {
        if (this.isStarted()) {
            return;
        }
        this.started = true;
        this.resize();
        return this.render();
    }

    public render(): Promise<void> | void {
    }

    public resize(box: Box | null = null): Size | null {
        if (box != null) {
            dom.setBox(this.domNode!, box);
        }
        return dom.marginBox(this.domNode!);
    }

    public get domNode(): T | null {
        return this._domNode;
    }

    public get parent(): UiComponent | null {
        return this._parent;
    }

    public set parent(par: UiComponent | null) {
        this._parent = par;
    }

    public isInitialized = () => this.initialized;

    public isStarted = () => this.started;

    public set(html: string): void {
        dom.set(this.domNode!, html);
    }

    public addClasses(classes: Array<string>): void {
        dom.addClasses(this.domNode!, classes);
    }

    public isAttached = () => this.domNode!.parentElement != null;

    public attach(parent: Element): void {
        if (!this.isAttached()) {
            dom.append(parent, this.domNode!);
        }
    }

    public detach(): void {
        let parentNode: Node | null = this.domNode!.parentNode;
        if (parentNode != null && parentNode instanceof Element) {
            dom.remove(parentNode, this.domNode!);
        }
    }

    public destroy(): void {
        this.detach();
        this.detachEventListeners();
        this._domNode = null;
    }

    public get visible(): boolean {
        return this._visible;
    }

    public set visible(v: boolean) {
        this._visible = v;
        this.applyVisible();
    }

    public get displayed(): boolean {
        return this._displayed;
    }

    public set displayed(v: boolean) {
        this._displayed = v;
        this.applyDisplayed();
    }

    protected applyVisible(): void {
        if (this.domNode != null) {
            if (this.visible) {
                dom.removeClass(this.domNode!, ui.invisible);
            }
            else {
                dom.addClass(this.domNode!, ui.invisible);
            }
        }
    }

    protected applyDisplayed(): void {
        if (this.domNode != null) {
            if (this.displayed) {
                dom.removeClass(this.domNode!, ui.Display.d(ui.Display.none));
            }
            else {
                dom.addClass(this.domNode!, ui.Display.d(ui.Display.none));
            }
        }
    }

    public get identifier(): string | undefined {
        return this._identifier;
    }

    public set identifier(i: string | undefined) {
        this._identifier = i;
    }
}
