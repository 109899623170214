import { AppEventEmitter } from "./AppEventEmitter";
import { AppEventListener } from "./AppEventListener";
import { AppEvent } from "./AppEvent";

type Constructor<T = {}> = new (...args: any[]) => T;

export function AppEventEmitterDecorator<TBase extends Constructor>(Base: TBase) {
    return class extends Base implements AppEventEmitter {
        protected listeners: Map<string, Array<AppEventListener>>;

        constructor(...args: any[]) {
            super(...args);
            this.listeners = new Map<string, Array<AppEventListener>>();
        }

        public addEventListener(listener: AppEventListener, eventType: string): void {
            let typeListeners = this.listeners.get(eventType);
            if (typeListeners == undefined) {
                typeListeners = [] as Array<AppEventListener>;
                this.listeners.set(eventType, typeListeners);
            }

            if (typeListeners.indexOf(listener) === -1) {
                typeListeners.push(listener);
            }
        }

        public hasEventListener(listener: AppEventListener, eventType: string): boolean {
            const typeListeners = this.listeners.get(eventType);
            return typeListeners != undefined && typeListeners.indexOf(listener) !== -1;
        }

        public removeEventListener(listener: AppEventListener, eventType?: string): void {
            if (eventType != undefined) {
                const typeListeners = this.listeners.get(eventType);
                if (typeListeners !== undefined) {
                    const idx = typeListeners.indexOf(listener);
                    if (idx !== -1) {
                        typeListeners.splice(idx, 1);
                    }
                }
            } else {
                this.listeners.forEach((typeListeners, key, map) => {
                    const idx = typeListeners.indexOf(listener);
                    if (idx !== -1) {
                        typeListeners.splice(idx, 1);
                    }
                });
            }
        }

        public removeEventListeners(eventType?: string | undefined): void {
            if (eventType != undefined) {
                this.listeners.delete(eventType);
            } else {
                this.listeners.clear();
            }
        }

        public dispatchEvent(event: AppEvent): boolean {
            let shouldContinue = true;
            const typeListeners = this.listeners.get(event.type);
            if (typeListeners !== undefined) {
                event.target = this;
                const trapped = typeListeners.some((listener) => {
                    return listener.call(this, event) === false;
                });
                shouldContinue = !trapped;
            }
            return shouldContinue;
        }

    };

}