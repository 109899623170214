import { Intersection } from "three";

export class PickResults {
    private _objects: Map<string, Array<Intersection>>;

    public constructor() {
        this._objects = new Map();
    }

    public get objects(): Map<string, Array<Intersection>> {
        return this._objects;
    }

    public set objects(value: Map<string, Array<Intersection>>) {
    }
}