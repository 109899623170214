import { Ws } from "../Ws";
import { SignalingMessage } from "gis3d/wf/net/rtc/SignalingMessage";
import { SignalingMessageFactory } from "gis3d/wf/net/rtc/SignalingMessageFactory";
import run from "gis3d/wf/util/RuntimeUtils";

export enum SignalingChannelStatus {
    CONNECTING = 0,
    OPEN = 1,
    CLOSING = 2,
    CLOSED = 3
}

export class SignalingChannel {
    private _ws : Ws;
    private _messageFactory : SignalingMessageFactory;
    public onStatusChange? : (channel:SignalingChannel, status:SignalingChannelStatus) => void;
    public onMessage? : (channel:SignalingChannel, message:SignalingMessage) => void;
    public onError? : (channel:SignalingChannel, ev:Event) => void;

    public constructor(readonly url:string) { 
        this._ws = new Ws(url);
        this.ws.onMessage = run.bind(this, this._onMessage);
        this.ws.onClose = run.bind(this, this._onStatusChange);
        this.ws.onOpen = run.bind(this, this._onStatusChange);
        this.ws.onError = run.bind(this, this._onError);

        this._messageFactory = new SignalingMessageFactory();
    }

    public get ws() : Ws {
        return this._ws;
    }

    public get messageFactory() : SignalingMessageFactory {
        return this._messageFactory;
    }

    public get status() : SignalingChannelStatus {
        if (this.ws.socket) {
            return <SignalingChannelStatus>this.ws.socket.readyState;
        }
        return SignalingChannelStatus.CLOSED;
    }

    public open() : void {
        if (!this.isOpen()) {
            this.ws.open();
        }
    }

    public isOpen() : boolean {
        return this.ws.socket != null && this.ws.socket.readyState === WebSocket.OPEN;
    }

    public send(message:SignalingMessage) : void {
        if (this.isOpen()) {
            this.ws.send(message.toJsonString());
        }
    }

    public close() : void {
        if (this.isOpen()) {
            console.log("Close() called");
            this.ws.close();
        }
    }

    protected _onMessage(ev:MessageEvent) : void {
        const message = SignalingMessage.fromJsonString(ev.data);
        if (this.onMessage) {
            this.onMessage(this, message);
        }
    }

    protected _onStatusChange(ev:Event) : void {
        if (this.onStatusChange) {
            this.onStatusChange(this, this.status);
        }
    }

    protected _onError(ev:Event) : void {
        if (this.onError) {
            this.onError(this, ev);
        }
    }

}