export interface XhrRequest {
    url : string;
    method? : string;
    headers? : { [key: string] : string };
    body? : BodyInit;
    responseType?:XMLHttpRequestResponseType;
    returnFullXhr? : boolean;
}

export class Xhr {
    public static readonly request = (request:XhrRequest) : Promise<any> => (new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.open(request.method || "GET", request.url);
        xhr.responseType = request.responseType || "";

        if (request.headers) {
            for (let key in request.headers) {
                xhr.setRequestHeader(key, request.headers[key]);
            }
        }

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(request.returnFullXhr ? xhr : xhr.response);
            } else {
                reject(request.returnFullXhr ? xhr : xhr.status);
            }
        };
        xhr.onerror = () => reject(xhr.status);

        xhr.send(request.body);
    }))
}