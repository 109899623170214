export class RtcActions {
    public static readonly LOAD : string = "LOAD";
    public static readonly LOADED : string = "LOADED";
    public static readonly LOADERROR : string = "LOADERROR";
    public static readonly READY : string = "READY";
    public static readonly RESET : string = "RESET";
    public static readonly ACK : string = "ACK";
    public static readonly STARTMEASURING : string = "STARTMEASURING";
    public static readonly STOPMEASURING : string = "STOPMEASURING";
    public static readonly MEASUREMENT : string = "MEASUREMENT";
    public static readonly SETPOSITION : string = "SETPOSITION";
    public static readonly POSITION : string = "POSITION";
    public static readonly CONTROLS : string = "CONTROLS";
}