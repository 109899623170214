import { SceneDefinitionDataSource } from "../scene/definition/SceneDefinitionDataSource";
import { DataSourceType } from "./DataSourceType";
import { PointCloudDataSource } from "./PointCloudDataSource";
import { Bounds } from "../core/geometry/Bounds";

export interface TiledPointCloudDataSourceOptions {
    grid: {
        bounds: Bounds;
        level: number;
    };
}

export class TiledPointCloudDataSource extends PointCloudDataSource {
    protected _options: TiledPointCloudDataSourceOptions;
    public constructor(readonly definition: SceneDefinitionDataSource) {
        super(definition);

        if (definition.options == null || definition.options.grid == null || definition.options.grid.bounds == null || definition.options.grid.level == null) {
            throw Error("tiledPointCloudDataSource.error.optionsUndefined");
        }
        const bounds = new Bounds();
        bounds.min.set(definition.options.grid.bounds.minX, definition.options.grid.bounds.minY, definition.options.grid.bounds.minZ);
        bounds.max.set(definition.options.grid.bounds.maxX, definition.options.grid.bounds.maxY, definition.options.grid.bounds.maxZ);

        this._options = {
            grid: {
                bounds: bounds,
                level: definition.options.grid.level,
            },
        } as TiledPointCloudDataSourceOptions;
    }

    public get type(): DataSourceType {
        return DataSourceType.TILED_POINTCLOUD;
    }

    public get options(): TiledPointCloudDataSourceOptions {
        return this._options;
    }
}
