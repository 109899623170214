import { Vector3 } from "three";
import { AnimatedControls } from "./AnimatedControls";
import { InputManagerEvent } from "../../event/InputManager";
import { AppEvent } from "../../event/AppEvent";
import { DragData } from "../../event/DragData";
import MouseUtils from "gis3d/wf/util/MouseUtils";
import { WheelData } from "../../event/WheelData";

export class FpsControls extends AnimatedControls {
    public movementSpeedMultiplier: number = 1.0;
    public movementSpeed: number = 5.0;
    public rotationSpeed: number = 0.4;
    public walkingElevationLock: boolean = false;
    public frictionAmount: number = 12.0;
    public wheelMultiplier: number = 5.0;

    protected panDelta: Vector3 = new Vector3();
    protected rotationDelta: Vector3 = new Vector3(); // as yaw, pitch, roll
    protected worldDelta: Vector3 = new Vector3();
    protected userInput: boolean = false;
    protected buffer: Vector3 = new Vector3();
    protected keyState = {
        up: 0,
        down: 0,
        left: 0,
        right: 0,
        forward: 0,
        back: 0,
    };

    public enable(): void {
        this.stop();
        this.attachKeyboardEvents();
        this.attachMouseEvents();
    }

    protected attachMouseEvents() {
        this.addEventListener(this.drag, InputManagerEvent.DRAG);
        this.addEventListener(this.drop, InputManagerEvent.DROP);
        this.addEventListener(this.wheel, InputManagerEvent.MOUSEWHEEL);
    }

    protected attachKeyboardEvents() {
        this.addEventListener(this.keyDown, InputManagerEvent.KEYDOWN);
        this.addEventListener(this.keyUp, InputManagerEvent.KEYUP);
    }

    public disable(): void {
        this.removeEventListeners();
    }

    public update(delta: number): void {
        if (this.scene == null) {
            return;
        }

        // remove automations if user input has been detected
        if (this.userInput) {
            this.clearTweens();
            this.userInput = false;
        }

        // manage keys
        if (this.walkingElevationLock) {
            const dir = this.scene.pose.direction;
            dir.y = 0;
            dir.normalize();
            // translate along direction but only in XZ (not Y)
            if (this.keyState.forward) {
                this.worldDelta.add(dir.multiplyScalar(this.movementSpeed));
            } else if (this.keyState.back) {
                this.worldDelta.add(dir.multiplyScalar(-this.movementSpeed));
            }
        } else {
            // pan along direction
            if (this.keyState.forward) {
                this.panDelta.y += this.movementSpeed;
            } else if (this.keyState.back) {
                this.panDelta.y += -this.movementSpeed;
            }
        }

        if (this.keyState.right) {
            this.panDelta.x += this.movementSpeed;
        } else if (this.keyState.left) {
            this.panDelta.x += -this.movementSpeed;
        }

        if (this.keyState.up) {
            this.worldDelta.z += this.movementSpeed;
        } else if (this.keyState.down) {
            this.worldDelta.z += -this.movementSpeed;
        }

        // rotate
        this.scene.pose.yaw -= this.rotationDelta.x * delta;
        this.scene.pose.pitch -= this.rotationDelta.y * delta;

        // pan
        this.buffer.copy(this.panDelta).multiplyScalar(delta);
        this.scene.pose.pan3(this.buffer);

        // translate
        this.buffer.copy(this.worldDelta).multiplyScalar(delta);
        this.scene.pose.translate(this.buffer);

        // apply friction
        const friction = Math.max(1 - this.frictionAmount * delta, 0);
        this.panDelta.multiplyScalar(friction);
        this.rotationDelta.multiplyScalar(friction);
        this.worldDelta.multiplyScalar(friction);
    }

    public stop(): void {
        super.stop();
        this.rotationDelta.set(0, 0, 0);
        this.panDelta.set(0, 0, 0);
        this.worldDelta.set(0, 0, 0);
    }

    public wheel(appEvent: AppEvent): void {
        const data = appEvent.value as WheelData;

        // move forward or backward
        this.panDelta.y += data.delta * this.movementSpeed * this.wheelMultiplier;
    }

    public drag(appEvent: AppEvent): void {
        const dragData = appEvent.value as DragData;

        this.userInput = true;
        if (MouseUtils.isLeft(dragData.buttons)) {
            // yaw
            this.rotationDelta.x = dragData.delta.x * this.rotationSpeed;
            // pitch
            this.rotationDelta.y = dragData.delta.y * this.rotationSpeed;
            this.rotationDelta.z = 0;
        } else if (MouseUtils.isRight(dragData.buttons)) {
            this.panDelta.x += dragData.delta.x * this.movementSpeed;
            this.worldDelta.z -= dragData.delta.y * this.movementSpeed;
        }
    }

    public drop(appEvent: AppEvent): void {
        this.userInput = true;
    }

    public keyUp(appEvent: AppEvent): void {
        this.userInput = true;
        const event = (appEvent as AppEvent<KeyboardEvent>).value!;
        switch (event.keyCode) {
            //case 16: /* shift */ this.movementSpeedMultiplier = 1; break;

            case 87:
                /*W*/ this.keyState.forward = 0;
                break;
            case 83:
                /*S*/ this.keyState.back = 0;
                break;

            case 65:
                /*A*/ this.keyState.left = 0;
                break;
            case 68:
                /*D*/ this.keyState.right = 0;
                break;

            case 82:
                /*R*/ this.keyState.up = 0;
                break;
            case 70:
                /*F*/ this.keyState.down = 0;
                break;
        }
    }

    public keyDown(appEvent: AppEvent): void {
        this.userInput = true;
        const event = (appEvent as AppEvent<KeyboardEvent>).value!;
        if (event.altKey) {
            return;
        }

        switch (event.keyCode) {
            //case 16: /* shift */ this.movementSpeedMultiplier = .1; break;

            case 87:
                /*W*/ this.keyState.forward = 1;
                break;
            case 83:
                /*S*/ this.keyState.back = 1;
                break;

            case 65:
                /*A*/ this.keyState.left = 1;
                break;
            case 68:
                /*D*/ this.keyState.right = 1;
                break;

            case 82:
                /*R*/ this.keyState.up = 1;
                break;
            case 70:
                /*F*/ this.keyState.down = 1;
                break;
        }
    }
}
