export class Version {
    public version: string;
    public versionMajor: number;
    public versionMinor: number = 0;

    public constructor(version: string) {
        this.version = version;

        const vmLength = version.indexOf(".") === -1 ? version.length : version.indexOf(".");
        this.versionMajor = parseInt(version.substr(0, vmLength), 10);
        this.versionMinor = parseInt(version.substr(vmLength + 1), 10);
        if (isNaN(this.versionMinor)) {
            this.versionMinor = 0;
        }
    }

    public newerThan(version: string): boolean {
        const v = new Version(version);
        return this.newerThanN(v.versionMajor, v.versionMinor);
    }

    public newerThanN(versionMajor: number, versionMinor: number): boolean {
        if (this.versionMajor > versionMajor) {
            return true;
        } else if (this.versionMajor === versionMajor && this.versionMinor > versionMinor) {
            return true;
        }
        return false;
    }

    public equalOrHigher(version: string): boolean {
        const v = new Version(version);
        return this.equalOrHigherN(v.versionMajor, v.versionMinor);
    }

    public equalOrHigherN(versionMajor: number, versionMinor: number): boolean {
        if (this.versionMajor > versionMajor) {
            return true;
        } else if (this.versionMajor === versionMajor && this.versionMinor >= versionMinor) {
            return true;
        }
        return false;
    }

    public upToN(versionMajor: number, versionMinor: number): boolean {
        return !this.newerThanN(versionMajor, versionMinor);
    }

    public upTo(version: string): boolean {
        return !this.newerThan(version);
    }
}
