import { SceneDefinitionControls } from "../SceneDefinitionControls";
import { SceneDefinitionNavigationControls } from "../SceneDefinitionNavigationControls";
import { SceneDefinitionMeasurementsControls } from "../SceneDefinitionMeasurementsControls";
import { SceneDefinitionTools } from "../SceneDefinitionTools";
import { SceneDefinitionDrivingMode } from "../SceneDefinitionDrivingMode";

export class DefaultSceneControls implements SceneDefinitionControls {
    public navigation: SceneDefinitionNavigationControls;
    public measurements: SceneDefinitionMeasurementsControls;
    public tools: SceneDefinitionTools;

    constructor() {
        this.navigation = {} as SceneDefinitionNavigationControls;
        this.measurements = {} as SceneDefinitionMeasurementsControls;
        this.tools = {} as SceneDefinitionTools;
        this.tools.drivingMode = {} as SceneDefinitionDrivingMode;
    }
}
