import { SceneDefinitionLayerType as SDLayerType } from "../definition/SceneDefinitionLayerType";
import { Layer3d } from "gis3d/cityvu/core/three/scene/Layer3d";
import { DataSource } from "gis3d/cityvu/data/DataSource";
import { Layer3dBuilder } from "./builder/Layer3dBuilder";
import { PointCloudLayer3dBuilder } from "./builder/PointCloudLayer3dBuilder";

export class Layer3dFactory {
    public static readonly registry = new Map<SDLayerType, Layer3dBuilder>([
        //
        [SDLayerType.POINTCLOUD, new PointCloudLayer3dBuilder()],
    ]);

    public static create<T = any>(type: SDLayerType, options: T, dataSource: DataSource): Layer3d {
        const builder = Layer3dFactory.registry.get(type);
        if (builder != null) {
            return builder.build(options, dataSource);
        }
        throw new Error("layer3dFactory.typeNotImplemented");
    }
}
