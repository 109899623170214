import { Box3, Vector3 } from "three";
import { BinaryLoader, XhrRequest } from "./loading";
import { PointAttributes } from "./point-attributes";
import { PointCloudOctreeGeometryNode } from "./point-cloud-octree-geometry-node";

export class PointCloudOctreeGeometry {
    public disposed: boolean = false;
    public needsUpdate: boolean = true;
    public root!: PointCloudOctreeGeometryNode;
    public octreeDir: string = "";
    public hierarchyStepSize: number = -1;
    public nodes: Record<string, PointCloudOctreeGeometryNode> = {};
    public numNodesLoading: number = 0;
    public maxNumNodesLoading: number = 3;
    public spacing: number = 0;
    public pointAttributes: PointAttributes = new PointAttributes([]);
    public projection: any = null;
    public url: string | null = null;

    public constructor(public loader: BinaryLoader, public boundingBox: Box3, public tightBoundingBox: Box3, public offset: Vector3, public xhrRequest: XhrRequest) {}

    public dispose(): void {
        this.loader.dispose();

        let stack: Array<PointCloudOctreeGeometryNode> = [];
        stack.push(this.root);
        while (stack.length > 0) {
            let current = stack.pop();
            if (current != null) {
                current.dispose();

                for (const child of current.children) {
                    if (child != null && child.loaded) {
                        stack.push(child);
                    }
                }
            }
        }
        this.disposed = true;
    }

    public addNodeLoadedCallback(callback: (node: PointCloudOctreeGeometryNode) => void): void {
        this.loader.callbacks.push(callback);
    }

    public clearNodeLoadedCallbacks(): void {
        this.loader.callbacks = [];
    }
}
