import { SceneDefinition } from "./SceneDefinition";
import { GuiOptions, DefaultGuiOptions } from "../../gui/GuiOptions";

export class SceneDefinitionGuiOptions {
    public static get(def: SceneDefinition): GuiOptions {
        const gui = new DefaultGuiOptions();

        gui.enabledControls.constrained = def.controls.navigation.constrained;
        gui.enabledControls.earth = def.controls.navigation.earth;
        gui.enabledControls.fps = def.controls.navigation.fps;
        gui.enabledControls.orbit = def.controls.navigation.orbit;

        gui.controls = gui.enabledControls.constrained || gui.enabledControls.earth || gui.enabledControls.fps || gui.enabledControls.orbit;

        gui.selectedControls = def.settings.control;

        gui.tools.compass = def.controls.tools.compass;
        gui.tools.layerSelector = def.controls.tools.layers;
        gui.tools.navigator = def.controls.tools.drivingMode.enabled;
        gui.tools.info = def.controls.tools.info;

        gui.enabledMeasurements.angle = def.controls.measurements.angle;
        gui.enabledMeasurements.area = def.controls.measurements.area;
        gui.enabledMeasurements.info = def.controls.measurements.info;
        gui.enabledMeasurements.line = def.controls.measurements.line;

        gui.measurements = gui.enabledMeasurements.angle || gui.enabledMeasurements.area || gui.enabledMeasurements.info || gui.enabledMeasurements.line;

        return gui;
    }
}
