import * as _ from "lodash";

import aspect from "gis3d/wf/core/Aspect";

export default class RuntimeUtils {

    public static readonly useBufferMessage = () => {
        console.error("Please use buffer vector.");
    }

    public static readonly applyMixins = (derivedCtor: any, baseCtors: any[]) => {
        baseCtors.forEach(baseCtor => {
            Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
                derivedCtor.prototype[name] = baseCtor.prototype[name];
            });
        });
    }

    public static readonly bind = (ctx: any, func: Function, ...args: any[]): any => {
        if (args != null && args.length > 0) {
            return func.bind(ctx, ...args);
        }
        return func.bind(ctx);
    }

    public static cloneDeep = (source: object): object => {
        return _.cloneDeep(source);
    }

    public static readonly extend = (destination: object, ...sources: object[]): object => {
        return _.assignIn(destination, ...sources);
    }

    public static readonly before = (context: any, method: string, advice: Function): Function => aspect.before(context, method, advice);

    public static readonly around = (context: any, method: string, advice: Function): Function => aspect.around(context, method, advice);

    public static readonly after = (context: any, method: string, advice: Function): Function => aspect.after(context, method, advice);

    public static readonly isMobile = (): boolean => {
        const regex = new RegExp("Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini", "i");
        return regex.test(navigator.userAgent);
    }

}