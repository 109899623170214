import { BasicScene } from "gis3d/cityvu/core/three/scene/BasicScene";
import { Experiment } from "./Experiment";
import { Scene } from "../core/three/scene/Scene";
import { ShaderMaterial, BoxGeometry, Vector2, Mesh } from "three";
import vs from "./BoxShader.vs";
import fs from "./BoxShader.fs";
import { Layer3d } from "../core/three/scene/Layer3d";
import { CrsFactory } from "../core/geo/crs/CrsFactory";
import { GeoUtils } from "../core/geo/GeoUtils";

class ExperimentScene extends BasicScene {
    private geometry!: BoxGeometry;
    private material!: ShaderMaterial;
    private obj!: Mesh;
    private rx: number = 0 * 0.001;
    private ry: number = 0 * 0.002;
    private vel: number[] = [0, 0, 0];
    private startTime!: number;

    constructor() {
        super();
    }

    public init(): void {
        super.init();
        this.crs = CrsFactory.create(GeoUtils.cityvuCrs("simple"));
        this.geometry = new BoxGeometry(30, 30, 30);

        const uniforms = {
            iGlobalTime: {
                type: "f",
                value: 1.0,
            },
            iResolution: {
                type: "v2",
                value: new Vector2(),
            },
        };

        uniforms.iResolution.value.x = 0.5; // window.innerWidth;
        uniforms.iResolution.value.y = 0.5; // window.innerHeight;

        this.material = new ShaderMaterial({
            uniforms: uniforms,
            vertexShader: vs,
            fragmentShader: fs,
        });

        this.obj = new Mesh(this.geometry, this.material);
        this.startTime = Date.now();

        const layer = new Layer3d();
        layer.add(this.obj);
        this.add(layer);

        this.obj.position.x = 0;
        this.obj.position.y = 50;
        this.obj.position.z = 0;
    }

    public updateScene(delta: number): void {
        super.updateScene(delta);

        this.obj.rotation.x += this.rx;
        this.obj.rotation.y += this.ry;
        this.obj.position.x += this.vel[0];
        this.obj.position.y += this.vel[1];
        this.obj.position.z += this.vel[2];

        var elapsedMilliseconds = Date.now() - this.startTime;
        var elapsedSeconds = elapsedMilliseconds / 1000;
        this.material.uniforms.iGlobalTime.value = elapsedSeconds;
    }
}

export default class BoxShaderExperiment implements Experiment {
    public scene: Scene = new ExperimentScene();
}
