import dom from "gis3d/wf/util/DomUtils";
import ui from "gis3d/wf/ui/style/UiStyle";
import { UiComponent } from "gis3d/wf/ui/UiComponent";
import { UiContainer } from "gis3d/wf/ui/UiContainer";

export class Navbar extends UiContainer {
    protected _navbarBrand! : HTMLElement;
    protected _navbarToggler! : HTMLElement;
    protected _navbarCollapse! : HTMLElement;
    protected navbarCollapseId : string;
    public bgClass : string = ui.Color.bgPrimary;
    public barStyleClass : string = ui.Navbar.navbarLight;
    public brandLink : string | null = null;
    public ariaLabel : string = "Toggle navigation";
    public collapseAt : string | null = null;

    constructor() {
        super();
        this.navbarCollapseId = "navbarSupportedContent";
    }

    public prepareBuildOptions() : void {
        let expandClass : string = ui.Navbar.navbarExpand;
        if (this.collapseAt) {
            expandClass += "-" + this.collapseAt;
        }
        this.domElementType = "nav";
        this.domElementOptions.classes = [ 
            ui.Navbar.navbar, 
            expandClass,
            this.barStyleClass,
            this.bgClass
        ];
    }

    public build() {
        super.build();
        this.createBrand();
        this.createToggler();
        this.createCollapse();
        this.childrenHolderNode = this._navbarCollapse;
    }

    protected createBrand() : void {
        this._navbarBrand = dom.el("a", { classes : [ ui.Navbar.navbarBrand ]});
        if (this.brandLink) {
            dom.setAttr(this._navbarBrand, "href", this.brandLink);
        }
        dom.append(this.domNode!, this._navbarBrand);
    }

    protected createToggler() : void {
        this._navbarToggler = dom.el("button", { classes : [ ui.Navbar.navbarToggler ]});
        dom.append(this._navbarToggler, dom.el("span", { classes : [ ui.Navbar.navbarTogglerIcon] }));
        dom.setData(this._navbarToggler, "toggle", "collapse");
        dom.setData(this._navbarToggler, "target", "#" + this.navbarCollapseId);
        dom.setAttr(this._navbarToggler, "type", "button");
        dom.setAttr(this._navbarToggler, "aria-controls", this.navbarCollapseId);
        dom.setAttr(this._navbarToggler, "aria-expanded", "false");
        dom.setAttr(this._navbarToggler, "aria-label", this.ariaLabel);
        dom.append(this.domNode!, this._navbarToggler);
    }

    protected createCollapse() : void {
        this._navbarCollapse = dom.el("div", { 
            classes : [ ui.Navbar.navbarCollapse, ui.collapse ],
            id : this.navbarCollapseId
        });
        dom.append(this.domNode!, this._navbarCollapse);
    }

    public get navbarBrand() : HTMLElement {
        return this._navbarBrand!;
    }

    public get navbarCollapse() : HTMLElement {
        return this._navbarCollapse!;
    }

    public get navbarToggler() : HTMLElement {
        return this._navbarToggler!;
    }
}