
export interface UrlData {
}

/**
 * Decoupling UrlParser from UrlProcessor allows better extension and could
 * be used to have small "pieces" that can be combined together (for instance
 * a hostname / path processor with a hash processor).
 */
export interface UrlProcessor<T extends UrlData = UrlData> {
    process(url:URL) : T | null;
}

export class UrlParser<T extends UrlData = UrlData> {
    constructor(readonly processor:UrlProcessor<T>) {
    }

    public parse(url?:string | URL) : T | null {
        let u : URL | null = null;
        try {
            if (url == null) {
                u = new URL(window.location.href);
            } else if (url instanceof URL) {
                u = url!;
            } else {
                u = new URL(url);
            }
        } catch {
            return null;
        }
    
        return this.processor.process(u);
    }
}