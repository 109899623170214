import { Scene } from "../Scene";
import { Vector2, Vector3, Intersection } from "three";

export type PickRequestFilter = (intersection: Intersection) => boolean;

export class PickRequest {
    private _scene!: Scene;
    private _normalizedCoords: Vector2 | null = null;
    private _origin: Vector3 | null = null;
    private _direction: Vector3 | null = null;
    private _linePrecision: number = 0.5;
    private _far: number = Infinity;
    private _near: number = 0;
    private _layers: string[] | null = null;
    private _filter: PickRequestFilter | null = null;
    private _layerFilter: Map<string, PickRequestFilter> | null = null;

    /** Pick only in layers listed here if not null */
    public get layers(): string[] | null {
        return this._layers;
    }

    public set layers(value: string[] | null) {
        this._layers = value;
    }

    public get direction(): Vector3 | null {
        return this._direction;
    }

    public set direction(value: Vector3 | null) {
        this._direction = value;
    }

    public get origin(): Vector3 | null {
        return this._origin;
    }

    public set origin(value: Vector3 | null) {
        this._origin = value;
    }

    public get scene(): Scene {
        return this._scene;
    }

    public set scene(value: Scene) {
        this._scene = value;
    }

    public get normalizedCoords(): Vector2 | null {
        return this._normalizedCoords;
    }

    public set normalizedCoords(value: Vector2 | null) {
        this._normalizedCoords = value;
    }

    public get linePrecision(): number {
        return this._linePrecision;
    }

    public set linePrecision(value: number) {
        this._linePrecision = value;
    }

    public get near(): number {
        return this._near;
    }

    public set near(value: number) {
        this._near = value;
    }

    public get far(): number {
        return this._far;
    }

    public set far(value: number) {
        this._far = value;
    }

    public get layerFilter(): Map<string, PickRequestFilter> | null {
        return this._layerFilter;
    }

    public set layerFilter(value: Map<string, PickRequestFilter> | null) {
        this._layerFilter = value;
    }

    public get filter(): PickRequestFilter | null {
        return this._filter;
    }

    public set filter(value: PickRequestFilter | null) {
        this._filter = value;
    }
}