import { Measure } from "./Measure";
import { MeasureType } from "./MeasureType";

export class MeasuresCollector {
    protected measures: Map<MeasureType, Array<Measure>>;
    // callbacks
    public onCollect = (measure: Measure): void => { };
    public onRemove = (measure: Measure): void => { };
    public onClear = (type?: MeasureType): void => { };

    constructor() {
        this.measures = new Map();
        this.clear();
    }

    public get(measureType: MeasureType): Array<Measure> {
        return this.measures.get(measureType)!;
    }

    public count(): number {
        let count = 0;
        this.measures.forEach(meas => count += meas.length);
        return count;
    }

    public collect(measure: Measure): void {
        const items = this.measures.get(measure.type);
        if (items != null) {
            items.push(measure);
        }
        this.onCollect(measure);
    }

    public remove(measure: Measure): void {
        const items = this.measures.get(measure.type);
        if (items != null) {
            this.measures.set(measure.type, items.filter(m => m.id !== measure.id));
        }
        this.onRemove(measure);
    }

    public clear(type?: MeasureType): void {
        if (type != null) {
            this.measures.set(type, []);
        } else {
            Object.values(MeasureType).forEach(k => {
                this.measures.set(MeasureType[k] as MeasureType, []);
            });
        }
        this.onClear(type);
    }
}