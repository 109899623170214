import { Controls } from "./Controls";
import { AppEventEmitterDecorator } from "../../event/AppEventEmitterDecorator";
import { InputDelegate } from "../../event/InputDelegate";

const ControlsAppEventEmitter = AppEventEmitterDecorator(Controls);

export class EventedControls extends ControlsAppEventEmitter implements InputDelegate {
    public delegationPriority: number = 99;
    public delegationEnabled: boolean = true;

    public disable(): void {
        this.removeEventListeners();
    }
}
