import dom from "gis3d/wf/util/DomUtils";
import ui from "gis3d/wf/ui/style/UiStyle";
import on from "gis3d/wf/core/On";

import fa from "scss/font-awesome.scss";

import { BaseUiComponent } from "gis3d/wf/ui/BaseUiComponent";

export class Button extends BaseUiComponent {
    private _label!:string;
    private _icon!:string;
    private _disabled:boolean = false;
    private iconNode!:HTMLElement | null;
    private labelNode!:HTMLElement | null;
    private _active: boolean = false;
    public onClick? : Function;

    constructor(buttonType:string = ui.Button.buttonPrimary) {
        super();
        this.domElementType = "button";
        this.domElementOptions = {
            classes : [ 
                ui.Button.button, 
                buttonType
            ],
            attrs : new Map([[ "type", "button" ]])
        };
    }

    protected attachEventListeners() : void {
        on.listen(this.domNode!, "click", () => {
            if (this.onClick) {
                this.onClick();
            }
        });
    }

    public get label() : string {
        return this._label!;
    }

    public set label(label:string) {
        this._label = label;
        this.render();
    }

    public get icon() : string {
        return this._icon!;
    }

    public set icon(icon:string) {
        this._icon = icon;
        this.render();
    }

    public get disabled() : boolean {
        return this._disabled!;
    }

    public set disabled(disabled:boolean) {
        this._disabled = disabled;
        this.render();
    }

    public get active(): boolean {
        return this._active;
    }

    public set active(value: boolean) {
        this._active = value;
        this.render();
    }

    public render() : void {
        super.render();

        if (this.icon) {
            if (this.iconNode == null) {
                this.iconNode = dom.el("i", { classes : [ fa.fa, this.icon ] });
                dom.prepend(this.domNode!, this.iconNode);
            } else {
                this.iconNode.className = "";
                dom.addClasses(this.iconNode, [ fa.fa, fa.faLg, ui.Spacing.mr1, this.icon ]);
            }
        } else {
            if (this.iconNode) {
                dom.remove(this.domNode!, this.iconNode);
                this.iconNode = null;
            }
        }

        if (this.label) {
            if (this.labelNode == null) {
                this.labelNode = dom.el("span");
                dom.append(this.domNode!, this.labelNode);
            }
            dom.set(this.labelNode, this.label);
        } else {
            if (this.labelNode) {
                dom.remove(this.domNode!, this.labelNode);
                this.labelNode = null;
            }
        }

        if (this.disabled) {
            dom.setAttr(this.domNode!, "disabled");
        } else {
            dom.removeAttr(this.domNode!, "disabled");
        }

        if (this.active) {
            dom.addClass(this.domNode!, ui.active);
        } else {
            dom.removeClass(this.domNode!, ui.active);
        }
    }

}