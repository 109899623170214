export enum SignalingAction {
    REGISTER = "register",
    UNREGISTER = "unregister",
    REGISTERED = "registered",
    UNREGISTERED = "unregistered",
    OFFER = "offer",
    ANSWER = "answer",
    CANDIDATE = "candidate"
}

