import dom from "gis3d/wf/util/DomUtils";
import ui from "gis3d/wf/ui/style/UiStyle";

import { Box } from "gis3d/wf/ui/geom/Box";
import { Size } from "gis3d/wf/ui/geom/Size";
import { CanvasUiComponent } from "gis3d/wf/ui/CanvasUiComponent";

export class Compass extends CanvasUiComponent {
    private _angle: number = 0;
    protected _canvas!: HTMLCanvasElement;
    protected size: Size = new Size();

    constructor() {
        super();
        this.domElementOptions = {
            classes: [ui.p("CityvuCompass")],
        };
    }

    public build(): void {
        super.build();
    }

    public resize(box: Box | null): Size | null {
        // we do our own resizing
        return dom.marginBox(this.domNode!);
    }

    public get angle(): number {
        return this._angle;
    }

    public set angle(a: number) {
        if (a !== this.angle) {
            this._angle = (a + 360) % 360;
            if (this.isStarted()) {
                this.render();
            }
        }
    }

    public render(): void {
        super.render();
        dom.size(this.domNode!, this.size);
        this.canvas.height = this.size.h!;
        this.canvas.width = this.size.w!;

        const cx = this.size.w! / 2;
        const cy = this.size.h! / 2;
        const ctx = this.canvas.getContext("2d")!;
        const outerCircleRadius = cx - 1;
        const medianCircleRadius = outerCircleRadius - 10;
        const innerCircleRadius = 10;
        const needleWidth = 12;
        const needleHeight = medianCircleRadius;

        // circles
        ctx.clearRect(0, 0, this.size.w!, this.size.h!);
        ctx.beginPath();
        ctx.arc(cx, cy, outerCircleRadius, 0, 2 * Math.PI, true);
        ctx.arc(cx, cy, medianCircleRadius, 0, 2 * Math.PI, false);
        ctx.fillStyle = "rgba(0,0,0,0.4)";
        ctx.fill();
        ctx.beginPath();
        ctx.arc(cx, cy, medianCircleRadius, 0, 2 * Math.PI, true);
        ctx.arc(cx, cy, innerCircleRadius, 0, 2 * Math.PI, false);
        ctx.fillStyle = "rgba(0,0,0,0.2)";
        ctx.fill();
        ctx.beginPath();
        ctx.arc(cx, cy, innerCircleRadius, 0, 2 * Math.PI, true);
        ctx.fillStyle = "rgba(255,255,255,0.15)";
        ctx.fill();
        // north letter
        ctx.font = "bold 10px sans-serif";
        ctx.fillStyle = "white";
        ctx.textAlign = "center";
        ctx.fillText("N", cx, 10);
        // draw needle
        ctx.save();
        ctx.translate(cx, cy);
        ctx.rotate((this.angle / 180) * Math.PI);
        ctx.beginPath();
        ctx.moveTo(-needleWidth / 2, 0);
        ctx.lineTo(needleWidth / 2, 0);
        ctx.lineTo(0, needleHeight);
        ctx.lineTo(-needleWidth / 2, 0);
        ctx.fill();
        ctx.beginPath();
        ctx.moveTo(-needleWidth / 2, 0);
        ctx.lineTo(needleWidth / 2, 0);
        ctx.lineTo(0, -needleHeight);
        ctx.lineTo(-needleWidth / 2, 0);
        ctx.fillStyle = "red";
        ctx.fill();
        ctx.restore();
    }
}
