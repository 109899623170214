import dom from "gis3d/wf/util/DomUtils";

import { BaseUiComponent } from "gis3d/wf/ui/BaseUiComponent";

export abstract class CanvasUiComponent extends BaseUiComponent {
    protected _canvas!:HTMLCanvasElement;
    
    constructor() {
        super();
    }

    protected buildCanvas() : HTMLCanvasElement {
        return dom.el("canvas") as HTMLCanvasElement;
    }

    public build() : void {
        this._canvas = this.buildCanvas();
        super.build();
        dom.append(this.domNode!, this.canvas);
    }

    public get canvas() : HTMLCanvasElement {
        return this._canvas;
    }
}