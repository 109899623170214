import { Vector3 } from "three";
import { HasId } from "../util/HasId";

export class Tile implements HasId {
    private _level!: number;
    private _x!: number;
    private _y!: number;
    public width!: number;
    public height!: number;
    public valid!: boolean;

    private _id!: string;

    public copy(other: Tile) {
        this.level = other.level;
        this.x = other.x;
        this.y = other.y;
        this.width = other.width;
        this.height = other.height;
        this.valid = other.valid;
        this.id = other.id;
    }

    public static computeId(level: number, x: number, y: number): string {
        return level + "_" + x + "_" + y;
    }

    public updateId(): void {
        this._id = Tile.computeId(this.level, this.x, this.y);
    }

    public get id(): string {
        return this._id;
    }

    public set id(id: string) {
        this._id = id;
    }

    public get level(): number {
        return this._level;
    }

    public set level(value: number) {
        this._level = value;
    }

    public get x(): number {
        return this._x;
    }

    public set x(value: number) {
        this._x = value;
    }

    public get y(): number {
        return this._y;
    }

    public set y(value: number) {
        this._y = value;
    }
}
