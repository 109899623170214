import { SceneDefinitionGeoreference } from "../SceneDefinitionGeoreference";
import { SceneDefinitionGeoreferenceMode } from "../SceneDefinitionGeoreferenceMode";
import { SceneDefinitionGeoreferenceBounds } from "../SceneDefinitionGeoreferenceBounds";
import { GeoUtils } from "gis3d/cityvu/core/geo/GeoUtils";

export class DefaultSceneGeoreference implements SceneDefinitionGeoreference {
    public mode: SceneDefinitionGeoreferenceMode = SceneDefinitionGeoreferenceMode.LOCAL;
    public crs: string = GeoUtils.epsg(3857);
    public bounds: SceneDefinitionGeoreferenceBounds = {} as SceneDefinitionGeoreferenceBounds;
}
