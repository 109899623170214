import dom from "gis3d/wf/util/DomUtils";
import ui from "gis3d/wf/ui/style/UiStyle";
import on from "gis3d/wf/core/On";

import { TreeStore } from "gis3d/wf/data/TreeStore";
import { TreeNode } from "gis3d/wf/data/TreeNode";

import { BaseUiComponent } from "gis3d/wf/ui/BaseUiComponent";
import { fa, faMinusSquare, faPlusSquare, faFw } from "scss/font-awesome.scss";

export interface TreeNodeItem<T extends TreeNode = TreeNode> {
    node?: T;
    //
    radio: boolean;
    domNode: HTMLElement;
    itemCollapseWrapper?: HTMLElement;
    collapseNode?: HTMLElement;
    expandNode?: HTMLElement;
    labelNode?: HTMLElement;
    checkableNode?: HTMLElement;
    checkableWrapperNode?: HTMLElement;
}

export class Tree<T extends TreeNode = TreeNode> extends BaseUiComponent {
    protected cssItem: string = ui.p("TreeItem");
    protected cssItemLabel: string = ui.p("TreeItemLabel");
    protected cssItemContainer: string = ui.p("TreeItemContainer");
    protected cssSubtree: string = ui.p("TreeSubTree");
    protected cssTreeCollapsible: string = ui.p("TreeCollapsible");
    protected cssNoRoot: string = ui.p("TreeNoRoot");
    protected cssItemCollapsibleBranch: string = ui.p("TreeCollapsibleBranch");
    protected cssItemCollapseWrapper: string = ui.p("TreeItemCollapseWrapper");
    protected cssItemCollapse: string = ui.p("TreeItemCollapse");
    protected cssItemExpand: string = ui.p("TreeItemExpand");
    protected cssItemLeaf: string = ui.p("TreeItemLeaf");
    protected cssItemCheckbox: string = ui.p("TreeItemCheckbox");
    protected cssItemDisabled: string = ui.p("TreeItemDisabled");

    protected _hideRoot: boolean = false;
    protected _collapsible: boolean = true;
    protected _expandOnStartup: boolean = true;
    protected _lazyRendering: boolean = false;
    protected _checkable: boolean = false;

    protected _store?: TreeStore<T>;

    // callbacks
    public onCollapse: (node: T) => void = node => {};
    public onCheck: (node: T) => void = node => {};

    constructor() {
        super();
        this.domElementType = "ul";
        this.domElementOptions = {
            classes: [ui.p("Tree")],
        };
    }

    protected attachEventListeners(): void {}

    public render(): Promise<void> | void {
        super.render();
        return new Promise<void>((resolve: Function, reject: Function) => {
            dom.empty(this.domNode!);
            if (this.store != null) {
                this.store.root().then(r => {
                    this.renderTreeNode(r, this.domNode!, true).then(() => {
                        resolve();
                    });
                });
            } else {
                resolve();
            }
        });
    }

    protected nodeForItemContainer(node: T): HTMLElement {
        const li = dom.el("li", {
            classes: [this.cssItemContainer],
            attrs: new Map([["data-level", node.level], ["data-id", node.id]]),
        });

        return li;
    }

    protected createCheckableNodes(node: T, treeItem: TreeNodeItem<T>): void {
        let w = dom.el("div", {
            classes: [this.cssItemCheckbox, ui.Form.check, ui.Display.d(ui.Display.inlineBlock)],
        });
        treeItem.checkableWrapperNode = w;

        let c = dom.el("input", {
            classes: [ui.Form.checkInput],
            attrs: new Map([["type", treeItem.radio ? "radio" : "checkbox"]]),
        });

        if (node.checked) {
            (<HTMLInputElement>c).checked = true;
        }

        treeItem.checkableNode = c;
        dom.append(w, c);
        dom.before(treeItem.labelNode!, treeItem.checkableWrapperNode!);
    }

    protected createCollapseNodes(node: T, treeItem: TreeNodeItem<T>): void {
        let collapseNode: HTMLElement | undefined = undefined;
        let expandNode: HTMLElement | undefined = undefined;
        let itemCollapseWrapper: HTMLElement | undefined = undefined;

        itemCollapseWrapper = dom.el("span", {
            classes: [this.cssItemCollapseWrapper],
        });
        collapseNode = dom.el("span", {
            classes: [this.cssItemCollapse],
        });
        dom.append(
            collapseNode,
            dom.el("i", {
                classes: [fa, faFw, faMinusSquare],
            }),
        );
        dom.append(itemCollapseWrapper, collapseNode);

        expandNode = dom.el("span", {
            classes: [this.cssItemExpand],
        });
        dom.append(
            expandNode,
            dom.el("i", {
                classes: [fa, faFw, faPlusSquare],
            }),
        );

        if (this.expandOnStartup || node.expanded) {
            dom.addClass(expandNode, ui.Display.d(ui.Display.none));
        } else {
            dom.addClass(collapseNode, ui.Display.d(ui.Display.none));
        }
        dom.append(itemCollapseWrapper, expandNode);
        dom.prepend(treeItem.domNode, itemCollapseWrapper);

        treeItem.itemCollapseWrapper = itemCollapseWrapper;
        treeItem.collapseNode = collapseNode;
        treeItem.expandNode = expandNode;
    }

    protected nodeForItem(node: T, exclusive: boolean, exclusiveCallback: (treeItem: TreeNodeItem<T>) => void): TreeNodeItem<T> {
        const div = dom.el("div", {
            classes: [this.cssItem],
        });
        const label = dom.el("span", {
            classes: [this.cssItemLabel],
        });
        dom.set(label, node.label!);
        dom.append(div, label);

        let treeItem: TreeNodeItem<T> = <TreeNodeItem<T>>{
            node: node,
            domNode: div,
            labelNode: label,
            radio: exclusive,
        };

        if (this.collapsible) {
            if (!node.isLeaf) {
                this.createCollapseNodes(node, treeItem);
            } else {
                dom.addClass(treeItem.domNode, this.cssItemLeaf);
            }
        }

        if (this.checkable) {
            this.createCheckableNodes(node, treeItem);

            on.listen(treeItem.checkableNode!, "change", () => {
                this.onTreeItemChecked(treeItem);
                if (exclusive) {
                    exclusiveCallback(treeItem);
                }
            });
        }

        return treeItem;
    }

    protected nodeForChildren(): HTMLElement {
        return dom.el("ul", {
            classes: [this.cssSubtree],
        });
    }

    protected updateTreeEnableState(): void {
        const branches: Array<HTMLElement> = [];
        dom.query("> .wfTreeItemContainer", this.domNode!).forEach(b => {
            branches.unshift(<HTMLElement>b);
        });

        do {
            let branch = branches.pop();

            let checked: boolean = false;
            let inputList = dom.query("> .wfTreeItem > .wfTreeItemCheckbox > input", branch);
            if (inputList.length > 0) {
                checked = (<HTMLInputElement>inputList[0]).checked;
            }

            if (!checked) {
                // disable all descendant
                dom.query("> .wfTreeSubTree .wfTreeItem", branch).forEach(item => {
                    dom.addClass(item, this.cssItemDisabled);
                    dom.query("> .wfTreeItemCheckbox > input", <HTMLElement>item).forEach(input => {
                        (<HTMLInputElement>input).disabled = true;
                    });
                });
            } else {
                // enable direct descendants and progress traversing
                dom.query("> .wfTreeSubTree > .wfTreeItemContainer > .wfTreeItem", branch).forEach(item => {
                    dom.removeClass(item, this.cssItemDisabled);
                    dom.query("> .wfTreeItemCheckbox > input", <HTMLElement>item).forEach(input => {
                        (<HTMLInputElement>input).disabled = false;
                    });
                });

                let children = dom.query("> .wfTreeSubTree > .wfTreeItemContainer", branch);
                children.forEach(b => {
                    branches.push(<HTMLElement>b);
                });
            }
        } while (branches.length > 0);
    }

    protected onTreeItemChecked(itemNode: TreeNodeItem<T>) {
        if (itemNode.node) {
            itemNode.node.checked = (<HTMLInputElement>itemNode.checkableNode!).checked;
            this.store.update(itemNode.node);
            this.onCheck(itemNode.node);
            // update tree enable state
            this.updateTreeEnableState();
        }
    }

    protected onCollapseClick(itemNode: TreeNodeItem<T>, container: HTMLElement) {
        dom.toggleClass(itemNode!.expandNode!, ui.Display.d(ui.Display.none));
        dom.toggleClass(itemNode!.collapseNode!, ui.Display.d(ui.Display.none));
        dom.toggleClass(container!, ui.Display.d(ui.Display.none));
        if (itemNode.node) {
            itemNode.node.expanded = !itemNode.node.expanded;
            this.onCollapse(itemNode.node);
            this.store.update(itemNode.node);
        }
    }

    protected renderTreeNode(
        node: T,
        parentNode: HTMLElement,
        isRoot: boolean = false,
        disabled: boolean = false,
        exclusive: boolean = false,
        exclusiveCallback: (treeItem: TreeNodeItem<T>) => void = () => {},
    ): Promise<void> {
        return new Promise<void>((resolve: Function, reject: Function) => {
            let container: HTMLElement | null = null;
            let itemNode: TreeNodeItem<T> | null = null;

            if (isRoot && this.collapsible) {
                dom.addClass(this.domNode!, this.cssTreeCollapsible);
            }

            if (!isRoot || !this.hideRoot) {
                container = this.nodeForItemContainer(node);
                dom.append(parentNode, container);

                itemNode = this.nodeForItem(node, exclusive, exclusiveCallback);
                dom.append(container, itemNode.domNode);
            } else {
                container = parentNode;
                dom.addClass(container, this.cssNoRoot);
            }

            if (disabled) {
                dom.addClass(itemNode!.domNode, this.cssItemDisabled);
                if (itemNode!.checkableNode) {
                    (<HTMLInputElement>itemNode!.checkableNode).disabled = true;
                }
            }

            if (node.isLeaf) {
                resolve();
            } else {
                // node has children
                if (this.collapsible && !(isRoot && this.hideRoot)) {
                    dom.addClass(container, this.cssItemCollapsibleBranch);
                }

                if (this.lazyRendering) {
                    // lazy rendering needs collapsible = true, expandOnStartup = false
                    console.log("TODO: LAZY RENDERING NOT SUPPORTED YET");
                } else {
                    if (!(isRoot && this.hideRoot)) {
                        const newContainer = this.nodeForChildren();
                        dom.append(container!, newContainer);
                        container = newContainer;
                    }

                    if (this.expandOnStartup) {
                        node.expanded = true;
                    }

                    if (this.collapsible && !node.expanded && !(isRoot && this.hideRoot)) {
                        dom.addClass(container!, ui.Display.d(ui.Display.none));
                    }

                    if (itemNode != null && this.collapsible) {
                        on.listen(itemNode.collapseNode, "click", () => {
                            this.onCollapseClick(itemNode!, container!);
                        });
                        on.listen(itemNode.expandNode, "click", () => {
                            this.onCollapseClick(itemNode!, container!);
                        });
                    }

                    const childrenAreExclusive: boolean = node.exclusiveChildren;
                    this.store.children(node).then(cc => {
                        let exclusiveCallback = undefined;
                        if (cc.length > 0 && childrenAreExclusive) {
                            let alreadyChecked = false;
                            for (let c of cc) {
                                if (c.checked) {
                                    if (alreadyChecked) {
                                        c.checked = false;
                                        this.store.update(c);
                                    } else {
                                        alreadyChecked = true;
                                    }
                                }
                            }
                            // none is checked, preset the first one
                            if (alreadyChecked === false) {
                                cc[0].checked = true;
                                this.store.update(cc[0]);
                            }

                            exclusiveCallback = (treeItem: TreeNodeItem<T>) => {
                                // unselect items at the same level
                                let subTreeParent = treeItem.domNode.parentElement!;
                                while (!dom.match(subTreeParent, ".wfTreeSubTree")) {
                                    subTreeParent = subTreeParent.parentElement!;
                                }
                                if (subTreeParent) {
                                    dom.query(".wfTreeItem", subTreeParent).forEach(item => {
                                        if (item != treeItem.domNode) {
                                            (<HTMLInputElement>dom.query("input", <HTMLElement>item)[0]).checked = false;
                                        }
                                    });
                                }

                                for (let c of cc) {
                                    if (treeItem.node!.id != c.id) {
                                        c.checked = false;
                                        this.store.update(c);
                                    }
                                }
                                this.updateTreeEnableState();
                            };
                        }

                        const promiseList: Promise<void>[] = [];
                        for (let c of cc) {
                            promiseList.push(this.renderTreeNode(c, container!, false, disabled || (this.checkable && !node.checked), childrenAreExclusive, exclusiveCallback));
                        }
                        Promise.all(promiseList).then(() => {
                            resolve();
                        });
                    });
                    this.updateTreeEnableState();
                }
            }
        });
    }

    public get store(): TreeStore<T> {
        return this._store!;
    }

    public set store(s: TreeStore<T>) {
        this._store = s;
        if (this.started) {
            this.render();
        }
    }

    public get hideRoot(): boolean {
        return this._hideRoot;
    }

    public set hideRoot(s: boolean) {
        this._hideRoot = s;
    }

    public get expandOnStartup(): boolean {
        return this._expandOnStartup;
    }

    public set expandOnStartup(s: boolean) {
        this._expandOnStartup = s;
    }

    public get collapsible(): boolean {
        return this._collapsible;
    }

    public set collapsible(s: boolean) {
        this._collapsible = s;
    }

    public get checkable(): boolean {
        return this._checkable;
    }

    public set checkable(s: boolean) {
        this._checkable = s;
    }

    public get lazyRendering(): boolean {
        return this._lazyRendering;
    }

    public set lazyRendering(s: boolean) {
        console.log("Unimplemented");
        this._lazyRendering = s;
    }

    public visit(onVisit: (node: T) => boolean): Promise<void> {
        return new Promise<void>((resolve: Function, reject: Function) => {
            let bucket: Array<T> = [];

            const evaluateNodeInBucket = () => {
                if (bucket.length > 0) {
                    const node = bucket.pop()!;
                    if (onVisit(node)) {
                        if (node.isLeaf) {
                            evaluateNodeInBucket();
                        } else {
                            // follow children
                            this.store.children(node).then(
                                (nodes: T[]) => {
                                    // put in bucket
                                    for (let index = nodes.length - 1; index >= 0; index--) {
                                        bucket.push(nodes[index]);
                                    }
                                    evaluateNodeInBucket();
                                },
                                () => {
                                    reject();
                                },
                            );
                        }
                    } else {
                        evaluateNodeInBucket();
                    }
                } else {
                    resolve();
                }
            };

            // root node is a special case
            this.store.root().then(
                (node: T) => {
                    if (onVisit(node)) {
                        if (node.isLeaf) {
                            resolve();
                        } else {
                            // query children
                            this.store.children(node!).then(
                                (nodes: T[]) => {
                                    // put in bucket
                                    for (let index = nodes.length - 1; index >= 0; index--) {
                                        bucket.push(nodes[index]);
                                    }
                                    evaluateNodeInBucket();
                                },
                                () => {
                                    reject();
                                },
                            );
                        }
                    } else {
                        resolve();
                    }
                },
                () => {
                    reject();
                },
            );
        });
    }
}
