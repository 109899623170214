import { Size } from "./Size";

export class Box extends Size {
    public x:number | null;
    public y:number | null;

    public constructor(x:number | null  = null, y:number | null  = null, w:number | null  = null, h:number | null  = null) {
        super(w, h);
        this.x = x;
        this.y = y;
    }

    public reset() : void {
        super.reset();
        this.x = null;
        this.y = null;
    }
}