import { SceneDefinition } from "gis3d/cityvu/scene/definition/SceneDefinition";
import { SceneDefinitionGeoreferenceMode } from "gis3d/cityvu/scene/definition/SceneDefinitionGeoreferenceMode";
import { Scene } from "./Scene";
import { LocalScene } from "./LocalScene";

export class Cityvu3DSceneFactory {
    public static get(definition: SceneDefinition): Scene | null {
        let scene = null;
        if (definition.georeference.mode == SceneDefinitionGeoreferenceMode.LOCAL) {
            scene = new LocalScene(definition);
        }
        return scene;
    }
}
