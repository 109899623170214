import * as _ from "lodash";

export default class NumberUtils {
    public static readonly EPSILON1: number = 0.1;
    public static readonly EPSILON2: number = 0.01;
    public static readonly EPSILON3: number = 0.001;
    public static readonly EPSILON4: number = 0.0001;
    public static readonly EPSILON5: number = 0.00001;
    public static readonly EPSILON6: number = 0.000001;
    public static readonly EPSILON7: number = 0.0000001;
    public static readonly EPSILON8: number = 0.00000001;
    public static readonly EPSILON9: number = 0.000000001;
    public static readonly EPSILON10: number = 0.0000000001;

    public static readonly PI: number = Math.PI;
    public static readonly ONE_OVER_PI: number = 1.0 / Math.PI;
    public static readonly PI_OVER_2: number = Math.PI / 2.0;
    public static readonly PI_OVER_3: number = Math.PI / 3.0;
    public static readonly PI_OVER_4: number = Math.PI / 4.0;
    public static readonly PI_OVER_6: number = Math.PI / 6.0;
    public static readonly TWO_PI: number = 2.0 * Math.PI;
    public static readonly ONE_OVER_TWO_PI: number = 1.0 / (2.0 * Math.PI);
    public static readonly DEG_2_RAD: number = Math.PI / 180.0;
    public static readonly RAD_2_DEG: number = 180.0 / Math.PI;
    public static readonly RAD_IN_ASEC: number = NumberUtils.DEG_2_RAD / 3600.0;

    public static readonly toNumber = (input: any) => parseFloat(input) || 0;
    public static readonly toInt = (input: any) => parseInt(input) || 0;
    public static readonly toRad = (input: number) => input * NumberUtils.DEG_2_RAD;
    public static readonly toDeg = (input: number) => input * NumberUtils.RAD_2_DEG;

    public static readonly createUuid = (): string => {
        const s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        };

        return [s4(), s4(), s4(), s4(), s4(), s4(), s4(), s4()].join("-");
    }

}