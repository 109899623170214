import { BasicScene } from "gis3d/cityvu/core/three/scene/BasicScene";
import { Vector3 } from "three";
import { Epsg6708 } from "../core/geo/crs/Epsg6708";
import { Scene } from "../core/three/scene/Scene";
import { PointCloudLoader } from "../io/cloud/PointCloudLoader";
import { PointCloudLayer3d } from "../scene/layer3d/pointcloud/PointCloudLayer3d";
import { Experiment } from "./Experiment";

class ExperimentScene extends BasicScene {
    constructor() {
        super();
        this.crs = new Epsg6708();
    }

    public init(): void {
        super.init();
        this.crs.coordsToPoint(new Vector3(321717.894234, 5087199.271658, 61.015999), this.pose.position);
    }

    public onEngineAssigment(): void {
        super.onEngineAssigment();
        // TODO port to new cloud layer3d
        /*
        const cloudEngine = this.engine!.cloudEngine;
        const cloudLoader = new PointCloudLoader(cloudEngine);
        cloudLoader
            .load({
                filename: "cloud.js",
                baseUrl: "assets/data/lidar/12_960_1339/",
            })
            .then(pc => {
                this.crs.coordsToPoint(pc.position, pc.position);

                const layer = new PointCloudLayer3d();
                layer.name = "Cube";
                layer.add(pc);

                cloudEngine.add(pc);

                this.add(layer);
            });
            */
    }

    public updateScene(delta: number): void {
        super.updateScene(delta);
    }
}

export default class CloudEngineExperiment implements Experiment {
    public scene: Scene = new ExperimentScene();
}
