// styles
import defaultMessages from "assets/lang/default.json";
// components
import "bootstrap";
//
import { Cityvu } from "gis3d/cityvu/Cityvu";
import { CityvuOptions } from "gis3d/cityvu/CityvuOptions";
import { UrlManager } from "gis3d/cityvu/core/net/UrlManager";
import { UrlData, UrlDataParser } from "gis3d/cityvu/core/util/UrlDataParser";
import On from "gis3d/wf/core/On";
import i18n from "gis3d/wf/i18n/I18N";
import { Xhr } from "gis3d/wf/net/Xhr";
import dom from "gis3d/wf/util/DomUtils";
import "scss/app.scss";
import "scss/font-awesome.scss";
import "webrtc-adapter";
import TokenManager from "gis3d/cityvu/core/util/TokenManager";

// add global variable to Window
declare global {
    interface Window {
        API_BASE_URL: any;
        Cityvu: any;
    }
}
// preinit
UrlManager.BaseUrl = window.API_BASE_URL;
i18n.init([defaultMessages]);

// global error handler
const GlobalErrorHandler: (err: any) => void = err => {
    dom.removeLoader();
    const reason = (err as Error).message;
    dom.bsod(i18n.i("cityvu.error." + (reason ? reason : "unexpected")));
    // send error message to server if possible
    console.error(err);
    Xhr.request({
        url: UrlManager.API.ERROR(),
        method: "POST",
        body: JSON.stringify({
            userAgent: navigator.userAgent,
            stack: (err as Error).stack,
        }),
    });
};

window.onerror = (message, source, lineno, colno, error) => {
    GlobalErrorHandler(error);
};


let cityvuOptions: CityvuOptions | null = null;
// fetch default configuration from the server
const configurationPromise = new Promise<any>((resolve, reject) => {
    // token from url try
    Xhr.request({
        url: UrlManager.API.CONFIG() + (TokenManager.urlToken ? ("?token=" + TokenManager.urlToken) : "")
    }).then(
        (d1: any) => resolve(d1),
        () => {
            // stored token try            
            TokenManager.clearUrlToken();
            if (TokenManager.storedToken != null) {
                Xhr.request({
                    url: UrlManager.API.CONFIG() + "?token=" + TokenManager.storedToken,
                }).then(
                    (d2: any) => resolve(d2),
                    () => {
                        TokenManager.storedToken = null;
                        reject();
                    }
                );
            } else {
                reject();
            }
        }
    );
});
configurationPromise.then(
        (payload: any) => {
            cityvuOptions = CityvuOptions.parse(payload);

            if (cityvuOptions.language != null) {
                try {
                    i18n.init([defaultMessages, require("assets/lang/" + cityvuOptions.language + ".json")]);
                } catch (e) {
                    console.warn("Unable to load language file for", cityvuOptions.language);
                }
            }

            TokenManager.storedToken = cityvuOptions.token;
            TokenManager.clearUrlToken();
        },
        () => {
            console.error("Error fetching default configuration");
            throw Error("initialization");
        },
    )
    .then(() => {
        // initialize cityvu
        let cityvu = new Cityvu(cityvuOptions!);
        // expose to window global
        if (window) {
            window.Cityvu = cityvu;
        }

        cityvu.init().then(() => {
            try {
                dom.removeLoader();
                cityvu.reset(true);
                cityvu.start();

                // url hash management
                const processHash = () => {
                    const urlParams: UrlData | null = new UrlDataParser().parse();
                    if (urlParams) {
                        try {
                            if (urlParams.experiment != null) {
                                loadExperiment(cityvu, urlParams.experiment);
                            } else if (urlParams.sceneUrl != null && urlParams.sceneUrl.length > 0) {
                                cityvu.loadFromUrl(urlParams.sceneUrl);
                            }
                        } catch {
                            console.error("Invalid scene url: ", urlParams.sceneUrl);
                        }
                    }
                };
                processHash();
                On.listen(window, "hashchange", () => processHash());
                On.listen(window, "beforeunload", () => {
                    if (cityvu.dataChannel != null) {
                        cityvu.dataChannel.close(true);
                    }
                });
            } catch (e) {
                GlobalErrorHandler(e);
            }
        }, GlobalErrorHandler);
    }, GlobalErrorHandler)
    .then(null, GlobalErrorHandler);

const loadExperiment = (cityvu: Cityvu, experimentName: string) => {
    if (experimentName.length > 0) {
        cityvu.reset(false);
        import("gis3d/cityvu/experiment/" + experimentName + "Experiment").then(mod => {
            const experiment = new mod.default();
            cityvu.scene = experiment.scene;
        });
    }
};
