export class DataChannelTextMessage<T = any> {
    public action? : string;
    public payload? : T;

    public constructor(action?:string, payload?:T) {
        this.action = action;
        this.payload = payload;
    }

    protected prepareJsonObject() : any {
        return {
            action : this.action,
            payload : this.payload
        };
    }

    public serialize() : string {
        return JSON.stringify(this.prepareJsonObject());
    }

    public mapDataToObject(data:any, payloadDeserializer? : (data:any) => T) {
        this.action = data.action;
        this.payload = payloadDeserializer ? payloadDeserializer(data.payload) : <T>data.payload;
    }

    public static deserialize<X>(jsonString:string, payloadDeserializer? : (data:any) => X) : DataChannelTextMessage<X> {
        const data = JSON.parse(jsonString);
        const m = new DataChannelTextMessage<X>();
        m.mapDataToObject(data, payloadDeserializer);
        return m;
    }
}