import { Controls } from "gis3d/cityvu/core/three/controls/Controls";
import { Scene } from "gis3d/cityvu/core/three/scene/Scene";
import { InputManager } from "../../event/InputManager";
import { EventedControls } from "./EventedControls";

export class ControlsManager {
    public onSelect = (newControls: Controls, oldControls: Controls): void => {};
    private controlsSet: { [key: string]: Controls };
    private _selected!: Controls;
    protected _enabled: boolean = true;
    protected _inputManager!: InputManager;

    constructor() {
        this.controlsSet = {};
    }

    public add(key: string, controls: Controls): void {
        this.controlsSet[key] = controls;
    }

    public get(key: string): Controls {
        return this.controlsSet[key];
    }

    public remove(key: string): void {
        delete this.controlsSet[key];
    }

    public select(key: string): void {
        const oldControls = this.selected;
        const newControls = this.get(key);

        if (newControls == null || oldControls === newControls) {
            return;
        }

        if (oldControls != null) {
            this.disableWithDelegation(oldControls);
        }

        if (newControls != null) {
            this._selected = newControls;
            if (this.enabled) {
                this.enableWithDelegation(newControls);
            }
            this.onSelect(this.selected, oldControls);
        } else {
            console.error("Cannot find controls:", key);
        }
    }

    protected enableWithDelegation(c: Controls): void {
        c.enable();
        if (c instanceof EventedControls) {
            this.inputManager.addDelegate(c as EventedControls);
        }
    }

    protected disableWithDelegation(c: Controls): void {
        c.disable();
        if (c instanceof EventedControls) {
            this.inputManager.removeDelegate(c as EventedControls);
        }
    }

    public get selected(): Controls {
        return this._selected;
    }

    public get enabled(): boolean {
        return this._enabled;
    }

    public set enabled(en: boolean) {
        this._enabled = en;
        if (this.selected != null) {
            if (en) {
                this.enableWithDelegation(this.selected);
            } else {
                this.disableWithDelegation(this.selected);
            }
        }
    }

    public update(delta: number): void {
        if (this.selected) {
            this.selected.update(delta);
        }
    }

    public onSceneChange(scene: Scene): void {
        this.enabled = scene.enableControls;
        for (const key in this.controlsSet) {
            const controls = this.controlsSet[key];
            controls.scene = scene;
        }
    }

    public get inputManager(): InputManager {
        return this._inputManager;
    }

    public set inputManager(im: InputManager) {
        this._inputManager = im;
    }
}
