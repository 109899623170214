import { TreeNode } from "gis3d/wf/data/TreeNode";
import { TreeStore } from "gis3d/wf/data/TreeStore";

import run from "gis3d/wf/util/RuntimeUtils";

interface LocalTreeDataResult {
    data: LocalTreeData;
    level: number;
}

export interface LocalTreeData<T extends TreeNode = TreeNode> {
    node: T;
    children?: Array<LocalTreeData>;
}

export class LocalTreeStore<T extends TreeNode = TreeNode> extends TreeStore {

    constructor(readonly treeData:LocalTreeData) {
        super();
    }

    protected findTreeDataForNode(node:T) : LocalTreeDataResult | null {
        const toVisit = [ [0, this.treeData ] ];
        do {
            let v = toVisit.pop()!;
            const level : number = <number>v[0];
            const data : LocalTreeData = <LocalTreeData>v[1];
            if (data.node.id == node.id) {
                return <LocalTreeDataResult>{
                    data: data,
                    level: level
                };
            }
            if (data.children) {
                for (let c of data.children) {
                    toVisit.unshift([level + 1, c]);
                }
            }
        } while (toVisit.length > 0);
        
        return null;
    }

    public root(): Promise<T> {
        return new Promise<T>((resolve:Function, reject:Function) => {
            if (this.treeData != null) {
                const node:TreeNode = <TreeNode>run.extend({}, this.treeData.node);
                node.level = 0;
                node.isLeaf = this.treeData.children ? this.treeData.children.length == 0 : true;
                resolve(node);
            } else {
                reject(null);
            }
        });
    }    
    
    public children(node: T): Promise<T[]> {
        return new Promise<T[]>((resolve:Function, reject:Function) => {
            let result : LocalTreeDataResult | null = this.findTreeDataForNode(node);
            if (result != null) {
                const nodes : T[] = [];
                const data = result.data;
                if (data.children != null) {
                    const children = data.children!;
                    for (let c of children) {
                        const node : TreeNode = <TreeNode>run.extend({}, c.node);
                        node.level = result.level + 1;
                        node.isLeaf = c.children ? c.children.length == 0 : true;        
                        nodes.push(<T>node);
                    }    
                }
                resolve(<T[]>nodes);
            } else {
                reject(null);
            }
        });
    }

    public refresh(): void {
    }

    public update(node:T) : Promise<void> {
        return new Promise<void>((resolve:Function, reject:Function) => {
            let result : LocalTreeDataResult | null = this.findTreeDataForNode(node);
            if (result != null) {
                const treeNode = result.data.node;
                treeNode.checked = node.checked;
                treeNode.expanded = node.expanded;
                resolve();
            } else {
                reject();
            }
        });
    }


}
