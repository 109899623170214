import dom from "gis3d/wf/util/DomUtils";
import run from "gis3d/wf/util/RuntimeUtils";

import { Box } from "gis3d/wf/ui/geom/Box";
import { Evented } from "gis3d/wf/core/Evented";

export class Viewport extends Evented {
    public static readonly Event = class {
        public static readonly Resize: string = "WindowResize";
    };
    private static instance: Viewport;
    private running: boolean = false;
    private win: Window = dom.win();
    private box: Box | null = null;

    private constructor() {
        super();
        dom.on(this.win, "resize", run.bind(this, this.onWindowResize));
    }

    private onWindowResize(ev: Event): void {
        if (!this.running) {
            this.running = true;
            let newBox: Box = this.computeBox();
            if (this.box == null || this.box.w !== newBox.w || this.box.h !== newBox.h) {
                this.box = newBox;
                this.win.requestAnimationFrame(run.bind(this, this.emitResize));
            } else {
                this.running = false;
            }
        }
    }

    private emitResize(): void {
        this.emit(Viewport.Event.Resize);
        this.running = false;
    }

    public getBox(): Box {
        if (this.box == null) {
            this.box = this.computeBox();
        }
        return this.box;
    }

    public computeBox(): Box {
        let b = new Box();
        b.x = this.win.pageXOffset;
        b.y = this.win.pageYOffset;
        b.w = dom.body().clientWidth;
        b.h = dom.body().clientHeight;
        return b;
    }

    public static get(): Viewport {
        if (!Viewport.instance) {
            Viewport.instance = new Viewport();
        }
        return Viewport.instance;
    }
}
