import { GuiOptions } from "./GuiOptions";
import RuntimeUtils from "gis3d/wf/util/RuntimeUtils";

export class GuiOptionsUtil {

    public static readonly sceneControlToGuiControl = (sc?: string): string | undefined => {
        let gc = undefined;
        if (sc != null) {
            gc = sc.toLowerCase();
        }
        return gc;
    }

    /** merge on base as constrainted container */
    public static merge(src: GuiOptions, base: GuiOptions): GuiOptions {
        const target = GuiOptionsUtil.clone(base);
        if (target.controls) {
            target.controls = src.controls;
        }
        target.selectedControls = GuiOptionsUtil.sceneControlToGuiControl(src.selectedControls);
        if (target.enabledControls && src.enabledControls) {
            if (target.enabledControls.constrained) {
                target.enabledControls.constrained = src.enabledControls.constrained;
            }
            if (target.enabledControls.fps) {
                target.enabledControls.fps = src.enabledControls.fps;
            }
            if (target.enabledControls.earth) {
                target.enabledControls.earth = src.enabledControls.earth;
            }
            if (target.enabledControls.orbit) {
                target.enabledControls.orbit = src.enabledControls.orbit;
            }
        }

        if (target.measurements) {
            target.measurements = src.measurements;
        }

        if (target.enabledMeasurements && src.enabledMeasurements) {
            if (target.enabledMeasurements.angle) {
                target.enabledMeasurements.angle = src.enabledMeasurements.angle;
            }
            if (target.enabledMeasurements.area) {
                target.enabledMeasurements.area = src.enabledMeasurements.area;
            }
            if (target.enabledMeasurements.info) {
                target.enabledMeasurements.info = src.enabledMeasurements.info;
            }
            if (target.enabledMeasurements.line) {
                target.enabledMeasurements.line = src.enabledMeasurements.line;
            }
        }

        if (target.tools && src.tools) {
            if (target.tools.compass) {
                target.tools.compass = src.tools.compass;
            }
            if (target.tools.layerSelector) {
                target.tools.layerSelector = src.tools.layerSelector;
            }
            if (target.tools.navigator) {
                target.tools.navigator = src.tools.navigator;
            }
            if (target.tools.info) {
                target.tools.info = src.tools.info;
            }
        }

        return target;
    }

    public static clone(src: GuiOptions): GuiOptions {
        return RuntimeUtils.cloneDeep(src) as GuiOptions;
    }
}
