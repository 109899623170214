import { LocalTreeStore, LocalTreeData } from "gis3d/wf/data/LocalTreeStore";
import { TreeNode } from "gis3d/wf/data/TreeNode";
import { Scene } from "./Scene";
import { Cityvu3DScene } from "./Cityvu3DScene";
export interface LayerTreeNode extends TreeNode {
    layerId?: string;
    layerSourceId?: string;
    layer3dId?: string;
}
export class LayerTreeStore extends LocalTreeStore {
    public static create(scene: Scene): LayerTreeStore {
        const treeData = LayerTreeStore.getTreeDataFromScene(scene);
        return new LayerTreeStore(treeData);
    }

    public static getTreeDataFromScene(scene: Scene): LocalTreeData {
        const treeData = {
            node: {
                id: 0,
                label: "Root",
                expanded: true,
                checked: true,
            } as LayerTreeNode,
            children: [],
        } as LocalTreeData;

        let nodeCounter = 1;
        if (scene instanceof Cityvu3DScene) {
            // sceneDefinitionLayer based tree
            if (scene.definition != null) {
                const d = scene.definition;
                if (d.layers != null) {
                    for (const layDef of d.layers) {
                        if (layDef.enabled) {
                            let children = undefined;
                            const multipleSources = layDef.sources.length > 1;

                            const node = {
                                id: nodeCounter++,
                                label: layDef.label,
                                layerId: layDef.id,
                                expanded: true,
                                checked: layDef.visible,
                                exclusiveChildren: multipleSources,
                            } as LayerTreeNode;

                            if (multipleSources) {
                                children = [];
                                for (const source of layDef.sources) {
                                    children.push({
                                        node: {
                                            id: nodeCounter++,
                                            label: source.label,
                                            layerId: layDef.id,
                                            layerSourceId: source.id,
                                            expanded: true,
                                            checked: layDef.selectedSource === source.code,
                                        } as LayerTreeNode,
                                    } as LocalTreeData);
                                }
                            }

                            treeData.children!.push({
                                node: node,
                                children: children,
                            } as LocalTreeData);
                        }
                    }
                }
            }
        } else {
            // layer3d based tree
            if (scene.layers != null) {
                scene.layers.forEach(layer => {
                    treeData.children!.push({
                        node: {
                            id: nodeCounter++,
                            label: layer.label,
                            layer3dId: layer.id,
                            expanded: true,
                            checked: layer.visible,
                        } as LayerTreeNode,
                    } as LocalTreeData);
                });
            }
        }

        return treeData;
    }
}
