import { Vector3 } from "three";
import { AffineTransformation } from "../../geometry/AffineTransformation";
import { Bounds } from "../../geometry/Bounds";
import { GeoUtils } from "../GeoUtils";
import { Proj4Projection } from "../projection/Proj4Projection";
import { BaseCrs } from "./BaseCrs";

export class Epsg6708 extends BaseCrs {
    constructor() {
        super();
        this.code = GeoUtils.epsg(6708);
        GeoUtils.proj4defs();
        this.projection = new Proj4Projection(this.code);

        this.projectedBounds = new Bounds(new Vector3(-331278.39182019, 3884128.41246344, -Number.MAX_VALUE), new Vector3(802757.85485572, 5224005.23112021, Number.MAX_VALUE));

        this.transformation = new AffineTransformation();
    }
}
