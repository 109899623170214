import DomUtils from "gis3d/wf/util/DomUtils";
import { BaseUiComponent } from "../../BaseUiComponent";
import UiStyle from "../../style/UiStyle";
import { UiComponent } from "../../UiComponent";
import { InputUiComponent } from "../InputUiComponent";
import { Label } from "../Label";
import { FormEntry } from "./FormEntry";

export class LabeledInput<T> extends BaseUiComponent implements FormEntry<T> {
    private _label!: string;
    private _help!: string | null;
    private _name!: string;
    private _inputComponent!: InputUiComponent<T>;

    protected labelItem! : Label;
    protected helpNode! : HTMLElement;

    public constructor() {
        super();
        this.domElementOptions.classes = [ UiStyle.Form.group ];
    }

    public build() : void {
        super.build();
        this.labelItem = new Label();
        this.labelItem.init();
        this.labelItem.text = this.label;
        DomUtils.append(this.domNode!, this.labelItem.domNode!);
        
        DomUtils.append(this.domNode!, this.inputComponent.domNode!);

        if (this.help != null) {
            this.helpNode = DomUtils.el("small", {
                classes: [ UiStyle.Form.text  ]
            });
            DomUtils.set(this.helpNode, this.help);
            DomUtils.append(this.domNode!, this.helpNode);
        }
    }

    public get label(): string {
        return this._label;
    }

    public set label(value: string) {
        this._label = value;
    }

    public get help(): string | null {
        return this._help;
    }

    public set help(value: string | null) {
        this._help = value;
    }

    public get name(): string {
        return this._name;
    }

    public set name(value: string) {
        this._name = value;
    }

    public get inputComponent(): InputUiComponent<T> {
        return this._inputComponent;
    }

    public set inputComponent(value: InputUiComponent<T>) {
        this._inputComponent = value;
    }

    public get value(): T {
        return this.inputComponent.value!;
    }

    public set value(T) {
        this.inputComponent.value = T;
    }
    
    public reset(): void {
        this.inputComponent.reset();
    }

    public get uiComponent(): UiComponent {
        return this;
    }
}
