import { SceneDefinitionSettings } from "../SceneDefinitionSettings";
import { SceneDefinitionSettingsControl } from "../SceneDefinitionSettingsControl";
import { SceneDefinitionSettingsMode } from "../SceneDefinitionSettingsMode";

export class DefaultSceneSettings implements SceneDefinitionSettings {
    public location: number[] = [0, 0, 0];
    public lookAt: number[] = [0, 0, 1];
    public control: SceneDefinitionSettingsControl = SceneDefinitionSettingsControl.EARTH;
    public mode: SceneDefinitionSettingsMode = SceneDefinitionSettingsMode.FREE;
}
