import { Vector3 } from "three";
import { Pool } from "../util/Pool";
import { Bounds } from "./Bounds";
import { Tile } from "./Tile";
import { HashSet } from "../util/HashSet";

export class FixedGrid {
    protected tilesAtLevel: number;
    protected ySize: number;
    protected xSize: number;

    private buffer = new Vector3();
    private minTile = new Tile();
    private maxTile = new Tile();

    public constructor(readonly bounds: Bounds, readonly level: number) {
        this.tilesAtLevel = Math.pow(2, level);
        this.ySize = bounds.ySize / this.tilesAtLevel;
        this.xSize = bounds.xSize / this.tilesAtLevel;
    }

    public tileAtPoint(point: Vector3, target: Tile): Tile {
        target.level = this.level;
        target.width = this.xSize;
        target.height = this.ySize;
        target.valid = this.bounds.containsPoint(point);
        if (target.valid) {
            target.x = Math.floor((point.x - this.bounds.min.x) / this.xSize);
            target.y = Math.floor((point.y - this.bounds.min.y) / this.ySize);
        }
        target.updateId();

        return target;
    }

    public tilesInBounds(bounds: Bounds, pool: Pool<Tile>, target: HashSet<Tile>): HashSet<Tile> {
        this.tileAtPoint(bounds.min, this.minTile);
        this.tileAtPoint(bounds.max, this.maxTile);

        for (let x = this.minTile.x; x <= this.maxTile.x; x++) {
            for (let y = this.minTile.y; y <= this.maxTile.y; y++) {
                const tile = pool.fetch()!;
                tile.copy(this.minTile);
                tile.x = x;
                tile.y = y;
                tile.updateId();
                target.add(tile);
            }
        }
        return target;
    }

    public updateTilesInBounds(bounds: Bounds, pool: Pool<Tile>, activeSet: HashSet<Tile>, removedSet: HashSet<Tile>): boolean {
        this.tileAtPoint(bounds.min, this.minTile);
        this.tileAtPoint(bounds.max, this.maxTile);
        let tileChanged: boolean = false;

        for (const t of activeSet.items()) {
            if (t.x < this.minTile.x || t.x > this.maxTile.x) {
                removedSet.add(t);
                activeSet.delete(t);
                tileChanged = true;
            } else if (t.y < this.minTile.y || t.y > this.maxTile.y) {
                removedSet.add(t);
                activeSet.delete(t);
                tileChanged = true;
            }
        }

        for (let x = this.minTile.x; x <= this.maxTile.x; x++) {
            for (let y = this.minTile.y; y <= this.maxTile.y; y++) {
                const id = Tile.computeId(this.minTile.level, x, y);
                if (!activeSet.hasHash(id)) {
                    const tile = pool.fetch()!;
                    tile.copy(this.minTile);
                    tile.x = x;
                    tile.y = y;
                    tile.id = id;
                    activeSet.add(tile);
                    tileChanged = true;
                }
            }
        }

        return tileChanged;
    }

    public isTileInBounds(tile: Tile, bounds: Bounds): boolean {
        // NE
        if (bounds.containsPoint(this.getTilePoint(tile, 1, 1, this.buffer))) {
            return true;
        }
        // SE
        if (bounds.containsPoint(this.getTilePoint(tile, 1, 0, this.buffer))) {
            return true;
        }
        // NW
        if (bounds.containsPoint(this.getTilePoint(tile, 0, 1, this.buffer))) {
            return true;
        }
        // SW
        if (bounds.containsPoint(this.getTilePoint(tile, 0, 0, this.buffer))) {
            return true;
        }
        return false;
    }

    public getTilePoint(tile: Tile, xTileOffset: number, yTileOffset: number, target: Vector3): Vector3 {
        target.x = (tile.x + xTileOffset) * tile.width + this.bounds.min.x;
        target.y = (tile.y + yTileOffset) * tile.height + this.bounds.min.y;
        target.z = 0;
        return target;
    }

    public getTileCenter(tile: Tile, target: Vector3): Vector3 {
        return this.getTilePoint(tile, 0.5, 0.5, target);
    }
}
