import { EngineOptions } from "gis3d/cityvu/core/three/EngineOptions";
import { Scene } from "gis3d/cityvu/core/three/scene/Scene";
import { GuiOptions } from "gis3d/cityvu/gui/GuiOptions";
import { Size } from "gis3d/wf/ui/geom/Size";
import { Camera, DirectionalLight, HemisphereLight, PerspectiveCamera, Renderer, Scene as TScene } from "three";
import { Crs } from "../../geo/crs/Crs";
import { Engine } from "../Engine";
import { Frustum } from "../Frustum";
import { Layer3d } from "./Layer3d";
import { Pose } from "./Pose";
import { Layer3dState } from "./Layer3dState";
import NumberUtils from "gis3d/wf/util/NumberUtils";
import ThreeUtils from "../ThreeUtils";
import { Tween } from "@tweenjs/tween.js";

export class BasicScene implements Scene {
    protected _showGui: boolean = true;
    protected _enableControls: boolean = true;
    protected _enableMeasurementTools: boolean = true;
    protected _camera!: Camera;
    protected _scene3d!: TScene;
    protected _options!: EngineOptions;
    protected _initialized: boolean = false;
    protected _pose!: Pose;
    protected _guiOptions: GuiOptions | null = null;
    protected _size: Size = new Size(0, 0);
    protected _layers: Array<Layer3d>;
    protected _crs!: Crs;
    protected _frustum!: Frustum;
    protected _cameraTween: Tween | null = null;
    private _engine: Engine | null = null;

    public onLayersChange: () => void = () => {};
    public onUpdateCallback = (delta: number): void => {};

    public constructor() {
        this.scene3d = new TScene();
        this.pose = new Pose();
        this.frustum = new Frustum();
        this._layers = [];
    }

    protected setupLighting(): void {
        const hemiLight = new HemisphereLight(0xffffff, 0xffffff, 0.05);
        hemiLight.color.setHSL(0.6, 1, 0.6);
        hemiLight.groundColor.setHSL(0.095, 1, 0.75);
        hemiLight.position.set(0, 500, 100);
        this.scene3d.add(hemiLight);

        const d = 30;
        const directionalLight = new DirectionalLight(0xffffff, 0.7);
        directionalLight.position.set(1, 2, 1);
        directionalLight.target.position.set(0, 0, 0);
        directionalLight.castShadow = true;
        directionalLight.shadow.mapSize.width = 2048;
        directionalLight.shadow.mapSize.height = 2048;
        directionalLight.shadow.camera.left = -d;
        directionalLight.shadow.camera.right = d;
        directionalLight.shadow.camera.top = d;
        directionalLight.shadow.camera.bottom = -d;
        directionalLight.shadow.camera.far = 3500;
        directionalLight.shadow.bias = -0.000001;
        this.scene3d.add(directionalLight);

        const directionalLight2 = new DirectionalLight(0xffffff, 0.8);
        directionalLight2.position.set(-1, -2, -1);
        directionalLight2.target.position.set(0, 0, 0);
        directionalLight2.castShadow = true;
        directionalLight2.shadow.mapSize.width = 2048;
        directionalLight2.shadow.mapSize.height = 2048;
        directionalLight2.shadow.camera.left = -d;
        directionalLight2.shadow.camera.right = d;
        directionalLight2.shadow.camera.top = d;
        directionalLight2.shadow.camera.bottom = -d;
        directionalLight2.shadow.camera.far = 3500;
        directionalLight2.shadow.bias = -0.000001;
        this.scene3d.add(directionalLight2);
    }

    public init(): void {
        if (!this.initialized) {
            this.setupLighting();
            this.initialized = true;
        }
    }

    public onEngineAssigment(): void {
        this.layers.forEach(l3d => (l3d.engine = this.engine!));
    }

    public onBeforeRender(renderer: Renderer): void {}

    public setInitialCameraPosition(): void {
        this.camera.position.copy(this.pose.position);
        this.updateCameraRotationFromPose();
    }

    protected updateCameraRotationFromPose() : void {
        this.camera.rotation.set(this.pose.pitch + NumberUtils.PI_OVER_2, this.pose.roll, this.pose.yaw, "ZXY");
    }

    public updateScene(delta: number): void {
        if (this.pose.animate) {
            if (this.pose.updateAnimation) {
                const speed = 300;
                const cPos = this.pose.position;
                if (cPos.distanceToSquared(this.camera.position) > NumberUtils.EPSILON3) {
                    if (this.cameraTween != null) {
                        this.cameraTween.stop();
                    }

                    this._cameraTween = ThreeUtils.tweenPosition(this.camera.position, cPos, speed);
                    this._cameraTween
                        .onComplete(tw => {
                            this._cameraTween = null;
                        })
                        .start();
                }

                if (this.pose.zoomRequested) {
                    const or = this.pose.orientationToPoint(cPos);
                    const sP = this.pose.pitch;
                    const sY = this.pose.yaw;
                    ThreeUtils.tween({ pitch: sP, yaw: sY }, { pitch: or.x, yaw: or.y }, speed)
                        .onUpdate(t => {
                            this.pose.pitch = t.pitch;
                            this.pose.yaw = t.yaw;
                        })
                        .start();
                    this.pose.zoomRequested = false;
                }
                this.pose.updateAnimation = false;
            }
        } else {
            this.camera.position.copy(this.pose.position);
        }
        this.updateCameraRotationFromPose();
        this.frustum.update(this.camera);
        this.onUpdateCallback(delta);
        this.layers.forEach(l3d => {
            if (l3d.state == Layer3dState.READY && l3d.visible) {
                l3d.update(delta);
            }
        });
    }

    public updateCamera(aspect: number, vFov: number, near: number, far: number): void {
        if (this.camera == null) {
            this.camera = new PerspectiveCamera();
        }

        const pc = this.camera as PerspectiveCamera;
        pc.fov = vFov;
        pc.near = near;
        pc.far = far;
        pc.aspect = aspect;
        pc.up.set(0, 0, 1);
        pc.updateProjectionMatrix();
        pc.updateMatrix();
        pc.updateMatrixWorld(true);
        pc.matrixWorldInverse.getInverse(pc.matrixWorld);
    }

    public add(layer: Layer3d): void {
        this.layers.push(layer);
        this.scene3d.add(layer.object);
        this.onLayersChange();
    }

    public remove(layer: Layer3d): void {
        this.scene3d.remove(layer.object);
        this.layers = this.layers.filter(l => layer !== l);
        this.onLayersChange();
    }

    public findLayer(id: string): Layer3d | undefined {
        return this.layers.find(layer => layer.id === id);
    }

    public get scene3d(): TScene {
        return this._scene3d;
    }

    public set scene3d(s: TScene) {
        this._scene3d = s;
    }

    public get camera(): Camera {
        return this._camera;
    }

    public set camera(c: Camera) {
        this._camera = c;
    }

    public get frustum(): Frustum {
        return this._frustum;
    }

    public set frustum(value: Frustum) {
        this._frustum = value;
    }

    public get crs(): Crs {
        if (this._crs == null) {
            throw Error("undefinedCrs");
        }
        return this._crs;
    }

    public set crs(value: Crs) {
        this._crs = value;
    }

    public get initialized(): boolean {
        return this._initialized;
    }

    public set initialized(init: boolean) {
        if (init == false && this.initialized == true) {
            console.error("Cannot unset initialization");
        }
        this._initialized = init;
    }

    public get showGui(): boolean {
        return this._showGui;
    }

    public set showGui(b: boolean) {
        this._showGui = b;
    }

    public get enableControls(): boolean {
        return this._enableControls;
    }

    public set enableControls(b: boolean) {
        this._enableControls = b;
    }

    public get pose(): Pose {
        return this._pose;
    }

    public set pose(value: Pose) {
        this._pose = value;
    }

    public get size(): Size {
        return this._size;
    }

    public set size(value: Size) {
        this._size.copy(value);
    }

    public get guiOptions(): GuiOptions | null {
        return this._guiOptions;
    }

    public get layers(): Array<Layer3d> {
        return this._layers;
    }

    public set layers(value: Array<Layer3d>) {
        console.error("Cannot set layers using assigment");
    }

    public get enableMeasurementTools(): boolean {
        return this._enableMeasurementTools;
    }

    public set enableMeasurementTools(value: boolean) {
        this._enableMeasurementTools = value;
    }

    public get engine(): Engine | null {
        return this._engine;
    }

    public set engine(value: Engine | null) {
        this._engine = value;
        this.onEngineAssigment();
    }

    public get cameraTween(): Tween | null {
        return this._cameraTween;
    }
}
