import * as _ from "lodash";

export default class UiStyle {
    public static readonly gisPrefix: string = "wf";
    public static readonly c = (prefix: string, suffix: string) => {
        let c: string = prefix + suffix.charAt(0).toUpperCase() + suffix.slice(1);
        return c;
    };
    public static readonly p = (suffix: string) => UiStyle.c(UiStyle.gisPrefix, suffix);

    public static readonly active: string = "active";
    public static readonly clearfix: string = "clearfix";
    public static readonly collapse: string = "collapse";
    public static readonly draggable: string = "draggable";
    public static readonly fade: string = "fade";
    public static readonly disableMouse: string = "disableMouse";
    public static readonly disabled: string = "disabled";
    public static readonly visible: string = "visible";
    public static readonly invisible: string = "invisible";
    public static readonly listUnstyled: string = "list-unstyled";
    public static readonly listInline: string = "list-inline";
    public static readonly listInlineItem: string = "list-inline-item";
    public static readonly floatRight: string = "float-right";
    public static readonly close: string = "close";
    public static readonly closed: string = "closed";

    public static readonly systemFontFamily: string = "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue',sans-serif";

    public static readonly Border = class {
        public static readonly on: string = "border";
        public static readonly onTop: string = "border-top";
        public static readonly onRight: string = "border-right";
        public static readonly onBottom: string = "border-bottom";
        public static readonly onLeft: string = "border-left";

        public static readonly no: string = "border-0";
        public static readonly noTop: string = "border-top-0";
        public static readonly noRight: string = "border-right-0";
        public static readonly noBottom: string = "border-bottom-0";
        public static readonly noLeft: string = "border-left-0";

        public static readonly colorPrimary: string = "border-primary";
        public static readonly colorSecondary: string = "border-secondary";
        public static readonly colorSuccess: string = "border-success";
        public static readonly colorDanger: string = "border-danger";
        public static readonly colorWarning: string = "border-warning";
        public static readonly colorInfo: string = "border-info";
        public static readonly colorLight: string = "border-light";
        public static readonly colorDark: string = "border-dark";
        public static readonly colorWhite: string = "border-white";

        public static readonly rounded: string = "rounded";
        public static readonly roundedTop: string = "rounded-top";
        public static readonly roundedRight: string = "rounded-right";
        public static readonly roundedBottom: string = "rounded-bottom";
        public static readonly roundedLeft: string = "rounded-left";
        public static readonly roundedCircle: string = "rounded-circle";
        public static readonly roundedNo: string = "rounded-0";
    };

    public static readonly Breakpoint = class {
        public static readonly xs: string = "xs";
        public static readonly sm: string = "sm";
        public static readonly md: string = "md";
        public static readonly lg: string = "lg";
        public static readonly xl: string = "xl";
        public static readonly print: string = "print";
    };

    public static readonly Button = class {
        public static readonly button = "btn";
        public static readonly buttonLarge: string = "btn-lg";
        public static readonly buttonSmall: string = "btn-sm";
        public static readonly buttonBlock: string = "btn-block";

        public static readonly buttonPrimary: string = "btn-primary";
        public static readonly buttonSecondary: string = "btn-secondary";
        public static readonly buttonSuccess: string = "btn-success";
        public static readonly buttonDanger: string = "btn-danger";
        public static readonly buttonWarning: string = "btn-warning";
        public static readonly buttonInfo: string = "btn-info";
        public static readonly buttonLight: string = "btn-light";
        public static readonly buttonDark: string = "btn-dark";
        public static readonly buttonLink: string = "btn-link";
        public static readonly buttonOutlinePrimary: string = "btn-outline-primary";
        public static readonly buttonOutlineSecondary: string = "btn-outline-secondary";
        public static readonly buttonOutlineSuccess: string = "btn-outline-success";
        public static readonly buttonOutlineDanger: string = "btn-outline-danger";
        public static readonly buttonOutlineWarning: string = "btn-outline-warning";
        public static readonly buttonOutlineInfo: string = "btn-outline-info";
        public static readonly buttonOutlineLight: string = "btn-outline-light";
        public static readonly buttonOutlineDark: string = "btn-outline-dark";
        public static readonly buttonOutlineLink: string = "btn-outline-link";

        public static readonly buttonGroup = "btn-group";
        public static readonly buttonGroupToggle = "btn-group-toggle";
        public static readonly buttonToolbar = "btn-toolbar";
    };

    public static readonly Card = class {
        public static readonly card: string = "card";
        public static readonly cardHeader: string = "card-header";
        public static readonly cardBody: string = "card-body";
        public static readonly cardTitle: string = "card-title";
        public static readonly cardText: string = "card-text";
    };

    public static readonly Color = class {
        public static readonly textPrimary: string = "text-primary";
        public static readonly textSecondary: string = "text-secondary";
        public static readonly textSuccess: string = "text-success";
        public static readonly textDanger: string = "text-danger";
        public static readonly textWarning: string = "text-warning";
        public static readonly textInfo: string = "text-info";
        public static readonly textLight: string = "text-light";
        public static readonly textDark: string = "text-dark";
        public static readonly textBody: string = "text-body";
        public static readonly textMuted: string = "text-muted";
        public static readonly textWhite: string = "text-white";
        public static readonly textBlack50: string = "text-black-50";
        public static readonly textWhite50: string = "text-white-50";

        public static readonly bgPrimary: string = "bg-primary";
        public static readonly bgSecondary: string = "bg-secondary";
        public static readonly bgSuccess: string = "bg-success";
        public static readonly bgDanger: string = "bg-danger";
        public static readonly bgWarning: string = "bg-warning";
        public static readonly bgInfo: string = "bg-info";
        public static readonly bgLight: string = "bg-light";
        public static readonly bgDark: string = "bg-dark";
        public static readonly bgWhite: string = "bg-white";
        public static readonly bgTransparent: string = "bg-transparent";
    };

    public static readonly Display = class {
        public static readonly none: string = "none";
        public static readonly inline: string = "inline";
        public static readonly inlineBlock: string = "inline-block";
        public static readonly block: string = "block";
        public static readonly table: string = "table";
        public static readonly tableCell: string = "table-cell";
        public static readonly tableRow: string = "table-row";
        public static readonly flex: string = "flex";
        public static readonly inlineFlex: string = "inline-flex";

        public static readonly d = (display: string, breakpoint?: string) => {
            if (breakpoint != null) {
                return "d-" + breakpoint + "-" + display;
            }
            return "d-" + display;
        };
    };

    public static readonly Flex = class {
        public static readonly column: string = "flex-column";
        public static readonly justifyContentEnd: string = "justify-content-end";
        public static readonly justifyContentCenter: string = "justify-content-center";
    };

    public static readonly ListGroup = class {
        public static readonly group: string = "list-group";
        public static readonly item: string = "list-group-item";
        public static readonly itemAction: string = "list-group-item-action";

        public static readonly itemPrimary: string = "list-group-item-primary";
        public static readonly itemSecondary: string = "list-group-item-secondary";
        public static readonly itemSuccess: string = "list-group-item-success";
        public static readonly itemDanger: string = "list-group-item-danger";
        public static readonly itemWarning: string = "list-group-item-warning";
        public static readonly itemInfo: string = "list-group-item-info";
        public static readonly itemLight: string = "list-group-item-light";
        public static readonly itemDark: string = "list-group-item-dark";
    };

    public static readonly InputGroup = class {
        public static readonly inputGroup: string = "input-group";
    };

    public static readonly Form = class {
        public static readonly inline: string = "form-inline";
        public static readonly group: string = "form-group";
        public static readonly control: string = "form-control";
        public static readonly controlSm: string = "form-control-sm";
        public static readonly controlLg: string = "form-control-lg";
        public static readonly check: string = "form-check";
        public static readonly checkInput: string = "form-check-input";
        public static readonly text: string = "form-text";
    };

    public static readonly Navbar = class {
        public static readonly navbar: string = "navbar";
        public static readonly navbarBrand: string = "navbar-brand";
        public static readonly navbarExpand: string = "navbar-expand";
        public static readonly navbarLight: string = "navbar-light";
        public static readonly navbarDark: string = "navbar-dark";
        public static readonly navbarText: string = "navbar-text";
        public static readonly navbarToggler: string = "navbar-toggler";
        public static readonly navbarCollapse: string = "navbar-collapse";
        public static readonly navbarTogglerIcon: string = "navbar-toggler-icon";
    };

    public static readonly Modal = class {
        public static readonly modal: string = "modal";
        public static readonly modalLarge: string = "modal-lg";
        public static readonly modalSmall: string = "modal-sm";
        public static readonly modalDialog: string = "modal-dialog";
        public static readonly modalDialogCentered: string = "modal-dialog-centered";
        public static readonly modalContent: string = "modal-content";
        public static readonly modalHeader: string = "modal-header";
        public static readonly modalTitle: string = "modal-title";
        public static readonly modalBody: string = "modal-body";
        public static readonly modalFooter: string = "modal-footer";
    };

    public static readonly Spacing = class {
        public static readonly m0 = "m-0";
        public static readonly m1 = "m-1";
        public static readonly m2 = "m-2";
        public static readonly m3 = "m-3";
        public static readonly m4 = "m-4";
        public static readonly m5 = "m-5";
        public static readonly mAuto = "m-auto";
        public static readonly mt0 = "mt-0";
        public static readonly mt1 = "mt-1";
        public static readonly mt2 = "mt-2";
        public static readonly mt3 = "mt-3";
        public static readonly mt4 = "mt-4";
        public static readonly mt5 = "mt-5";
        public static readonly mtAuto = "mt-auto";
        public static readonly mb0 = "mb-0";
        public static readonly mb1 = "mb-1";
        public static readonly mb2 = "mb-2";
        public static readonly mb3 = "mb-3";
        public static readonly mb4 = "mb-4";
        public static readonly mb5 = "mb-5";
        public static readonly mbAuto = "mb-auto";
        public static readonly ml0 = "ml-0";
        public static readonly ml1 = "ml-1";
        public static readonly ml2 = "ml-2";
        public static readonly ml3 = "ml-3";
        public static readonly ml4 = "ml-4";
        public static readonly ml5 = "ml-5";
        public static readonly mlAuto = "ml-auto";
        public static readonly mr0 = "mr-0";
        public static readonly mr1 = "mr-1";
        public static readonly mr2 = "mr-2";
        public static readonly mr3 = "mr-3";
        public static readonly mr4 = "mr-4";
        public static readonly mr5 = "mr-5";
        public static readonly mrAuto = "mr-auto";
        public static readonly mx0 = "mx-0";
        public static readonly mx1 = "mx-1";
        public static readonly mx2 = "mx-2";
        public static readonly mx3 = "mx-3";
        public static readonly mx4 = "mx-4";
        public static readonly mx5 = "mx-5";
        public static readonly mxAuto = "mx-auto";
        public static readonly my0 = "my-0";
        public static readonly my1 = "my-1";
        public static readonly my2 = "my-2";
        public static readonly my3 = "my-3";
        public static readonly my4 = "my-4";
        public static readonly my5 = "my-5";
        public static readonly myAuto = "my-auto";

        public static readonly p0 = "p-0";
        public static readonly p1 = "p-1";
        public static readonly p2 = "p-2";
        public static readonly p3 = "p-3";
        public static readonly p4 = "p-4";
        public static readonly p5 = "p-5";
        public static readonly pAuto = "p-auto";
        public static readonly pt0 = "pt-0";
        public static readonly pt1 = "pt-1";
        public static readonly pt2 = "pt-2";
        public static readonly pt3 = "pt-3";
        public static readonly pt4 = "pt-4";
        public static readonly pt5 = "pt-5";
        public static readonly ptAuto = "pt-auto";
        public static readonly pb0 = "pb-0";
        public static readonly pb1 = "pb-1";
        public static readonly pb2 = "pb-2";
        public static readonly pb3 = "pb-3";
        public static readonly pb4 = "pb-4";
        public static readonly pb5 = "pb-5";
        public static readonly pbAuto = "pb-auto";
        public static readonly pl0 = "pl-0";
        public static readonly pl1 = "pl-1";
        public static readonly pl2 = "pl-2";
        public static readonly pl3 = "pl-3";
        public static readonly pl4 = "pl-4";
        public static readonly pl5 = "pl-5";
        public static readonly plAuto = "pl-auto";
        public static readonly pr0 = "pr-0";
        public static readonly pr1 = "pr-1";
        public static readonly pr2 = "pr-2";
        public static readonly pr3 = "pr-3";
        public static readonly pr4 = "pr-4";
        public static readonly pr5 = "pr-5";
        public static readonly prAuto = "pr-auto";
        public static readonly px0 = "px-0";
        public static readonly px1 = "px-1";
        public static readonly px2 = "px-2";
        public static readonly px3 = "px-3";
        public static readonly px4 = "px-4";
        public static readonly px5 = "px-5";
        public static readonly pxAuto = "px-auto";
        public static readonly py0 = "py-0";
        public static readonly py1 = "py-1";
        public static readonly py2 = "py-2";
        public static readonly py3 = "py-3";
        public static readonly py4 = "py-4";
        public static readonly py5 = "py-5";
        public static readonly pyAuto = "py-auto";
    };
}
