import I18N from "gis3d/wf/i18n/I18N";
import { LengthMeasure } from "../../measure/LengthMeasure";
import { MeasurementUtils } from "../../measure/MeasurementUtils";
import { MeasureType } from "../../measure/MeasureType";
import { MeasureObject } from "../objects/MeasureObject";
import { MeasurePair } from "../objects/MeasurePair";
import { Scene } from "../scene/Scene";
import { MultiStepMeasurementTool } from "./MultiStepMeasurementTool";

export class LineTool extends MultiStepMeasurementTool<LengthMeasure> {
    public constructor(scene?: Scene) {
        super(scene);
        this.message = I18N.i("cityvu.core.measurementTool.lineTool");
    }

    public getMeasureType(): MeasureType {
        return MeasureType.LENGTH;
    }

    protected configureMeasureObject(mobj: MeasureObject): MeasureObject {
        mobj.markerColor = 0x00ff00;
        mobj.edgeColor = 0x00ff00;
        return mobj;
    }

    protected computeMeasurement(pair: MeasurePair<LengthMeasure>): void {
        super.computeMeasurement(pair);
        let lenght = 0;
        const m = pair.measure;
        const crs = this.scene.crs;
        for (let i = 0; i < m.coords.length - 1; i++) {
            lenght += crs.distance(m.coords[i + 1], m.coords[i]);
        }
        m.value = lenght;
    }
}
