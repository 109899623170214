import { StatsPanel } from "./StatsPanel";
import { StatsOptions } from "./StatsOptions";
import { StatsData } from "./StatsData";

declare global {
    interface Performance {
        memory: {
            totalJSHeapSize: number;
            usedJSHeapSize: number;
            jsHeapSizeLimit: number;
        };
    }
}

export class Stats {
    protected _panel?: StatsPanel;
    private _data: StatsData;
    private lastTime: number = 0;
    private lastDrawTime: number = 0;
    private frameCount: number = 0;

    public constructor(readonly opts: StatsOptions, readonly usePanel: boolean = true) {
        if (usePanel) {
            this._panel = new StatsPanel(opts);
            this.panel!.init();
        }
        this._data = {
            fps: 0,
            timePerFrame: 0,
            memoryHeap: 0,
            memoryLimit: 0,
            lastTime: 0,
        } as StatsData;
    }

    public begin(): void {
        this.lastTime = (performance || Date).now();
    }

    public update(): void {
        const curTime: number = (performance || Date).now();
        if (this.opts.fps) {
            this.frameCount++;
        }

        if (curTime - this.lastDrawTime >= 1000) {
            this.data.fps = Math.ceil((this.frameCount * 1000) / (curTime - this.lastDrawTime));

            if (this.opts.timePerFrame) {
                this.data.timePerFrame = Math.round(curTime - this.lastTime);
            }

            if (this.opts.memory) {
                if (window.performance.memory) {
                    const memory = performance.memory;
                    this.data.memoryHeap = Math.ceil(memory.usedJSHeapSize / 1048576);
                    this.data.memoryLimit = Math.ceil(memory.jsHeapSizeLimit / 1048576);
                }
            }

            if (this.usePanel && this.panel && this.panel.isStarted()) {
                this.panel.update(this.data);
            }

            this.frameCount = 0;
            this.lastDrawTime = curTime;
        }
        this.lastTime = curTime;
    }

    public get panel(): StatsPanel | undefined {
        return this._panel;
    }

    public get data(): StatsData {
        return this._data;
    }
}
