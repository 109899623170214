import { CookieManager } from "gis3d/wf/util/CookieManager";

class RealTokenManager {
    private _urlToken : string | null;
    private cookieManager : CookieManager = new CookieManager();
    private static readonly COOKIE_TOKEN_NAME : string = "API_AUTH_TOKEN";
    private static readonly COOKIE_TOKEN_DURATION : number = 7 * 24 * 60 * 60;

    public constructor() {        
        const url : URL = new URL(window.location.href);
        this._urlToken = url.searchParams.get("token");
    }

    public get urlToken() : string | null {
        return this._urlToken;
    }

    public set urlToken(token : string | null)  {
        this._urlToken = token;
    }

    public get storedToken() : string | null {
        return this.cookieManager.get(RealTokenManager.COOKIE_TOKEN_NAME);
    }

    public set storedToken(token: string | null) {
        if (token) {
            this.cookieManager.set(RealTokenManager.COOKIE_TOKEN_NAME, token, "/", RealTokenManager.COOKIE_TOKEN_DURATION);
        } else {
            this.cookieManager.delete(RealTokenManager.COOKIE_TOKEN_NAME);
        }
    }

    public get token() : string | null {
        if (this.urlToken) {
            return this.urlToken;
        }
        return this.storedToken;   
    }
}

export default class TokenManager {
    private static readonly instance = new RealTokenManager();
    
    public static get urlToken() : string | null {
        return TokenManager.instance.urlToken;
    }

    public static get storedToken() : string | null {
        return TokenManager.instance.storedToken;
    }

    public static set storedToken(token: string | null) {
        TokenManager.instance.storedToken = token;
    }

    public static get token() : string | null {
        return TokenManager.instance.token;
    }

    public static clearUrlToken() : void {
        TokenManager.instance.urlToken = null;
    }

    public static getBearer(token: string) : string {
        return btoa("x:" + token);
    }
}