import cityvu from "assets/img/cityvu.png";
import { Navbar } from "gis3d/wf/ui/widget/Navbar";
import dom from "gis3d/wf/util/DomUtils";



export class CityvuNavbar extends Navbar {
    protected logo!:HTMLElement;

    protected createBrand() : void {
        super.createBrand();
        this.logo = dom.el("img", { 
            classes : [ "logo" ],
            attrs : new Map([
                ["src", cityvu],
                ["alt", "3DGIS cityvu"]
            ])
        });
        dom.append(this.navbarBrand, this.logo);
    }
}
