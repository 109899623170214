import { MeasureType } from "./MeasureType";
import { Measure } from "./Measure";

type Constructor<T = {}> = new (...args: any[]) => T;

export class MeasureFactory {
    private map : Map<MeasureType, Constructor> = new Map();

    public register(type:MeasureType, ctr:Constructor) : void {
        this.map.set(type, ctr);
    }

    public get<T extends Measure>(type:MeasureType) : T | null {
        const ctr = this.map.get(type);
        if (ctr) {
            return new ctr() as T;
        }
        return null;
    }
}