export default class MouseUtils {
    public static readonly LEFT_BUTTON: number = 0;
    public static readonly MIDDLE_BUTTON: number = 1;
    public static readonly RIGHT_BUTTON: number = 2;
    //
    public static readonly isLeft = (buttons: number): boolean => MouseUtils.isButton(buttons, MouseUtils.LEFT_BUTTON);

    public static readonly isMiddle = (buttons: number): boolean => MouseUtils.isButton(buttons, MouseUtils.MIDDLE_BUTTON);

    public static readonly isRight = (buttons: number): boolean => MouseUtils.isButton(buttons, MouseUtils.RIGHT_BUTTON);

    public static readonly isButton = (buttons: number, check: number): boolean => {
        const b = MouseUtils.unmaskButtons(buttons);
        return b.length > 0 ? b[0] == check : false;
    }

    public static readonly unmaskButtons = (buttons: number): Array<number> => {
        const data = [];
        if ((buttons & 1) === 1) {
            // left
            data.push(MouseUtils.LEFT_BUTTON);
        }
        if ((buttons & 4) === 4) {
            // middle
            data.push(MouseUtils.MIDDLE_BUTTON);
        }
        if ((buttons & 2) === 2) {
            // right
            data.push(MouseUtils.RIGHT_BUTTON);
        }
        if ((buttons & 8) === 8) {
            // 4th
            data.push(3);
        }
        if ((buttons & 16) === 16) {
            // 5th
            data.push(4);
        }
        return data;
    }
}
