import { MeasurementTool } from "./MeasurementTool";
import { Scene } from "../scene/Scene";
import { MeasureType } from "../../measure/MeasureType";

export class BaseMeasurementTool extends MeasurementTool {
    public constructor(scene?: Scene) {
        super();
        if (scene) {
            this.scene = scene;
        }
    }

    public getMeasureType(): MeasureType {
        throw new Error("Method not implemented.");
    }

    public onSceneChange(scene: Scene): void {

    }

    public enable(): void {

    }
    public disable(): void {

    }
}
