import { DataSourceType } from "./DataSourceType";
import { DataSource } from "./DataSource";
import { SceneDefinitionDataSource } from "../scene/definition/SceneDefinitionDataSource";

// TODO PhotosphereDataSource
export class PhotosphereDataSource extends DataSource {
    public constructor(readonly definition: SceneDefinitionDataSource) {
        super(definition);
    }

    public get type(): DataSourceType {
        return DataSourceType.PHOTOSPHERE;
    }
}
