import { Object3D } from "three";
import { IPointCloudTreeNode } from "./types";

export class PointCloudTree extends Object3D {
  public root: IPointCloudTreeNode | null = null;

  public initialized() {
    return this.root !== null;
  }
}
