
export class Point {
    public x:number | null;
    public y:number | null;

    public constructor(x:number | null  = null, y:number | null  = null) {
        this.x = x;
        this.y = y;
    }

    public reset() : void {
        this.x = null;
        this.y = null;
    }
}