import { UiContainer } from "gis3d/wf/ui/UiContainer";
import { FormEntry } from "./FormEntry";

export class Form extends UiContainer {
    protected entries: Array<FormEntry>;

    constructor() {
        super();
        this.domElementType = "form";
        this.entries = [];
    }

    public addEntry(iuc: FormEntry): void {
        this.entries.push(iuc);
        this.addChild(iuc.uiComponent);
    }

    public get data(): Map<string, any> {
        const map = new Map<string, any>();
        for (const entry of this.entries) {
            map.set(entry.name, entry.value);
        }
        return map;
    }

    public set data(data: Map<string, any>) {
        for (const entry of this.entries) {
            const name = entry.name;
            const entryData = data.get(name);
            if (entryData != null) {
                entry.value = entryData;
            } else {
                entry.reset();
            }
        }
    }

    public reset() : void {
        this.entries.map(e => e.reset());
    }
}
