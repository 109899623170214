import { BasicScene } from "gis3d/cityvu/core/three/scene/BasicScene";
import { CrsFactory } from "../core/geo/crs/CrsFactory";
import { GeoUtils } from "../core/geo/GeoUtils";
import { Scene } from "../core/three/scene/Scene";
import { Experiment } from "./Experiment";

class ExperimentScene extends BasicScene {
    constructor() {
        super();
        this.crs = CrsFactory.create(GeoUtils.cityvuCrsSimple);
    }

    public init(): void {
        super.init();
    }

    public onEngineAssigment(): void {}

    public updateScene(delta: number): void {
        super.updateScene(delta);
    }
}

export default class FrustumExperiment implements Experiment {
    public scene: Scene = new ExperimentScene();
}
