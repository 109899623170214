import { DataSourceType } from "./DataSourceType";
import { DataSource } from "./DataSource";
import { SceneDefinitionDataSource } from "../scene/definition/SceneDefinitionDataSource";

export class PointCloudDataSource extends DataSource {
    public constructor(readonly definition: SceneDefinitionDataSource) {
        super(definition);
    }

    public get type(): DataSourceType {
        return DataSourceType.POINTCLOUD;
    }

    public get url(): string {
        if (this.definition.url != null) {
            return this.definition.url;
        }
        throw Error("dataSource.undefinedUrl");
    }
}
