import dom from "gis3d/wf/util/DomUtils";
import ui from "gis3d/wf/ui/style/UiStyle";
import on from "gis3d/wf/core/On";

import { BaseUiComponent } from "gis3d/wf/ui/BaseUiComponent";
import { Box } from "gis3d/wf/ui/geom/Box";
import { Size } from "gis3d/wf/ui/geom/Size";

export class OverlayArea extends BaseUiComponent {

    constructor() {
        super();
        this.domElementOptions = {
            classes : [ ui.p("CityvuOverlayArea") ]
        };
    }

    public set content(html:string) {
        dom.set(this.domNode!, html);
    }

    public resize(box : Box | null) : Size | null {
        // we do our own resizing
        return dom.marginBox(this.domNode!);
    }
}