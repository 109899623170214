import { UrlParser as WfUrlParser, UrlData as WfUrlData, UrlProcessor as WfUrlProcessor } from "gis3d/wf/util/UrlParser";

export interface UrlData extends WfUrlData {
    sceneUrl?: string;
    experiment?: string;
}

class UrlProcessor implements WfUrlProcessor<UrlData> {
    private static readonly K_SCENE_URL : string = "sceneUrl";
    private static readonly K_EXPERIMENT : string = "experiment";

    public process(url: URL): UrlData | null {
        if (url.hash.length > 1) {
            try {
                const payload = JSON.parse(decodeURI(url.hash.substr(1)));
                const urlData = {} as UrlData;
                if (payload[UrlProcessor.K_SCENE_URL] != null) {
                    urlData.sceneUrl = payload[UrlProcessor.K_SCENE_URL].trim();
                }
                if (payload[UrlProcessor.K_EXPERIMENT] != null) {
                    urlData.experiment = payload[UrlProcessor.K_EXPERIMENT].trim();
                }
                return urlData;
            } catch {
                console.error("Cannot parse url parameters");
            }
        }
        return null;
    }

}

export class UrlDataParser extends WfUrlParser<UrlData> {

    constructor() {
        super(new UrlProcessor());
    }
}