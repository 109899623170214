import dom from "gis3d/wf/util/DomUtils";
import uiStyle from "gis3d/wf/ui/style/UiStyle";

import { UiContainer } from "gis3d/wf/ui/UiContainer";
import { Region } from "gis3d/wf/ui/layout/Region";
import { Splitter } from "gis3d/wf/ui/layout/Splitter";
import { RegionAwareUiComponent } from "gis3d/wf/ui/layout/RegionAwareUiComponent";

export class Pane extends UiContainer implements RegionAwareUiComponent {
    private _region: Region = Region.Center;
    private _resizable: boolean = false;
    private _splitter: Splitter | null = null;
    private _liveSplitter: boolean = true;
    private _resizeChildren: boolean = false;

    public constructor(region: Region = Region.Center, resizable: boolean = false) {
        super();
        this.region = region;
        this.resizable = resizable;
    }

    public get region(): Region {
        return this._region;
    }

    public set region(region: Region) {
        this._region = region;
    }

    public get liveSplitter(): boolean {
        return this._liveSplitter;
    }

    public set liveSplitter(liveSplitter: boolean) {
        this._liveSplitter = liveSplitter;
    }

    public get resizable(): boolean {
        return this._resizable;
    }

    public set resizable(resizable: boolean) {
        // pane is resizable only if not in center region
        this._resizable = resizable && this.region != Region.Center;
    }

    public get splitter(): Splitter | null {
        return this._splitter;
    }

    public set splitter(splitter: Splitter | null) {
        if (this.region != Region.Center) {
            this._splitter = splitter;
        } else {
            console.warn("Cannot set splitter to a Pane with region = Region.Center");
        }
    }

    public get resizeChildren(): boolean {
        return this._resizeChildren;
    }

    public set resizeChildren(resizeChildren: boolean) {
        this._resizeChildren = resizeChildren;
    }

    public build(): void {
        const cName = uiStyle.p("Pane");
        if (!this.domElementOptions.classes) {
            this.domElementOptions.classes = [];
        }
        this.domElementOptions.classes!.push(cName, uiStyle.c(cName, Region[this.region]));
        super.build();
    }

    public layout(): void {
        let box = null;
        if (this.resizeChildren) {
            box = dom.marginBox(this.domNode!);
            box.x = box.y = 0;
        }
        for (let child of this.children) {
            child.resize(box);
        }
    }
}
