import UiStyle from "../../style/UiStyle";
import { InputUiComponent } from "../InputUiComponent";

export enum InputType {
    TEXT = "text",
    EMAIL = "email",
    COLOR = "color",
    DATE = "date",
    HIDDEN = "hidden",
    NUMBER = "number",
    PASSWORD = "password",
    RADIO = "radio",
    RESET = "reset",
    SUBMIT = "submit",
    TIME = "time"
}

export class Input<T = any> extends InputUiComponent<T> {
    private _type: InputType = InputType.TEXT;

    public constructor() {
        super();
        this.domElementType = "input";
        this.domElementOptions.classes = [UiStyle.Form.control];
        this.domElementOptions.attrs = new Map([["type", this.type]]);
    }

    public get type(): InputType {
        return this._type;
    }

    public set type(value: InputType) {
        this._type = value;
        this.domElementOptions.attrs!.set("type", value);
    }
    
    public get value(): T | null {
        const node = this.domNode! as HTMLInputElement;
        let value = null;
        switch (this.type) {
            case InputType.NUMBER:
                value = node.valueAsNumber;
                break;
            case InputType.DATE:
            case InputType.TIME:
                value = node.valueAsDate;
                break;
            default:
                value = node.value;
        }
        return value;
    }

    public set value(v: T | null) {
        const node = this.domNode! as HTMLInputElement;
        if (v == null) {
            node.value = node.defaultValue;
        } else {
            switch (this.type) {
                case InputType.NUMBER:
                    node.valueAsNumber = v as unknown as number;
                    break;
                case InputType.DATE:
                case InputType.TIME:
                    node.valueAsDate = v as unknown as Date;
                    break;
                default:
                    node.value = v.toString();
            }
        }
    }

    public reset() : void {
        const node = this.domNode! as HTMLInputElement;
        node.value = node.defaultValue;
    }
}
