import { AppEventEmitterDecorator } from "../../event/AppEventEmitterDecorator";
import { BaseMeasurementTool } from "./BaseMeasurementTool";
import { InputDelegate } from "../../event/InputDelegate";
import { Picker } from "../scene/picker/Picker";
import { PickRequest } from "../scene/picker/PickRequest";
import { Scene } from "../scene/Scene";
import { Message, MessageType, MessageRemover } from "gis3d/wf/ui/widget/Message";
import { MeasureObject } from "../objects/MeasureObject";
import { Measure3dScene } from "../objects/Measure3dScene";
import { InputManagerEvent } from "../../event/InputManager";
import { AppEvent } from "../../event/AppEvent";
import { Vector2, Raycaster, Mesh, MeshLambertMaterial, Vector3 } from "three";
import ThreeUtils from "../ThreeUtils";
import { Measure } from "../../measure/Measure";
import { MeasurePair } from "../objects/MeasurePair";
import { SortedPickResults } from "../scene/picker/SortedPickResults";
import { DragData } from "../../event/DragData";

const EventEmitterBaseMeasurementTool = AppEventEmitterDecorator(BaseMeasurementTool);

export abstract class EventedMeasurementTool extends EventEmitterBaseMeasurementTool implements InputDelegate {
    public delegationPriority: number = 90;
    public delegationEnabled: boolean = true;

    protected picker: Picker;
    protected pickRequest: PickRequest;
    protected message: string | null = null;
    protected messageRemover: MessageRemover | null = null;
    protected raycaster?: Raycaster;

    private _hoveredMarkerColor: number = 0xff9900;
    private _hoveredMarker: Mesh | null = null;
    private _hoverEnabled: boolean = true;

    private _pointerEnabled: boolean = true;
    private userPointEnabled:boolean = false;

    public constructor(scene?: Scene) {
        super(scene);
        this.picker = new Picker();
        this.pickRequest = new PickRequest();
    }

    protected attachEvents(): void {
        this.addEventListener(this.mouseMove, InputManagerEvent.MOUSEMOVE);
        this.addEventListener(this.drag, InputManagerEvent.DRAG);
        this.addEventListener(this.drop, InputManagerEvent.DROP);
    }

    protected drag(appEvent: AppEvent): void {
        const dragData = appEvent.value as DragData;
        if (dragData.isStart) {
            this.userPointEnabled = this.pointerEnabled;
            this.pointerEnabled = false;
        }
    }

    protected drop(appEvent: AppEvent): void {
        this.pointerEnabled = this.userPointEnabled;
    }

    protected configureMeasureObject(mobj: MeasureObject): MeasureObject {
        return mobj;
    }

    public createMeasure<T extends Measure = Measure>(): MeasurePair<T> {
        const m = this.broker.factory.get(this.getMeasureType())! as T;
        return {
            measure: m,
            measureObject: this.configureMeasureObject(new MeasureObject(m.id, this.getMeasureType())),
        };
    }

    public onSceneChange(scene: Scene): void {
        super.onSceneChange(scene);
        this.pickRequest.scene = scene;
    }

    public clearMeasures(): void {
        const measuresOfType = this.measures3dScene.root.children.filter(o => (o as MeasureObject).measureType == this.getMeasureType());
        measuresOfType.forEach(o => {
            (o as MeasureObject).clear();
            this.measures3dScene.root.remove(o);
        });
    }

    public enable(): void {
        super.enable();
        this.attachEvents();
        if (this.message != null) {
            this.messageRemover = this.broker.message({
                content: this.message,
                type: MessageType.IMPORTANT,
            } as Message);
        }
    }

    public disable(): void {
        super.disable();
        this.removeEventListeners();
        if (this.messageRemover != null) {
            this.messageRemover();
        }
        this.hidePointer();
    }

    public get measures3dScene(): Measure3dScene {
        return super.measures3dScene;
    }

    public set measures3dScene(value: Measure3dScene) {
        super.measures3dScene = value;
    }

    public get hoveredMarker(): Mesh | null {
        return this._hoveredMarker;
    }

    public set hoveredMarker(value: Mesh | null) {
        this._hoveredMarker = value;
    }

    public get hoveredMarkerColor(): number {
        return this._hoveredMarkerColor;
    }

    public set hoveredMarkerColor(value: number) {
        this._hoveredMarkerColor = value;
    }

    public get hoverEnabled(): boolean {
        return this._hoverEnabled;
    }

    public get pointerEnabled(): boolean {
        return this._pointerEnabled;
    }

    public set pointerEnabled(value: boolean) {
        this._pointerEnabled = value;
        if (value === false) {
            this.hidePointer();
        }
    }

    public set hoverEnabled(value: boolean) {
        this._hoverEnabled = value;
        if (value === false) {
            this.unhoverMarker();
        }
    }

    public showPointer(position: Vector3): void {
        const p = this.measures3dScene.pointer;
        p.position.copy(position);
        p.visible = true;
    }

    public hidePointer(): void {
        this.measures3dScene.pointer.visible = false;
    }

    protected hoverMarker(m: Mesh) {
        (m.material as MeshLambertMaterial).color.setHex(this.hoveredMarkerColor);
        this.hoveredMarker = m;
    }

    protected unhoverMarker(): void {
        if (this.hoveredMarker != null) {
            const mobj = this.hoveredMarker!.parent!.parent as MeasureObject;
            if (mobj != null) {
                (this.hoveredMarker.material as MeshLambertMaterial).color.setHex(mobj.markerColor);
            }
            this.hoveredMarker = null;
        }
    }

    protected mouseMove(appEvent: AppEvent): void {
        const mouse = appEvent.value as Vector2;
        if (this.hoverEnabled === false) {
            this.movePointer(mouse);
            return;
        }

        this.raycaster = ThreeUtils.raycaster(mouse, this.scene.camera, this.scene.size.w!, this.scene.size.h!, this.raycaster);
        if (this.raycaster != null) {
            // this could be restricted to current object if needed (as in MultiStepMeasTool)
            const intersections = this.raycaster.intersectObjects(
                this.measures3dScene.root.children.filter(o => (o as MeasureObject).measureType == this.getMeasureType()).map(o => (o as MeasureObject).markers),
                true,
            );
            if (intersections.length > 0) {
                this.hidePointer();
                const marker = intersections[0].object as Mesh;
                if (this.hoveredMarker !== marker) {
                    this.unhoverMarker();
                    this.hoverMarker(marker);
                }
            } else {
                this.unhoverMarker();
                this.movePointer(mouse);
            }
        }
    }

    private movePointer(mouse: Vector2) {
        if (this.pointerEnabled) {
            this.pickRequest.normalizedCoords = ThreeUtils.normalize2dCoordinates(mouse, this.scene.size.w!, this.scene.size.h!);
            const results = this.picker.pick(this.pickRequest);
            const hit = new SortedPickResults(results).first();
            if (hit) {
                this.showPointer(hit.intersection.point);
            } else {
                this.hidePointer();
            }
        } else {
            this.hidePointer();
        }
    }

    public removeMeasure(m: Measure): void {
        const mobj = this.findMeasureObjectByMeasureId(m.id);
        if (mobj) {
            this.measures3dScene.root.remove(mobj);
            mobj.clear();
        }
    }

    protected findMeasureObjectByMeasureId(measureId: string): MeasureObject | null {
        for (const c of this.measures3dScene.root.children) {
            const mobj = c as MeasureObject;
            if (measureId == mobj.measureId) {
                return mobj;
            }
        }
        return null;
    }

    public highlightMeasure(m: Measure): void {
        const mobj = this.findMeasureObjectByMeasureId(m.id);
        if (mobj) {
            mobj.highlighted = true;
        }
    }

    public dehighlightMeasure(m: Measure): void {
        const mobj = this.findMeasureObjectByMeasureId(m.id);
        if (mobj) {
            mobj.highlighted = false;
        }
    }
}
