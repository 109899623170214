import { Cityvu } from "../Cityvu";
import { DataChannelOptions } from "../CityvuOptions";
import { DataChannel, DataChannelStatus } from "gis3d/wf/net/rtc/DataChannel";
import { SignalingChannel } from "gis3d/wf/net/rtc/SignalingChannel";
import { ConnectionStatus } from "gis3d/wf/ui/widget/ConnectionStatus";
import { DataChannelTextMessage } from "gis3d/wf/net/rtc/DataChannelTextMessage";
import { RtcActions } from "./RtcActions";
import { MeasureType } from "../core/measure/MeasureType";
import { ControlsType } from "../core/three/controls/ControlsType";
import { Vector3 } from "three";

export class RtcChannel {
    protected dataChannel: DataChannel;
    protected commandActions!: Map<string, (payload: any) => void>;

    public constructor(readonly opts: DataChannelOptions, readonly app: Cityvu) {
        const signallingChannel = new SignalingChannel(opts.signallingUrl!);
        signallingChannel.messageFactory.configureSender(opts.app!, opts.userId!, opts.windowId!);

        const connStatusWidget = app.gui.connectionStatus;
        connStatusWidget.displayed = true;

        const dataChannel = new DataChannel(signallingChannel);
        dataChannel.onReady = () => {
            signallingChannel.messageFactory.configureDestination(opts.connectToApp!, opts.connectToUserId!, opts.connectToWindowId!);
            dataChannel.connect();
            connStatusWidget.status = ConnectionStatus.CONNECTING;
        };

        dataChannel.onConnect = () => {
            console.log("Connected");
            connStatusWidget.status = ConnectionStatus.CONNECTED;
            this.send(RtcActions.READY, null);
        };

        dataChannel.onConnectionTimeout = () => {
            connStatusWidget.status = ConnectionStatus.DISCONNECTED;
            console.log("Connection timeout. Reconnecting...");
            dataChannel.connect();
            connStatusWidget.status = ConnectionStatus.CONNECTING;
        };

        dataChannel.onDisconnect = () => {
            connStatusWidget.status = ConnectionStatus.DISCONNECTED;
            console.log("Disconnected. Reconnecting...");
            dataChannel.connect();
            connStatusWidget.status = ConnectionStatus.CONNECTING;
        };

        dataChannel.onMessage = (data: any) => {
            this.process(data);
        };
        this.registerCommandActions();
        this.dataChannel = dataChannel;
    }

    protected registerCommandActions(): void {
        this.commandActions = new Map();
        this.commandActions.set(RtcActions.LOAD, payload => this.onActionLoad(payload));
        this.commandActions.set(RtcActions.RESET, payload => this.onActionReset(payload));
        this.commandActions.set(RtcActions.STARTMEASURING, payload => this.onActionStartMeasuring(payload));
        this.commandActions.set(RtcActions.STOPMEASURING, payload => this.onActionStopMeasuring(payload));
        this.commandActions.set(RtcActions.SETPOSITION, payload => this.onActionSetPosition(payload));
        this.commandActions.set(RtcActions.POSITION, payload => this.onActionPosition(payload));
        this.commandActions.set(RtcActions.CONTROLS, payload => this.onActionControls(payload));
    }

    protected onActionLoad(payload: any): void {
        const data = payload as { url?: string; sceneDefinition?: string };
        if (data != null) {
            if (data.url != null) {
                this.app.loadFromUrl(data.url).then(
                    () => {
                        this.send(RtcActions.LOADED, null);
                    },
                    () => {
                        this.send(RtcActions.LOADERROR, null);
                    },
                );
            } else if (data.sceneDefinition != null) {
                if (this.app.loadFromString(data.sceneDefinition)) {
                    this.send(RtcActions.LOADED, null);
                } else {
                    this.send(RtcActions.LOADERROR, null);
                }
            }
        }
    }

    protected onActionReset(payload: any): void {
        this.app.reset(true);
        this.send(RtcActions.ACK, null);
    }

    protected onActionStartMeasuring(payload: any): void {
        const data = payload as { measureType?: string };
        if (data != null && data.measureType != null) {
            this.app.startMeasuring(data.measureType as MeasureType);
            this.send(RtcActions.ACK, null);
        }
    }

    protected onActionStopMeasuring(payload: any): void {
        this.app.stopMeasuring();
        this.send(RtcActions.ACK, null);
    }

    protected onActionSetPosition(payload: any): void {
        const data = payload as {
            position?: Array<number>;
            lookAt?: Array<number>;
            direction?: Array<number>;
        };
        if (data != null) {
            if (data.position != null) {
                this.app.pose.position.set(data.position[0], data.position[1], data.position[2]);
            }
            if (data.direction != null) {
                this.app.pose.lookAt(
                    new Vector3(data.direction[0], data.direction[1], data.direction[2]) //
                        .normalize()
                        .add(this.app.pose.position),
                );
            } else if (data.lookAt != null) {
                this.app.pose.lookAt(new Vector3(data.lookAt[0], data.lookAt[1], data.lookAt[2]));
            }
            this.send(RtcActions.ACK, null);
        }
    }

    protected onActionPosition(payload: any): void {
        this.send(RtcActions.POSITION, {
            position: this.app.pose.position.toArray(),
            direction: this.app.pose.direction.toArray(),
        });
    }

    protected onActionControls(payload: any): void {
        const data = payload as { controlsType?: string };
        if (data != null && data.controlsType != null) {
            this.app.selectControl(data.controlsType as ControlsType);
            this.send(RtcActions.ACK, null);
        }
    }

    public send(action: string, payload: any) {
        this.dataChannel.send(new DataChannelTextMessage(action, payload).serialize());
    }

    protected process(data: any) {
        const message = DataChannelTextMessage.deserialize(data);
        const commandAction = this.commandActions.get(message.action!);
        if (commandAction != null) {
            commandAction(message.payload);
        }
    }

    public isConnected(): boolean {
        return this.dataChannel.status == DataChannelStatus.CONNECTED;
    }

    public open(): void {
        this.dataChannel.open();
    }

    public close(alsoCloseSignaling: boolean = true): void {
        this.dataChannel.close(alsoCloseSignaling);
    }
}
