import NumberUtils from "gis3d/wf/util/NumberUtils";
import { Vector2, Vector3 } from "three";

export class Pose {
    private _position: Vector3 = new Vector3();
    private _maxPitch: number = NumberUtils.PI_OVER_2;
    private _minPitch: number = -NumberUtils.PI_OVER_2;
    private _orbitPitchThreshold: number = NumberUtils.EPSILON4 * NumberUtils.DEG_2_RAD;

    private _animate: boolean = false;
    private _updateAnimation: boolean = false;
    private _zoomRequested: boolean = false;

    private _pitch: number = 0;
    private _roll: number = 0;
    private _yaw: number = 0;

    public get position(): Vector3 {
        return this._position;
    }

    public get direction(): Vector3 {
        const dir = new Vector3(0, 1, 0);
        dir.applyAxisAngle(new Vector3(0, 0, 1), this.yaw);
        dir.applyAxisAngle(new Vector3(1, 0, 0), this.pitch);
        if (this.roll !== 0) {
            dir.applyAxisAngle(new Vector3(0, 1, 0), this.roll);
        }
        return dir;
    }

    public pan(delta: Vector2): void {
        this.pan3(new Vector3(delta.x, 0, delta.y));
    }

    public pan3(delta: Vector3): void {
        const dir = this.direction;
        const side = this.side();
        const up = side
            .clone()
            .cross(dir)
            .normalize();
        let t = side
            .multiplyScalar(delta.x)
            .add(dir.multiplyScalar(delta.y))
            .add(up.multiplyScalar(delta.z));
        this.translate(t);
    }

    public moveAlong(delta: number): void {
        const dir = this.direction;
        this.translate(dir.multiplyScalar(delta));
    }

    public side(): Vector3 {
        const d = this.direction;
        if (this.roll !== 0) {
            d.applyAxisAngle(new Vector3(0, 1, 0), this.roll);
        }
        d.applyAxisAngle(new Vector3(1, 0, 0), -this.pitch);
        d.applyAxisAngle(new Vector3(0, 0, 1), -NumberUtils.PI_OVER_2);
        d.normalize();
        return d;
    }

    public orbitSphere(center: Vector3, pitchStep: number, yawStep: number): void {
        // compute spherical coords
        const v = new Vector3().subVectors(this.position, center);
        const r = v.length();

        const theta = Math.acos(v.z / r);
        // Math.atan2(v.z, Math.sqrt(v.x * v.x + v.y * v.y)) + NumberUtils.PI_OVER_2;
        const phi = Math.atan2(v.x, -v.y);

        const theta2 = Math.min(NumberUtils.PI - this.orbitPitchThreshold, Math.max(this.orbitPitchThreshold, (theta - pitchStep) % NumberUtils.TWO_PI));
        const phi2 = (phi + yawStep) % NumberUtils.TWO_PI;

        const dx = r * Math.sin(theta2) * Math.sin(phi2);
        const dy = -r * Math.sin(theta2) * Math.cos(phi2);
        const dz = r * Math.cos(theta2);

        this.position.set(center.x + dx, center.y + dy, center.z + dz);
        /*
        const theta = Math.acos(-v.z / r);
        const phi = Math.atan2(v.y, -v.x);
        // new angles
        const theta2 = Math.min(Math.PI - this.orbitPitchThreshold, Math.max(this.orbitPitchThreshold, (theta - pitchStep) % NumberUtils.TWO_PI));
        const phi2 = (phi + yawStep) % NumberUtils.TWO_PI;
        // compute back cartesian coords
        this.position.set(center.x + r * Math.sin(theta2) * Math.sin(phi2), center.y + r * Math.cos(theta2), center.z - r * Math.sin(theta2) * Math.cos(phi2));
        */
        // update pitch and yaw (limited)
        this.pitch = Math.min(this.maxPitch - this.orbitPitchThreshold, Math.max(this.minPitch + this.orbitPitchThreshold, this.pitch - pitchStep));
        this.yaw += yawStep;
        this.updateAnimation = true;
    }

    public orbit(center: Vector3, angle: number): void {
        const v = new Vector2(this.position.x - center.x, this.position.y - center.y);
        const omega = Math.atan2(-v.y, v.x);
        const r = v.length();
        const cos = Math.cos(angle - omega);
        const sin = Math.sin(angle - omega);
        //const p = new Vector3(r * cos + center.x, this.position.y, r * sin + center.z);
        const p = new Vector3(r * cos + center.x, r * sin + center.y, this.position.z);
        this.position.copy(p);
        this.updateAnimation = true;
    }

    public orbit3d(center: Vector3, angle: number): void {
        // TODO orbit3d using a circle centered on center with radius = distance and rotated by yaw, pitch, roll
        const r = new Vector3().subVectors(this.position, center).length();
    }

    public lookAt(point: Vector3): void {
        const d = new Vector3().subVectors(point, this.position);
        if (d.length() < NumberUtils.EPSILON4) {
            return;
        }
        d.normalize();

        this.pitch = Math.atan2(d.z, Math.sqrt(d.x * d.x + d.y * d.y));
        this.yaw = Math.atan2(d.y, d.x) - Math.PI / 2;
        this.roll = 0;
    }

    public orientationToPoint(point: Vector3): Vector3 {
        const d = new Vector3().subVectors(point, this.position);
        d.normalize();

        const v = new Vector3();
        v.x = Math.atan2(d.z, Math.sqrt(d.x * d.x + d.y * d.y));
        v.y = Math.atan2(d.y, d.x);
        v.z = 0;
        return v;
    }

    public zoomTo(point: Vector3, distance: number = 10): void {
        const v = new Vector3().subVectors(this.position, point);
        v.normalize()
            .multiplyScalar(distance)
            .add(point);
        this.position.copy(v);
        if (this.animate) {
            this.zoomRequested = true;
            this.updateAnimation = true;
        } else {
            this.lookAt(point);
        }
    }

    public translate(delta: Vector3): void {
        this.position.add(delta);
        this.updateAnimation = true;
    }

    public get yaw(): number {
        return this._yaw;
    }

    public set yaw(value: number) {
        this._yaw = value % (2 * Math.PI);
    }

    public get pitch(): number {
        return this._pitch;
    }

    public set pitch(p: number) {
        this._pitch = Math.max(Math.min(p, this.maxPitch), this.minPitch);
    }

    public get roll(): number {
        return this._roll;
    }

    public set roll(value: number) {
        this._roll = value % (2 * Math.PI);
    }

    public get minPitch(): number {
        return this._minPitch;
    }

    public set minPitch(value: number) {
        this._minPitch = value;
    }

    public get maxPitch(): number {
        return this._maxPitch;
    }

    public set maxPitch(value: number) {
        this._maxPitch = value;
    }

    public get orbitPitchThreshold(): number {
        return this._orbitPitchThreshold;
    }

    public set orbitPitchThreshold(value: number) {
        this._orbitPitchThreshold = value;
    }

    public get animate(): boolean {
        return this._animate;
    }

    public set animate(value: boolean) {
        this._animate = value;
        this.updateAnimation = false;
    }

    public get updateAnimation(): boolean {
        return this._updateAnimation;
    }

    public set updateAnimation(value: boolean) {
        this._updateAnimation = value;
    }

    public get zoomRequested(): boolean {
        return this._zoomRequested;
    }

    public set zoomRequested(value: boolean) {
        this._zoomRequested = value;
    }
}
