import { Vector3, Camera, PerspectiveCamera, Plane, Matrix4, Box3 } from "three";
import NumberUtils from "gis3d/wf/util/NumberUtils";
import { Bounds } from "../geometry/Bounds";
import { GrahamScanConvexHull } from "../geometry/GrahamScanConvexHull";

export class Frustum {
    private _nearPlane: Array<Vector3>;
    private _farPlane: Array<Vector3>;
    private _projectedFrustumBox: Bounds;
    private _direction: Vector3;
    private _floorPlane: Plane = new Plane(new Vector3(0, 0, 1), 0);

    private bufVector3: Vector3 = new Vector3();
    private bufMatrix4: Matrix4 = new Matrix4();
    public planes: Array<Plane> = [
        new Plane(), //
        new Plane(),
        new Plane(),
        new Plane(),
        new Plane(),
        new Plane(),
    ];

    private projectedVertices = [
        new Vector3(), //
        new Vector3(),
        new Vector3(),
        new Vector3(),
        new Vector3(),
        new Vector3(),
        new Vector3(),
        new Vector3(),
    ];
    private convexHull: GrahamScanConvexHull = new GrahamScanConvexHull();

    constructor() {
        // TL TR BR BL
        this._nearPlane = [new Vector3(), new Vector3(), new Vector3(), new Vector3()];
        this._farPlane = [new Vector3(), new Vector3(), new Vector3(), new Vector3()];
        this._projectedFrustumBox = new Bounds();
        this._direction = new Vector3();
    }

    protected updateFrustumPlanes(m: Matrix4): void {
        const planes = this.planes;
        const me = m.elements;
        const me0 = me[0],
            me1 = me[1],
            me2 = me[2],
            me3 = me[3];
        const me4 = me[4],
            me5 = me[5],
            me6 = me[6],
            me7 = me[7];
        const me8 = me[8],
            me9 = me[9],
            me10 = me[10],
            me11 = me[11];
        const me12 = me[12],
            me13 = me[13],
            me14 = me[14],
            me15 = me[15];

        planes[0].setComponents(me3 - me0, me7 - me4, me11 - me8, me15 - me12).normalize();
        planes[1].setComponents(me3 + me0, me7 + me4, me11 + me8, me15 + me12).normalize();
        planes[2].setComponents(me3 + me1, me7 + me5, me11 + me9, me15 + me13).normalize();
        planes[3].setComponents(me3 - me1, me7 - me5, me11 - me9, me15 - me13).normalize();
        planes[4].setComponents(me3 - me2, me7 - me6, me11 - me10, me15 - me14).normalize();
        planes[5].setComponents(me3 + me2, me7 + me6, me11 + me10, me15 + me14).normalize();
    }

    public update(camera: Camera): void {
        const pCam = camera as PerspectiveCamera;
        const fovTan = 2 * Math.tan(NumberUtils.DEG_2_RAD * (pCam.fov / 2));
        const near = pCam.near;
        const far = pCam.far;
        const hNear = fovTan * near;
        const wNear = hNear * pCam.aspect;
        const hFar = fovTan * far;
        const wFar = hFar * pCam.aspect;

        pCam.updateProjectionMatrix();
        pCam.updateMatrixWorld(false);
        this.updateFrustumPlanes(this.bufMatrix4.multiplyMatrices(pCam.projectionMatrix, pCam.matrixWorldInverse));

        this.nearPlane[0].set(-wNear / 2, hNear / 2, -near);
        this.nearPlane[1].set(wNear / 2, hNear / 2, -near);
        this.nearPlane[2].set(wNear / 2, -hNear / 2, -near);
        this.nearPlane[3].set(-wNear / 2, -hNear / 2, -near);

        this.farPlane[0].set(-wFar / 2, hFar / 2, -far);
        this.farPlane[1].set(wFar / 2, hFar / 2, -far);
        this.farPlane[2].set(wFar / 2, -hFar / 2, -far);
        this.farPlane[3].set(-wFar / 2, -hFar / 2, -far);

        let idx = 0;
        for (let p of this.nearPlane) {
            p.applyMatrix4(pCam.matrixWorld);
            this.floorPlane.projectPoint(p, this.projectedVertices[idx++]);
        }
        for (let p of this.farPlane) {
            p.applyMatrix4(pCam.matrixWorld);
            this.floorPlane.projectPoint(p, this.projectedVertices[idx++]);
        }

        // camera direction
        camera.getWorldDirection(this.direction);
    }

    public cameraAngle(): number {
        const a = Math.atan2(this.direction.x, this.direction.y);
        return NumberUtils.RAD_2_DEG * -a;
    }

    public get direction(): Vector3 {
        return this._direction;
    }

    public get projectedFrustumBox(): Bounds {
        this._projectedFrustumBox.setFromPoints(this.projectedVertices);
        return this._projectedFrustumBox;
    }

    public get nearPlane(): Array<Vector3> {
        return this._nearPlane;
    }

    public get farPlane(): Array<Vector3> {
        return this._farPlane;
    }

    public get floorPlane(): Plane {
        return this._floorPlane;
    }

    public set floorPlane(value: Plane) {
        this._floorPlane = value;
    }

    public get projectedFrustum(): Array<Vector3> {
        return this.convexHull.hull(this.projectedVertices);
    }

    public containsPoint(point: Vector3): boolean {
        const planes = this.planes;
        for (var i = 0; i < 6; i++) {
            if (planes[i].distanceToPoint(point) < 0) {
                return false;
            }
        }
        return true;
    }

    public intersectsBox(box: Box3): boolean {
        const planes = this.planes;

        for (let i = 0; i < 6; i++) {
            const plane = planes[i];

            // corner at max distance

            this.bufVector3.x = plane.normal.x > 0 ? box.max.x : box.min.x;
            this.bufVector3.y = plane.normal.y > 0 ? box.max.y : box.min.y;
            this.bufVector3.z = plane.normal.z > 0 ? box.max.z : box.min.z;

            if (plane.distanceToPoint(this.bufVector3) < 0) {
                return false;
            }
        }

        return true;
    }
}
