import { SceneDefinition } from "../SceneDefinition";
import { DefaultSceneGeoreference } from "./DefaultSceneGeoreference";
import { DefaultSceneSettings } from "./DefaultSceneSettings";
import { DefaultSceneControls } from "./DefaultSceneControls";
import { SceneDefinitionSettings } from "../SceneDefinitionSettings";
import { SceneDefinitionControls } from "../SceneDefinitionControls";
import { SceneDefinitionGeoreference } from "../SceneDefinitionGeoreference";
import { SceneDefinitionLayer } from "../SceneDefinitionLayer";
import { SceneDefinitionDataSource } from "../SceneDefinitionDataSource";

export class DefaultSceneDefinition implements SceneDefinition {
    public title: string = "";
    public settings: SceneDefinitionSettings = new DefaultSceneSettings();
    public controls: SceneDefinitionControls = new DefaultSceneControls();
    public georeference: SceneDefinitionGeoreference = new DefaultSceneGeoreference();
    public layers: SceneDefinitionLayer[] = [];
    public dataSources: SceneDefinitionDataSource[] = [];
}
