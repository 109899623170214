import I18N from "gis3d/wf/i18n/I18N";
import { AreaMeasure } from "../../measure/AreaMeasure";
import { MeasureType } from "../../measure/MeasureType";
import { MeasureObject } from "../objects/MeasureObject";
import { Scene } from "../scene/Scene";
import { MultiStepMeasurementTool } from "./MultiStepMeasurementTool";
import { MeasurePair } from "../objects/MeasurePair";
import { MeasurementUtils } from "../../measure/MeasurementUtils";
import { Vector3 } from "three";
export class AreaTool extends MultiStepMeasurementTool<AreaMeasure> {
    public constructor(scene?: Scene) {
        super(scene);
        this.message = I18N.i("cityvu.core.measurementTool.areaTool");
        this.closeLoop = true;
        this.minCoordsNumber = 3;
        this.showLabel = true;
    }

    protected configureMeasureObject(mobj: MeasureObject): MeasureObject {
        mobj.markerColor = 0x0000ff;
        mobj.edgeColor = 0x0000ff;
        return mobj;
    }

    public getMeasureType(): MeasureType {
        return MeasureType.AREA;
    }

    protected computeMeasurement(pair: MeasurePair<AreaMeasure>): void {
        super.computeMeasurement(pair);
        let area = 0;
        const m = pair.measure;
        if (m.valid === true) {
            const m = pair.measure;
            // This works on any projected CRS
            // TODO Support on geographic datum could be achieved but postponed for the moment
            const plane = MeasurementUtils.planeFromPointsLinearLeastSquares(m.coords);
            if (plane != null) {
                const points = new Array<Vector3>();
                m.coords.forEach(c => {
                    points.push(c.clone());
                });
                MeasurementUtils.projectPointsOnPlane(points, plane);
                pair.measure.adjustedCoords = points;
                area = MeasurementUtils.area(points);
            }
        }
        m.value = area;
    }
}
