/**
 *
 * Based on "A Practical Analytic Model for Daylight"
 * aka The Preetham Model, the de facto standard analytic skydome model
 * http://www.cs.utah.edu/~shirley/papers/sunsky/sunsky.pdf
 *
 * First implemented by Simon Wallner
 * http://www.simonwallner.at/projects/atmospheric-scattering
 *
 * Improved by Martin Upitis
 * http://blenderartists.org/forum/showthread.php?245954-preethams-sky-impementation-HDR
 *
 * Three.js integration by zz85 http://twitter.com/blurspline
 *
 * Cityvu adaptation by 3DGIS
 *
 */
import { BackSide, BoxBufferGeometry, Mesh, ShaderMaterial, Vector3 } from "three";
import fragmentShader from "./Sky.fs";
import vertexShader from "./Sky.vs";

export class Sky extends Mesh {
    constructor() {
        super();
        this.geometry = new BoxBufferGeometry(1, 1, 1);
        this.material = new ShaderMaterial({
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
            uniforms: {
                luminance: { value: 1 },
                turbidity: { value: 2 },
                rayleigh: { value: 1 },
                mieCoefficient: { value: 0.0003 },
                mieDirectionalG: { value: 0.8 },
                sunPosition: { value: new Vector3() },
            },
            side: BackSide,
        });
    }

    public uniforms(): any {
        return (<ShaderMaterial>this.material).uniforms;
    }
}
