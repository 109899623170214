import { SignalingAction } from "./SignalingAction";

export class SignalingMessage {
    public static DEFAULT_APP = "wf";
    public action : SignalingAction = SignalingAction.REGISTER;
    public userId : any;
    public application : string = SignalingMessage.DEFAULT_APP;
    public windowId? : any;
    public targetUserId? : any;
    public targetApplication? : any;
    public targetWindowId? : any;
    public token? : any;

    public payload? : any;

    public toJsonString() : string {
        return JSON.stringify({
            action : this.action.toString(),
            application : this.application,
            userId : this.userId,
            windowId : this.windowId,
            targetUserId : this.targetUserId,
            targetApplication : this.targetApplication,
            targetWindowId : this.targetWindowId,
            payload : this.payload,
            token : this.token
        });
    }

    public static fromJsonString(dataString:any) : SignalingMessage {
        const data = JSON.parse(dataString);
        const message = new SignalingMessage();
        message.action = data.action;
        message.application = data.application;
        message.userId = data.userId;
        message.token = data.token;
        if (data.windowId != null) {
            message.windowId = data.windowId;
        }
        if (data.payload != null) {
            message.payload = data.payload;
        }

        return message;
    }
}