import { Vector3, Matrix4 } from "three";

export class AffineTransformation {
    private _matrix!: Matrix4;
    protected matrixInverse!: Matrix4;

    public constructor(matrix?: Matrix4, matrixInverse?: Matrix4) {
        this.matrixInverse = matrixInverse ? matrixInverse : new Matrix4();
        this.matrix = matrix ? matrix : new Matrix4();
    }

    public transform(input: Vector3, output: Vector3) {
        output.copy(input).applyMatrix4(this.matrix);
    }

    public untransform(input: Vector3, output: Vector3) {
        output.copy(input).applyMatrix4(this.matrixInverse);
    }

    public get matrix(): Matrix4 {
        return this._matrix;
    }

    public set matrix(value: Matrix4) {
        this._matrix = value;
        this.matrixInverse.getInverse(this.matrix, true);
    }
}