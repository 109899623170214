import { Vector3, Vector2, Intersection, Plane } from "three";
import { AnimatedControls } from "./AnimatedControls";
import { AppEvent } from "../../event/AppEvent";
import { DragData } from "../../event/DragData";
import { InputManagerEvent } from "../../event/InputManager";

import ThreeUtils from "../ThreeUtils";
import MouseUtils from "gis3d/wf/util/MouseUtils";
import { WheelData } from "../../event/WheelData";
import { Picker } from "../scene/picker/Picker";
import { PickRequest } from "../scene/picker/PickRequest";
import { Scene } from "../scene/Scene";
import { SortedPickResults } from "../scene/picker/SortedPickResults";

export class EarthControls extends AnimatedControls {
    private _target!: Vector3 | null;

    public movementSpeed: number = 1.8;
    public rotationSpeed: number = 0.4;
    public walkingElevationLock: boolean = false;
    public frictionAmount: number = 12.0;
    public wheelMultiplier: number = 5.0;
    public minRadius: number = -1;
    public maxRadius: number = -1;
    public rayLimit: number = 50.0;
    public zoomDistance: number = 5.0;

    protected picker: Picker;
    protected pickRequest: PickRequest;
    protected estimationPlane: Plane = new Plane();
    protected lastRayDistanceFromPlane: number = 0;

    protected panDelta: Vector3 = new Vector3(); // XYZ
    protected rotationDelta: Vector3 = new Vector3(); // as yaw, pitch, roll
    protected worldDelta: Vector3 = new Vector3();
    protected userInput: boolean = false;
    protected buffer: Vector3 = new Vector3();
    protected poseWasAnimated: boolean = false;

    public constructor(scene?: Scene) {
        super(scene);
        this.picker = new Picker();
        this.pickRequest = new PickRequest();
    }

    public onSceneChange(scene: Scene): void {
        this.pickRequest.scene = scene;
        this.poseWasAnimated = this.scene.pose.animate;
        this.scene.pose.animate = false;
    }

    public enable(): void {
        this.stop();
        this.attachKeyboardEvents();
        this.attachMouseEvents();
        if (this.scene != null) {
            this.onSceneChange(this.scene);
        }
    }

    public disable(): void {
        this.removeEventListeners();
        if (this.scene != null) {
            this.scene.pose.animate = this.poseWasAnimated;
        }
    }

    protected attachMouseEvents() {
        this.addEventListener(this.drag, InputManagerEvent.DRAG);
        this.addEventListener(this.drop, InputManagerEvent.DROP);
        this.addEventListener(this.wheel, InputManagerEvent.MOUSEWHEEL);
        this.addEventListener(this.doubleClick, InputManagerEvent.DOUBLECLICK);
    }

    protected attachKeyboardEvents() {
        this.addEventListener(this.keyDown, InputManagerEvent.KEYDOWN);
        this.addEventListener(this.keyUp, InputManagerEvent.KEYUP);
    }

    public stop(): void {
        super.stop();
        this.rotationDelta.set(0, 0, 0);
        this.panDelta.set(0, 0, 0);
        this.worldDelta.set(0, 0, 0);
    }

    public update(delta: number): void {
        if (this.scene == null) {
            return;
        }

        // remove automations if user input has been detected
        if (this.userInput) {
            this.clearTweens();
            this.userInput = false;
        }

        const pitchStep = this.rotationDelta.y * delta;
        const yawStep = this.rotationDelta.x * delta;
        // rotation
        if (this.target != null) {
            // limit to minDistance
            const radius = this.scene.pose.position.distanceTo(this.target);
            if (this.minRadius != -1 && this.panDelta.y > 0 && radius < this.minRadius) {
                this.panDelta.y = 0;
            }
            if (this.maxRadius != -1 && this.panDelta.y < 0 && radius > this.maxRadius) {
                this.panDelta.y = 0;
            }

            // rotate on the sphere
            if (pitchStep != 0 || yawStep != 0) {
                this.scene.pose.orbitSphere(this.target, pitchStep, yawStep);
            }
        } else {
            // rotate pose
            this.scene.pose.yaw -= yawStep;
            this.scene.pose.pitch -= pitchStep;
        }

        // pan
        this.buffer.copy(this.panDelta).multiplyScalar(delta);
        this.scene.pose.pan3(this.buffer);

        // translate
        this.buffer.copy(this.worldDelta).multiplyScalar(delta);
        this.scene.pose.translate(this.buffer);

        // apply friction
        const friction = Math.max(1 - this.frictionAmount * delta, 0);
        this.panDelta.multiplyScalar(friction);
        this.rotationDelta.multiplyScalar(friction);
        this.worldDelta.multiplyScalar(friction);
    }

    protected calculatePivot(mouse: Vector2): Vector3 | null {
        const w = this.scene.size.w!;
        const h = this.scene.size.h!;
        this.pickRequest.normalizedCoords = ThreeUtils.normalize2dCoordinates(new Vector2(mouse.x, mouse.y), w, h);
        const results = this.picker.pick(this.pickRequest);
        const hit = new SortedPickResults(results).first();
        if (hit != null) {
            const hitPoint = hit.intersection.point;
            this.estimationPlane.setFromNormalAndCoplanarPoint(this.scene.camera.up, hitPoint);
            return hitPoint.clone();
        }
        return null;
    }

    protected doubleClick(appEvent: AppEvent): void {
        const mouse = appEvent.value as Vector2;
        const clickPosition = this.calculatePivot(mouse);
        if (clickPosition != null) {
            this.scene.pose.zoomTo(clickPosition, this.zoomDistance);
        }
    }

    protected wheel(appEvent: AppEvent): void {
        const data = appEvent.value as WheelData;
        // move forward or backward
        this.panDelta.y += data.delta * this.movementSpeed * this.wheelMultiplier;
    }

    protected drag(appEvent: AppEvent): void {
        this.userInput = true;
        const dragData = appEvent.value as DragData;

        if (dragData.isStart) {
            this.target = this.calculatePivot(dragData.start);
        } else {
            if (this.target != null) {
                if (MouseUtils.isLeft(dragData.buttons)) {
                    const w = this.scene.size.w!;
                    const h = this.scene.size.h!;
                    const ray = ThreeUtils.ray(dragData.end, this.scene.camera, w, h);

                    let d = ray.distanceToPlane(this.estimationPlane);
                    if (d == null) {
                        d = this.lastRayDistanceFromPlane;
                    }

                    // WARNING: do not use that ray anymore as direction gets messed to avoid object creation
                    const limit = this.rayLimit;
                    const direction = d > 0 ? -1 : 1;
                    if (d > limit) {
                        d = limit;
                    } else if (d < -limit) {
                        d = -limit;
                    }
                    this.lastRayDistanceFromPlane = d;
                    this.buffer.addVectors(this.scene.pose.position, ray.direction.multiplyScalar(d));
                    this.worldDelta.copy(
                        this.buffer
                            .sub(this.target)
                            .multiplyScalar(direction * this.movementSpeed)
                            .setZ(0),
                    );
                } else if (MouseUtils.isRight(dragData.buttons)) {
                    // rotate around pivoted point
                    // x yaw, y pitch, z roll
                    this.rotationDelta.x = dragData.delta.x * this.rotationSpeed;
                    this.rotationDelta.y = dragData.delta.y * this.rotationSpeed;
                    this.rotationDelta.z = 0;
                }
            }

            if (MouseUtils.isMiddle(dragData.buttons)) {
                this.worldDelta.z = dragData.delta.y * this.movementSpeed;
                this.panDelta.x = dragData.delta.x * this.movementSpeed;
            }
        }
    }

    protected drop(appEvent: AppEvent): void {
        this.userInput = true;
        this.target = null;
    }

    protected keyUp(appEvent: AppEvent): void {
        // this.userInput = true;
        // const event = (appEvent as AppEvent<KeyboardEvent>).value!;
    }

    protected keyDown(appEvent: AppEvent): void {
        // this.userInput = true;
        // const event = (appEvent as AppEvent<KeyboardEvent>).value!;
    }

    public get target(): Vector3 | null {
        return this._target;
    }

    public set target(value: Vector3 | null) {
        this._target = value;
    }
}
