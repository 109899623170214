import { CanvasUiComponent } from "gis3d/wf/ui/CanvasUiComponent";
import { Features } from "../Features";

export interface StatsWidgetOptions {
    title: string;
    fgColor: string;
    bgColor: string;
}

export class StatsWidget extends CanvasUiComponent {
    protected w: number = 80 * Features.pixelRatio;
    protected h: number = 24 * Features.pixelRatio;
    protected graphW: number = 78 * Features.pixelRatio;
    protected graphH: number = 12 * Features.pixelRatio;
    protected graphX: number = 1 * Features.pixelRatio;
    protected graphY: number = 11 * Features.pixelRatio;
    protected textX: number = 1 * Features.pixelRatio;
    protected textY: number = 1 * Features.pixelRatio;

    protected context! : CanvasRenderingContext2D;

    constructor(readonly opts: StatsWidgetOptions) {
        super();
    }

    protected buildCanvas(): HTMLCanvasElement {
        const canvas = super.buildCanvas();
        canvas.width = this.w;
        canvas.height = this.h;
        canvas.style.width = this.w + "px";
        canvas.style.height = this.h + "px";

        this.context = canvas.getContext("2d")!;
        this.context.font = "bold " + ( 9 * Features.pixelRatio ) + "px -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue',sans-serif;";
        this.context.textBaseline = "top";

        this.context.fillStyle = this.opts.bgColor;
        this.context.fillRect( 0, 0, this.w, this.h );

        this.context.fillStyle = this.opts.fgColor;
        this.context.fillText( this.opts.title, this.textX, this.textY );
        this.context.fillRect( this.graphX, this.graphY, this.graphW, this.graphH );

        this.context.fillStyle = this.opts.bgColor;
        this.context.globalAlpha = 0.9;
        this.context.fillRect( this.graphX, this.graphY, this.graphW, this.graphH );

        return canvas;
    }

    public update(data: number, max: number): void {
        // cap the data to the max
        const drawValue = Math.min(data, max);

        this.context.fillStyle = this.opts.bgColor;
        this.context.globalAlpha = 1;
        this.context.fillRect( 0, 0, this.w, this.graphY );
        this.context.fillStyle = this.opts.fgColor;
        this.context.fillText( drawValue + " " + this.opts.title, this.textX, this.textY);

        const PR = Features.pixelRatio;
        // redraw canvas moved by a pixel to the right
        this.context.drawImage(this.canvas, this.graphX + PR, this.graphY, this.graphW - PR, this.graphH, this.graphX, this.graphY, this.graphW - PR, this.graphH );
        // fill the last pixel generating the graph
        this.context.fillRect( this.graphX + this.graphW - PR, this.graphY, PR, this.graphH );
        this.context.fillStyle = this.opts.bgColor;
        // draw the maximum value
        this.context.globalAlpha = 0.8;
        this.context.fillRect( this.graphX + this.graphW - PR, this.graphY, PR, Math.round( ( 1 - ( drawValue / max ) ) * this.graphH ) );


    }
}
