import { PickResults } from "./PickResults";
import { PickRequest } from "./PickRequest";
import { Raycaster, Intersection, PerspectiveCamera } from "three";
import { Layer3dType } from "../Layer3dType";
import { PointCloudOctree } from "../../cloud/potree/point-cloud-octree";
import { Scene } from "../Scene";

export interface ExtendedIntersection extends Intersection {
    data?: { [property: string]: any };
}

export class Picker {
    private _raycaster: Raycaster;
    private _recursive: boolean = false;

    constructor() {
        this._raycaster = new Raycaster();
    }

    protected configureRaycaster(request: PickRequest) {
        if (request.origin != null && request.direction != null) {
            this.raycaster.set(request.origin, request.direction);
        } else if (request.normalizedCoords != null) {
            this.raycaster.setFromCamera(request.normalizedCoords, request.scene.camera);
        }
        this.raycaster.far = request.far;
        this.raycaster.near = request.near;
        this.raycaster.linePrecision = request.linePrecision;
    }

    public pick(request: PickRequest): PickResults {
        const results = new PickResults();
        this.configureRaycaster(request);

        request.scene.layers.forEach(layer => {
            if (layer.canReceiveCast && (request.layers === null || request.layers.indexOf(layer.id) !== -1)) {
                const objectList = layer.getPickableObjects(request);
                const intersections: Array<ExtendedIntersection> = [];
                // TODO implement custom picking function on layer3d with request, objectList and intersections as parameters
                if (layer.type === Layer3dType.POINTCLOUD) {
                    let clouds = objectList.filter(o => o instanceof PointCloudOctree === true);

                    const camera = request.scene.camera as PerspectiveCamera;

                    for (let i = 0; i < clouds.length; i++) {
                        const pointcloud = clouds[i] as PointCloudOctree;
                        const point = pointcloud.pick(request.scene.engine!.renderer, camera, this.raycaster.ray);

                        if (point != null) {
                            const distance = camera.position.distanceTo(point.position!);
                            const intersection = {
                                object: pointcloud,
                                point: point.position!,
                                distance: distance,
                                data: {},
                            } as ExtendedIntersection;
                            if (point.intensity != null) {
                                intersection.data!.intensity = point.intensity;
                            }
                            if (point.color != null) {
                                intersection.data!.rgb = [point.color[0], point.color[1], point.color[2]];
                            }
                            intersections.push(intersection);
                        }
                    }
                } else {
                    this.raycaster.intersectObjects(objectList, this.recursive, intersections);
                }
                layer.resultsFromIntersections(request, intersections, results);
            }
        });

        return results;
    }

    /*
    FROM OLD SCENE

    public intersectableObjects(results?: Array<Object3D>, userFilter?: (o: Object3D) => boolean): Array<Object3D> {
        const target = results != null ? results : [];
        this.scene.traverseVisible(object => {
            if (object instanceof Points === false && object.parent instanceof PointCloudOctree === false) {
                target.push(object);
            }
        });
        return target;
    }
    */

    public get raycaster(): Raycaster {
        return this._raycaster;
    }

    public set raycaster(value: Raycaster) {
        this._raycaster = value;
    }

    public get recursive(): boolean {
        return this._recursive;
    }

    public set recursive(value: boolean) {
        this._recursive = value;
    }
}
