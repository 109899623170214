import { Measure, ConcreteDataMeasure } from "./Measure";
import { MeasureType } from "./MeasureType";
import { WktBuilder } from "../geometry/WktBuilder";

export class MeasureUtils {
    public static prepareToShare(measure: Measure): object {
        let data: any = {
            id: measure.id,
            type: measure.type.toString(),
            coords: measure.coords,
            adjustedCoords: measure.adjustedCoords,
            value: measure.value,
            valid: measure.valid,
        };

        if (measure instanceof ConcreteDataMeasure) {
            data.data = measure.data;
        }

        const coordsToUse = data.adjustedCoords != null ? data.adjustedCoords : data.coords;
        switch (measure.type) {
            case MeasureType.ANGLE:
                data.wkt = WktBuilder.linestring(coordsToUse);
                break;
            case MeasureType.AREA:
                data.wkt = WktBuilder.polygon(coordsToUse);
                break;
            case MeasureType.LENGTH:
                data.wkt = WktBuilder.linestring(coordsToUse);
                break;
            case MeasureType.INFO:
            default:
                data.wkt = WktBuilder.point(coordsToUse);
                break;
        }

        return data;
    }
}
