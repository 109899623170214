import { PointCloudOctree } from "gis3d/cityvu/core/three/cloud/potree";
import { Tile } from "gis3d/cityvu/core/geometry/Tile";

export enum PointCloudTileState {
    INITIALIZED,
    WAITING,
    LOADING,
    VISIBLE,
    HIDDEN,
    DISPOSED,
    ERROR,
}

export class PointCloudTile extends Tile {
    private _state: PointCloudTileState = PointCloudTileState.INITIALIZED;
    private _cloud?: PointCloudOctree;

    public get cloud(): PointCloudOctree | undefined {
        return this._cloud;
    }

    public set cloud(value: PointCloudOctree | undefined) {
        this._cloud = value;
    }

    public get state(): PointCloudTileState {
        return this._state;
    }

    public set state(value: PointCloudTileState) {
        this._state = value;
    }
}
