import { InputManager } from "../../event/InputManager";
import { MeasuresBroker } from "../../measure/MeasuresBroker";
import { Scene } from "../scene/Scene";
import { EventedMeasurementTool } from "./EventedMeasurementTool";
import { MeasurementTool } from "./MeasurementTool";
import { Measure3dScene } from "../objects/Measure3dScene";
import { Measure } from "../../measure/Measure";
import { MeasureType } from "../../measure/MeasureType";
import { MeasureUtils } from "../../measure/MeasureUtils";
import DomUtils from "gis3d/wf/util/DomUtils";

export class MeasurementToolsManager {
    protected toolSet: Map<string, MeasurementTool>;
    protected scene!: Scene;
    protected _selected: MeasurementTool | null = null;
    protected _inputManager!: InputManager;
    protected _measuresBroker!: MeasuresBroker;
    protected _enabled: boolean = true;
    protected _measures3dScene: Measure3dScene = new Measure3dScene();
    //
    public onSelect = (current: MeasurementTool, old: MeasurementTool | null): void => { };
    public onDeselect = (old: MeasurementTool): void => { };
    public onSend = (measure: Measure): void => { };

    public constructor() {
        this.toolSet = new Map<string, MeasurementTool>();
    }

    public add(key: string, tool: MeasurementTool): void {
        tool.broker = this.measuresBroker;
        tool.measures3dScene = this.measures3dScene;
        this.toolSet.set(key, tool);
    }

    public get(key: string): MeasurementTool | undefined {
        return this.toolSet.get(key);
    }

    public remove(key: string): boolean {
        return this.toolSet.delete(key);
    }

    public select(key: string): void {
        const oldTool = this.selected;
        const newTool = this.get(key);

        this.unselect();

        if (newTool != null) {
            this._selected = newTool;
            if (this.enabled) {
                this.enableWithDelegation(newTool);
            }
            this.onSelect(this.selected!, oldTool);
        } else {
            console.error("Cannot find tool:", key);
        }
    }

    public unselect(): void {
        const oldTool = this.selected;

        if (oldTool != null) {
            this.disableWithDelegation(oldTool);
            this.onDeselect(oldTool);
            this._selected = null;
        }
    }

    public get selected(): MeasurementTool | null {
        return this._selected;
    }

    public get enabled(): boolean {
        return this._enabled;
    }

    public set enabled(value: boolean) {
        this._enabled = value;

        if (this.selected != null) {
            if (value) {
                this.enableWithDelegation(this.selected);
            } else {
                this.disableWithDelegation(this.selected);
            }
        }
    }

    protected enableWithDelegation(m: MeasurementTool): void {
        m.enable();
        if (m instanceof EventedMeasurementTool) {
            this.inputManager.addDelegate(m as EventedMeasurementTool);
        }
    }

    protected disableWithDelegation(m: MeasurementTool): void {
        m.disable();
        if (m instanceof EventedMeasurementTool) {
            this.inputManager.removeDelegate(m as EventedMeasurementTool);
        }
    }

    public onSceneChange(scene: Scene): void {
        this.unselect();
        this.enabled = scene.enableMeasurementTools;
        this.scene = scene;
        this.toolSet.forEach(tool => {
            tool.scene = scene;
        });
    }

    public update(delta: number): void {
        if (this.scene != null) {
            this.measures3dScene.update(this.scene);
        }
    }

    public get inputManager(): InputManager {
        return this._inputManager;
    }

    public set inputManager(value: InputManager) {
        this._inputManager = value;
    }

    public get measuresBroker(): MeasuresBroker {
        return this._measuresBroker;
    }

    public set measuresBroker(value: MeasuresBroker) {
        this._measuresBroker = value;
        // setup callbacks
        value.onAdd = (m) => this.onMeasureAdd(m);
        value.onClear = (t?) => this.onMeasureClear(t);
        value.onRemove = (m) => this.onMeasureRemove(m);
        value.onHighlight = (m) => this.onMeasureHighlight(m);
        value.onDehighlight = (m) => this.onMeasureDehighlight(m);
        value.onCopy = (m) => this.onMeasureCopy(m);
        value.onSend = (m) => this.onSend(m);
    }

    public get measures3dScene(): Measure3dScene {
        return this._measures3dScene;
    }

    public set measures3dScene(value: Measure3dScene) {
        this._measures3dScene = value;
    }

    public set enableSend(value: boolean) {
        this.measuresBroker.measuresList.enableSend = value;
    }

    public set enableCopy(value: boolean) {
        this.measuresBroker.measuresList.enableCopy = value;
    }

    protected onMeasureCopy(m: Measure): void {
        const data = MeasureUtils.prepareToShare(m);
        DomUtils.copyToClipboard(JSON.stringify(data));
    }

    protected onMeasureHighlight(m: Measure): void {
        const tool = this.firstToolOfType(m.type);
        if (tool != null) {
            tool.highlightMeasure(m);
        }
    }


    protected onMeasureDehighlight(m: Measure): void {
        const tool = this.firstToolOfType(m.type);
        if (tool != null) {
            tool.dehighlightMeasure(m);
        }
    }


    protected onMeasureAdd(m: Measure): void {
    }

    protected onMeasureRemove(m: Measure): void {
        const tool = this.firstToolOfType(m.type);
        if (tool != null) {
            tool.removeMeasure(m);
        }
    }

    protected onMeasureClear(type?: MeasureType): void {
        if (type != null) {
            const tool = this.firstToolOfType(type);
            if (tool != null) {
                tool.clearMeasures();
            }
        } else {
            for (const tool of this.toolSet.values()) {
                tool.clearMeasures();
            }
        }
    }

    protected firstToolOfType(type: MeasureType): MeasurementTool | null {
        for (const tool of this.toolSet.values()) {
            // find first   
            if (tool.getMeasureType() === type) {
                return tool;
            }
        }
        return null;
    }
}
