import { BasicScene } from "gis3d/cityvu/core/three/scene/BasicScene";
import { Experiment } from "./Experiment";
import { Scene } from "../core/three/scene/Scene";

export class NoiseContourScene extends BasicScene {
    
    constructor() {
        super();
    }
}

export default class NoiseContourExperiment implements Experiment {
    public scene : Scene = new NoiseContourScene();
}