import { ConcreteDataMeasure } from "./Measure";
import { MeasureType } from "./MeasureType";

export class InfoMeasure extends ConcreteDataMeasure<any> {
    constructor() {
        super();
        this.type = MeasureType.INFO;
        this.valid = true;
    }
}
