import dom from "gis3d/wf/util/DomUtils";
import ui from "gis3d/wf/ui/style/UiStyle";
import on from "gis3d/wf/core/On";
import run from "gis3d/wf/util/RuntimeUtils";

import { UiContainer } from "gis3d/wf/ui/UiContainer";
import { Box } from "gis3d/wf/ui/geom/Box";
import { Size } from "gis3d/wf/ui/geom/Size";

export class Floating extends UiContainer {
    private _box: Box = new Box(10, 10, 200, 200);

    private _draggable: boolean = true;
    private _draggableElement: HTMLElement | null = null;
    protected dragging: boolean = false;
    protected dragHandlers: Array<any> = [];
    protected dragLastPosition: Array<number> = [0, 0];
    protected position: Box = new Box();

    public constructor() {
        super();
        this.maxChildrenNumber = 1;
    }

    public prepareBuildOptions(): void {
        this.domElementOptions = {
            classes: [
                ui.p("Floating")
            ]
        };

        if (this.draggable) {
            this.domElementOptions.classes!.push(ui.draggable);
        }
    }

    protected attachEventListeners(): void {
        super.attachEventListeners();

        if (this.draggable) {
            let dragItem: HTMLElement = this.draggableElement;
            if (dragItem == null) {
                dragItem = this.domNode!;
            }
            on.listen(dragItem, "mousedown", run.bind(this, this.onDragStart));
        }
    }

    protected onDragStart(ev: MouseEvent): any {
        if (this.draggable) {
            this.dragging = true;
            this.dragLastPosition[0] = ev.pageX;
            this.dragLastPosition[1] = ev.pageY;
            this.clearDragHandlers();
            this.dragHandlers.push(on.listen(dom.win(), "mousemove", run.bind(this, this.onDragMove)));
            this.dragHandlers.push(on.listen(dom.win(), "mouseup", run.bind(this, this.onDragEnd)));
        }
    }

    protected onDragMove(downEvent: MouseEvent): any {
        let x = downEvent.pageX - this.dragLastPosition[0];
        let y = downEvent.pageY - this.dragLastPosition[1];
        this.dragLastPosition[0] = downEvent.pageX;
        this.dragLastPosition[1] = downEvent.pageY;

        let parentBox = dom.marginBox(this.parent!.domNode!);
        const xMin = -this.box.w! + 80;
        const xMax = parentBox.w! - 80;
        const yMax = parentBox.h! - 60;

        this.box.x! += x;
        if (this.box.x! < xMin) {
            this.box.x = xMin;
        } else if (this.box.x! > xMax) {
            this.box.x = xMax;
        }

        this.box.y! += y;
        if (this.box.y! < 0) {
            this.box.y = 0;
        } else if (this.box.y! > yMax) {
            this.box.y = yMax;
        }
        this.resize();

        // block bubbling
        downEvent.stopPropagation();
        downEvent.preventDefault();
    }

    protected onDragEnd(ev: MouseEvent): any {
        this.dragging = false;
        this.clearDragHandlers();
    }

    protected clearDragHandlers(): void {
        let removeHandler = null;
        while (removeHandler = this.dragHandlers.pop()) {
            removeHandler();
        }
    }

    public startup(): void {
        super.startup();
    }

    public resize(box: Box | null = null): Size | null {
        dom.setBox(this.domNode!, this.box);
        this.layout();
        return dom.marginBox(this.domNode!);
    }

    public get box(): Box {
        return this._box;
    }

    public set box(b: Box) {
        this._box = b;
    }

    public get draggable(): boolean {
        return this._draggable;
    }

    public set draggable(draggable: boolean) {
        this._draggable = draggable;
    }

    public get draggableElement(): HTMLElement {
        return this._draggableElement!;
    }

    public set draggableElement(draggableElement: HTMLElement) {
        this._draggableElement = draggableElement;
    }


}