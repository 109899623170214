import { Evented } from "gis3d/wf/core/Evented";

export default class Topic {
    private static pubSubHub: Evented = new Evented();

    public static subscribe(topic: string, listener: Function): Function {
        return Topic.pubSubHub.listen.apply(Topic.pubSubHub, [topic, listener]);
    }

    public static publish(topic: string, event: any = null, ...args: any[]): boolean {
        return Topic.pubSubHub.emit.apply(Topic.pubSubHub, [topic, event, ...args]);
    }
}
