import ui from "gis3d/wf/ui/style/UiStyle";
import dom from "gis3d/wf/util/DomUtils";
import I18N from "gis3d/wf/i18n/I18N";
import fa from "scss/font-awesome.scss";
import On from "gis3d/wf/core/On";

import { Box } from "gis3d/wf/ui/geom/Box";
import { Size } from "gis3d/wf/ui/geom/Size";
import { FloatingCard } from "gis3d/wf/ui/widget/FloatingCard";
import { MeasuresBroker } from "../core/measure/MeasuresBroker";
import { MeasureType } from "../core/measure/MeasureType";
import { Measure } from "../core/measure/Measure";
import ThreeUtils from "../core/three/ThreeUtils";
import { MeasureDecorator } from "./MeasureDecorator";

export class MeasureList extends FloatingCard {
    private _broker?: MeasuresBroker;
    private decorator: MeasureDecorator;
    private _enableSend: boolean = false;
    private _enableCopy: boolean = false;
    constructor() {
        super();
        this.decorator = new MeasureDecorator();
    }

    public prepareBuildOptions(): void {
        super.prepareBuildOptions();
        this.box.w = 320;
        this.box.h = null;
        this.box.minH = 200;
        this.box.maxH = 400;
        this.title = I18N.i("cityvu.gui.measuresList.title");
        this.domElementOptions.classes!.push(ui.p("CityvuMeasuresList"));
    }

    public resize(box: Box | null = null): Size | null {
        if (box != null && this.dragLastPosition[0] == 0 && this.dragLastPosition[1] == 0) {
            let bW = 0;
            let bH = 0;
            if (this.box.h == null || this.box.w == null) {
                const computedBox = dom.marginBox(this.domNode!);
                bW = computedBox.w!;
                bH = computedBox.h!;
            } else {
                bW = this.box.w!;
                bH = this.box.h!;
            }
            this.box.x = box.w! - bW - 20;
            this.box.y = box.h! - bH - 20;
        }
        return super.resize(box);
    }

    public render(): Promise<void> | void {
        dom.empty(this.body);

        Object.values(MeasureType).forEach(k => {
            this.renderMeasures(k);
        });
        this.resize(dom.marginBox(this.domNode!.parentElement!));
    }

    protected renderMeasures(type: MeasureType): void {
        const measures = this.broker.collector.get(type);
        if (measures.length > 0) {
            const lowerType = type.toLowerCase();
            const title = I18N.i("cityvu.gui.measuresList." + lowerType);
            const container = dom.el("div", {
                classes: ["measures", "measures-" + lowerType],
                parent: this.body,
            });
            // title
            dom.el("div", {
                classes: ["measures-title"],
                html: title,
                parent: container,
            });
            // list
            const group = dom.el("ul", {
                classes: [ui.ListGroup.group],
                parent: container,
            });
            measures.forEach(m => {
                const item = this.renderMeasure(m, group);
                On.listen(item, "mouseenter", () => {
                    this.broker.highlight(m);
                });
                On.listen(item, "mouseleave", () => {
                    this.broker.dehighlight(m);
                });
            });
        }
    }

    protected renderMeasure(m: Measure, parent: HTMLElement): HTMLElement {
        const item = dom.el("li", {
            classes: [ui.ListGroup.item, "measure"],
        });
        // value
        if (m.value != null) {
            dom.el("div", {
                classes: ["measure-value"],
                html: [this.decorator.value(m), this.decorator.unit(m)].join(" "),
                parent: item,
            });
        }
        // data
        this.decorator.dataNode(m, item);
        // coords (already projected)
        if (m.coords != null) {
            const coordsDom = dom.el("div", {
                classes: ["measure-coordinates", ui.Display.d(ui.Display.flex)],
                parent: item,
            });
            const coordsButton = dom.el("i", {
                classes: [fa.fa, fa.faMapMarkerAlt, ui.Spacing.p1, "coordinates-button"],
                parent: coordsDom,
            });
            const coordsList = dom.el("div", {
                classes: [ui.closed],
                html: m.coords.map(v => ThreeUtils.v3str(v)).join(", "),
                parent: coordsDom,
            });
            On.listen(coordsButton, "click", () => {
                dom.toggleClass(coordsList, ui.closed);
            });
        }
        // delete button
        const closeButton = dom.el("i", {
            classes: [ui.close, fa.fa, fa.fa1X, fa.faTimes, ui.floatRight],
            parent: item,
        });
        On.listen(closeButton, "click", () => {
            this.broker.remove(m);
        });

        // tools area
        if (this.enableCopy || this.enableSend) {
            const toolsArea = dom.el("div", {
                classes: ["measure-tools", ui.Display.d(ui.Display.flex), ui.Flex.justifyContentEnd],
                parent: item,
            });

            if (this.enableCopy) {
                const copyButton = dom.el("i", {
                    classes: [fa.fa, fa.fa1X, fa.faClipboard],
                    parent: toolsArea,
                });
                On.listen(copyButton, "click", () => {
                    this.broker.onCopy(m);
                });
            }

            if (this.enableSend) {
                const sendButton = dom.el("i", {
                    classes: [fa.fa, fa.fa1X, fa.faArrowAltCircleUp],
                    parent: toolsArea,
                });
                On.listen(sendButton, "click", () => {
                    this.broker.onSend(m);
                });
            }
        }

        dom.append(parent, item);
        return item;
    }

    public get broker(): MeasuresBroker {
        return this._broker!;
    }

    public set broker(value: MeasuresBroker) {
        this._broker = value;
    }

    public get enableCopy(): boolean {
        return this._enableCopy;
    }

    public set enableCopy(value: boolean) {
        this._enableCopy = value;
    }

    public get enableSend(): boolean {
        return this._enableSend;
    }

    public set enableSend(value: boolean) {
        this._enableSend = value;
    }
}
