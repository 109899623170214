export type PromiseBuilder<T> = (...args: any[]) => Promise<T>;

export class WaitingPromiseBuilder<T> {
    protected _cancelled: boolean = false;
    protected _promise: Promise<T>;
    protected builderParameters: any[];
    protected promiseResolver!: (value: T | PromiseLike<T> | undefined) => void;
    protected promiseRejector!: (reason: any) => void;

    public constructor(readonly builder: PromiseBuilder<T>, args: any[]) {
        this.builderParameters = args;
        this._promise = new Promise<T>((resolve, reject) => {
            this.promiseResolver = resolve;
            this.promiseRejector = reject;
        });
    }

    public run(): Promise<T> {
        const realPromise = this.builder(...this.builderParameters);
        realPromise.then(
            t => !this.cancelled && this.promiseResolver(t), //
            err => !this.cancelled && this.promiseRejector(err),
        );
        return this.promise;
    }

    public get promise(): Promise<T> {
        return this._promise;
    }

    public get cancelled(): boolean {
        return this._cancelled;
    }

    public set cancelled(value: boolean) {
        this._cancelled = value;
    }
}

export class PromiseThrottler<T> {
    protected waitingQueue: Array<WaitingPromiseBuilder<T>>;
    protected executionQueue: Array<WaitingPromiseBuilder<T>>;

    public constructor(readonly concurrency: number = 6, readonly delayOnNext: number = 0) {
        this.waitingQueue = [];
        this.executionQueue = [];
    }

    public enqueue(func: PromiseBuilder<T>, ...args: any[]): Promise<T> {
        // wrap into a new promise that will execute promise function
        const wpb = new WaitingPromiseBuilder(func, args);
        // add to waiting queue
        this.waitingQueue.push(wpb);
        wpb.promise.then(t => !wpb.cancelled && this.executionCompleted(wpb), e => !wpb.cancelled && this.executionCompleted(wpb));
        this.processQueue();
        return wpb.promise;
    }

    protected executionCompleted(wbp: WaitingPromiseBuilder<T>): void {
        this.executionQueue = this.executionQueue.filter(w => w != wbp);
        if (this.delayOnNext > 0) {
            setTimeout(() => this.processQueue(), this.delayOnNext);
        } else {
            this.processQueue();
        }
    }

    protected processQueue(): void {
        if (this.waitingQueue.length > 0 && this.executionQueue.length < this.concurrency) {
            let queueNext = true;
            while (queueNext) {
                const wpb = this.waitingQueue.shift();
                if (wpb != null) {
                    // check for cancelled flag as we could have been fired from setTimeout after delayOnNext
                    if (wpb.cancelled) {
                        continue;
                    }
                    this.executionQueue.push(wpb);
                    wpb.run();
                    queueNext = false;
                } else {
                    // no items in queue (maybe they have all been cancelled)
                    queueNext = false;
                }
            }
        }
    }

    public clear(): void {
        this.waitingQueue.forEach(w => (w.cancelled = true));
        this.executionQueue.forEach(w => (w.cancelled = true));
        this.waitingQueue = [];
        this.executionQueue = [];
    }
}
