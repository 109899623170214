import { SignalingChannel, SignalingChannelStatus } from "gis3d/wf/net/rtc/SignalingChannel";
import { SignalingMessage } from "gis3d/wf/net/rtc/SignalingMessage";
import { SignalingAction } from "gis3d/wf/net/rtc/SignalingAction";
import run from "gis3d/wf/util/RuntimeUtils";

export enum DataChannelStatus {
    UNREGISTERED,
    REGISTERED,
    SENDING_OFFER,
    WAITING_OFFER,
    WAITING_ANSWER,
    WAITING_CANDIDATE,
    CONNECTED,
}

export class DataChannel {
    private registrationIntervalHandler: any;
    private registrationIntervalTime: number = 30 * 1000;
    private registrationIntervalOnError: number = 10 * 1000;
    private retryRegistratioHandler: any;
    private connectionTimeout: number = 5 * 1000;
    private connectionTimeoutHandler: any;
    private isListening: boolean = false;

    private _status: DataChannelStatus = DataChannelStatus.UNREGISTERED;
    private connection?: webkitRTCPeerConnection | null;
    private channel?: RTCDataChannel | null;

    public onReady?: () => void;
    public onConnect?: () => void;
    public onConnectionTimeout?: () => void;
    public onDisconnect?: () => void;
    public onMessage?: (data: any) => void;
    public onError?: (ev: Event) => void;

    private onDCStatusChange: any = run.bind(this, this.onDataChannelStatusChange);
    private onDCError: any = run.bind(this, this.onDataChannelError);
    private onDCMessage: any = run.bind(this, this.onDataChannelMessage);
    private onPeerDC: any = run.bind(this, this.onPeerDataChannel);
    private onPeerICE: any = run.bind(this, this.onPeerIceCandidate);
    private onPeerICEStatusChange: any = run.bind(this, this.onPeerIceCandidateStatusChange);

    public retryRegistrationOnError: boolean = true;

    public constructor(readonly signalingChannel: SignalingChannel, readonly peerConfiguration?: RTCPeerConnectionConfig, readonly channelLabel: string | null = null) {
        this.signalingChannel.onError = (channel: SignalingChannel, e: Event) => {
            this.onSignalingError(channel, e);
        };
        this.signalingChannel.onStatusChange = (channel: SignalingChannel, status: SignalingChannelStatus) => {
            this.onSignalingStatus(channel, status);
        };
        this.signalingChannel.onMessage = (channel: SignalingChannel, status: SignalingMessage) => {
            this.onSignallingMessage(channel, status);
        };
    }

    protected createPeerConnection(): void {
        this.clearConnection();

        const conf = this.peerConfiguration != null ? this.peerConfiguration : this.getDefaultRtcPeerConnectionConfig();
        this.connection = new RTCPeerConnection(conf);
        this.connection.addEventListener("datachannel", this.onPeerDC);
        this.connection.addEventListener("icecandidate", this.onPeerICE);
        this.connection.addEventListener("iceconnectionstatechange", this.onPeerICEStatusChange);
    }

    protected onPeerIceCandidateStatusChange(iceEvent: RTCPeerConnectionIceEvent) : void {
        if (this.connection != null) {
            switch (this.connection!.iceConnectionState) {
                case "disconnected":
                    this.disconnect();
                    break;
                default:
                    break;
            }
        }
    }

    protected onPeerDataChannel(event: RTCDataChannelEvent) : void {
        this.channel = event.channel;
        this.setupChannel(this.channel);
    }

    protected onPeerIceCandidate(iceEvent: RTCPeerConnectionIceEvent) : void {
        const candidate = iceEvent.candidate;
        if (candidate != null) {
            const mf = this.signalingChannel.messageFactory;
            if (mf.hasDestination()) {
                const message = mf.get(SignalingAction.CANDIDATE);
                message.payload = candidate;
                this.signalingChannel.send(message);
            }
        }
    }

    protected getDefaultRtcPeerConnectionConfig(): RTCPeerConnectionConfig {
        const c: RTCConfiguration = <RTCConfiguration>{};
        return c;
    }

    public get status(): DataChannelStatus {
        return this._status;
    }

    public listen(): void {
        if (this.status == DataChannelStatus.REGISTERED) {
            this._status = DataChannelStatus.WAITING_OFFER;
            this.isListening = true;
            this.createPeerConnection();
        } else {
            console.error("Cannot listen. Status:", this.status);
        }
    }

    public stopListening(): void {
        this.isListening = false;
        this.disconnect();
    }

    protected clearConnectionTimeout(): void {
        if (this.connectionTimeoutHandler != null) {
            clearTimeout(this.connectionTimeoutHandler);
            this.connectionTimeoutHandler = null;
        }
    }

    public connect(): void {
        if (this.status !== DataChannelStatus.CONNECTED) {
            this.clearConnection();
            this.clearConnectionTimeout();

            const mf = this.signalingChannel.messageFactory;
            if (mf.hasDestination()) {
                this._status = DataChannelStatus.SENDING_OFFER;
                const message = mf.get(SignalingAction.OFFER);

                this.createPeerConnection();
                this.channel = this.connection!.createDataChannel(this.channelLabel);
                this.setupChannel(this.channel);

                this.connection!.createOffer().then(offer => {
                    this.connection!.setLocalDescription(offer).then(() => {
                        message.payload = offer;
                        this.signalingChannel.send(message);
                        this._status = DataChannelStatus.WAITING_ANSWER;
                    });
                });
            }

            this.connectionTimeoutHandler = setTimeout(() => {
                this.clearConnectionTimeout();
                this.disconnect(false);
                if (this.onConnectionTimeout != null) {
                    this.onConnectionTimeout();
                }
            }, this.connectionTimeout);
        } else {
            console.log("Cannot connect. Status:", this.status);
        }
    }

    protected clearConnection(): void {
        if (this.channel != null) {
            this.channel.removeEventListener("open", this.onDCStatusChange);
            this.channel.removeEventListener("close", this.onDCStatusChange);
            this.channel.removeEventListener("error", this.onDCError);
            this.channel.removeEventListener("message", this.onDCMessage);
            this.channel.close();
            this.channel = null;
        }
        if (this.connection != null) {
            this.connection.removeEventListener("datachannel", this.onPeerDC);
            this.connection.removeEventListener("icecandidate", this.onPeerICE);
            this.connection.removeEventListener("iceconnectionstatechange", this.onPeerICEStatusChange);
            this.connection.close();
            this.connection = null;
        }
    }

    public disconnect(sendEvent: boolean = true): void {
        this.clearConnection();
        this._status = DataChannelStatus.REGISTERED;
        if (sendEvent !== false && this.onDisconnect != null) {
            this.onDisconnect();
        }
    }

    public closeSignaling(): void {
        this.unregister();
        this.signalingChannel.close();
    }

    public close(alsoCloseSignaling: boolean = false): void {
        if (alsoCloseSignaling) {
            this.closeSignaling();
        }
        this.clearConnection();
    }

    public open(): void {
        this.signalingChannel.open();
    }

    protected unregister(): void {
        if (this.registrationIntervalHandler != null) {
            clearTimeout(this.registrationIntervalHandler);
            this.registrationIntervalHandler = null;
        }

        if (this.status != DataChannelStatus.UNREGISTERED) {
            const unregMessage = this.signalingChannel.messageFactory.get(SignalingAction.UNREGISTER);
            try {
                if (this.signalingChannel.isOpen()) {
                    this.signalingChannel.send(unregMessage);
                }
            } catch (e) {
                console.log(e);
            } finally {
                // force as we could disconnect without waiting the signalling channel
                this._status = DataChannelStatus.UNREGISTERED;
            }
        }
    }

    protected register(): void {
        if (this.registrationIntervalHandler != null) {
            clearTimeout(this.registrationIntervalHandler);
            this.registrationIntervalHandler = null;
        }

        const message = this.signalingChannel.messageFactory.get(SignalingAction.REGISTER);
        this.signalingChannel.send(message);

        this.registrationIntervalHandler = setTimeout(() => {
            this.register();
        }, this.registrationIntervalTime);
    }

    private onSignalingError(channel: SignalingChannel, e: Event) {
        if (this.onError != null) {
            this.onError(e);
        }
        this.closeSignaling();
        // retry after time if enabled
        if (this.retryRegistrationOnError) {
            this.retryRegistratioHandler = setTimeout(() => {
                this.open();
            }, this.registrationIntervalOnError);
        }
    }

    private onSignalingStatus(channel: SignalingChannel, status: SignalingChannelStatus) {
        if (status == SignalingChannelStatus.OPEN) {
            this.register();
        } else {
            this.closeSignaling();
        }
    }

    private onSignallingMessage(channel: SignalingChannel, message: SignalingMessage) {
        if (message != null) {
            switch (message.action) {
                case SignalingAction.REGISTERED:
                    if (this.status == DataChannelStatus.UNREGISTERED) {
                        this._status = DataChannelStatus.REGISTERED;
                        if (this.onReady) {
                            this.onReady();
                        }
                        if (this.retryRegistratioHandler != null) {
                            clearTimeout(this.retryRegistratioHandler);
                            this.retryRegistratioHandler = null;
                        }
                    }
                    break;
                case SignalingAction.UNREGISTERED:
                    this._status = DataChannelStatus.UNREGISTERED;
                    this.closeSignaling();
                    break;
                case SignalingAction.OFFER:
                    this.answer(message);
                    break;
                case SignalingAction.ANSWER:
                    this.receiveAnswer(message);
                    break;
                case SignalingAction.CANDIDATE:
                    this.receiveCandidate(message);
                    break;
            }
        }
    }

    protected answer(message: SignalingMessage): void {
        if (this.status == DataChannelStatus.WAITING_OFFER && message.action == SignalingAction.OFFER) {
            const offer = message.payload;
            this.connection!.setRemoteDescription(offer).then(() => {
                this.connection!.createAnswer().then(answer => {
                    this.connection!.setLocalDescription(answer).then(() => {
                        const mf = this.signalingChannel.messageFactory;
                        mf.configureDestination(message.application, message.userId, message.windowId);
                        const ansMessage = mf.get(SignalingAction.ANSWER);
                        ansMessage.payload = answer;
                        this.signalingChannel.send(ansMessage);
                        this._status = DataChannelStatus.WAITING_CANDIDATE;
                    });
                });
            });
        }
    }

    protected receiveAnswer(message: SignalingMessage): void {
        if (this.status == DataChannelStatus.WAITING_ANSWER && message.action == SignalingAction.ANSWER) {
            const answer = message.payload;
            this._status = DataChannelStatus.WAITING_CANDIDATE;
            this.connection!.setRemoteDescription(answer);
        }
    }

    protected receiveCandidate(message: SignalingMessage): void {
        if (this.status == DataChannelStatus.WAITING_CANDIDATE && message.action == SignalingAction.CANDIDATE) {
            const candidate = message.payload;
            this.connection!.addIceCandidate(candidate);
        }
    }

    protected setupChannel(c: RTCDataChannel): void {
        c.addEventListener("open", this.onDCStatusChange);
        c.addEventListener("close", this.onDCStatusChange);
        c.addEventListener("error", this.onDCError);
        c.addEventListener("message", this.onDCMessage);
    }

    protected onDataChannelStatusChange(e: Event): void {
        this.clearConnectionTimeout();
        if (this.channel && this.channel.readyState == "open") {
            this._status = DataChannelStatus.CONNECTED;
            if (this.onConnect != null) {
                this.onConnect();
            }
        } else {
            this.disconnect();
            if (this.isListening === true) {
                this.listen();
            }
        }
    }

    protected onDataChannelError(e: ErrorEvent): void {
        console.log(e);
        this.disconnect();
        if (this.onError != null) {
            this.onError(e);
        }
    }

    protected onDataChannelMessage(e: MessageEvent): void {
        if (this.onMessage != null) {
            this.onMessage(e.data);
        }
    }

    public send(data: string | Blob | ArrayBuffer | ArrayBufferView): void {
        if (this.status == DataChannelStatus.CONNECTED) {
            this.channel!.send(data);
        } else {
            console.log("DataChannel non connected");
        }
    }
}
