import { HasId } from "./HasId";

export class HashSet<T extends HasId> {
    protected map: Map<string, T> = new Map<string, T>();

    public add(object: T): void {
        this.map.set(object.id, object);
    }

    public has(object: T): boolean {
        return this.map.has(object.id);
    }

    public hasHash(id: string): boolean {
        return this.map.has(id);
    }

    public delete(object: T): void {
        this.map.delete(object.id);
    }

    public clear(): void {
        this.map.clear();
    }

    public items(): IterableIterator<T> {
        return this.map.values();
    }
}
