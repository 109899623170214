import Topic from "gis3d/wf/core/Topic";
import { ToastOptions } from "./ToastOptions";
import DomUtils from "gis3d/wf/util/DomUtils";
import { Toast } from "./Toast";
import { Tween, Easing } from "@tweenjs/tween.js";
import { Point } from "../geom/Point";
import { ToastPosition } from "./ToastPosition";
import { ToastType } from "./ToastType";
import UiStyle from "../style/UiStyle";
import On from "gis3d/wf/core/On";

export type ToastInstance = { timeoutHandler: number | null; toast: Toast };

export class Toaster {
    public static readonly SHOW: string = "toaster/show";
    public static readonly CLEAR: string = "toaster/clear";
    public static readonly DEFAULT_POSITION: ToastPosition = ToastPosition.BOTTOM_RIGHT;
    public static readonly DEFAULT_TYPE: ToastType = ToastType.DEFAULT;

    protected toasts: Map<ToastPosition, Array<ToastInstance>>;
    private _hideAfter: number = 5 * 1000;
    private _fadeOutTime: number = 0.6 * 1000;
    private _enterTime: number = 1 * 1000;
    private _fadeInTime: number = 0.6 * 1000;
    private _updateTime: number = 0.4 * 1000;

    public constructor(readonly parent: HTMLElement = DomUtils.body()) {
        this.toasts = new Map();
        for (const pos in ToastPosition) {
            if (!isNaN(Number(pos))) {
                this.toasts.set(Number(pos) as ToastPosition, []);
            }
        }

        Topic.subscribe(Toaster.SHOW, (options: ToastOptions) => {
            this.show(options);
        });
        Topic.subscribe(Toaster.CLEAR, () => {
            this.clear();
        });
    }

    public show(options: ToastOptions): ToastInstance {
        if (options.position == null) {
            options.position = Toaster.DEFAULT_POSITION;
        }

        if (options.type == null) {
            options.type = Toaster.DEFAULT_TYPE;
        }

        const toast = new Toast(options);
        toast.init();

        this.add(toast);

        const hideAfterMs = this.enterTime + (options.hideAfter ? options.hideAfter : this.hideAfter);
        const toastInstance = {
            timeoutHandler:
                options.autoHide === true //
                    ? window.setTimeout(() => this.hide(toastInstance), hideAfterMs)
                    : null,
            toast: toast,
        };

        if (toast.closeNode != null) {
            On.listen(toast.closeNode, "click", () => this.hide(toastInstance));
        }

        const toasts = this.toasts.get(options.position)!;
        toasts.push(toastInstance);
        return toastInstance;
    }

    public hide(toastInstance: ToastInstance): void {
        const toast = toastInstance.toast;
        if (toastInstance.timeoutHandler != null) {
            window.clearTimeout(toastInstance.timeoutHandler);
        }
        const filtered = this.toasts.get(toast.options.position)!.filter(i => i.toast !== toast);
        this.remove(toast);
        this.toasts.set(toast.options.position, filtered);
    }

    public targetPointForToast(toast: Toast): Point {
        const p = new Point(0, 0);
        const ts = DomUtils.marginBox(toast.domNode!);
        if (toast.options.position === ToastPosition.CENTER) {
            p.x = DomUtils.win().innerWidth / 2 - ts.w! / 2;
            p.y = DomUtils.win().innerHeight / 2 - ts.h! / 2;
        } else {
            let yOffset = 0;
            const toastsInPosition = this.toasts.get(toast.options.position);
            if (toastsInPosition) {
                // find toast
                let toastIndex = 0;
                for (const tip of toastsInPosition) {
                    if (tip.toast === toast) {
                        break;
                    }
                    ++toastIndex;
                }
                //
                for (let i = 0; i < toastIndex; i++) {
                    const tip = toastsInPosition[i];
                    const s = DomUtils.marginBox(tip.toast.domNode!);
                    yOffset += s.h!;
                }
            }

            switch (toast.options.position) {
                case ToastPosition.TOP_LEFT:
                case ToastPosition.BOTTOM_LEFT:
                case ToastPosition.TOP_RIGHT:
                case ToastPosition.BOTTOM_RIGHT:
                    p.x = 0;
                    break;
                case ToastPosition.TOP_CENTER:
                case ToastPosition.BOTTOM_CENTER:
                    p.x = DomUtils.win().innerWidth / 2 - ts.w! / 2;
                    break;
            }
            p.y = yOffset;
        }
        return p;
    }

    public originPointForToast(toast: Toast, target: Point): Point {
        const s = DomUtils.size(toast.domNode!);
        const p = new Point();
        p.x = target.x;
        p.y = target.y;

        switch (toast.options.position) {
            case ToastPosition.TOP_LEFT:
            case ToastPosition.BOTTOM_LEFT:
            case ToastPosition.TOP_RIGHT:
            case ToastPosition.BOTTOM_RIGHT:
                p.x = target.x! - s.w!;
                break;
            case ToastPosition.TOP_CENTER:
            case ToastPosition.BOTTOM_CENTER:
                p.y = target.y! - s.h!;
                break;
        }
        return p;
    }

    public clear(): void {
        for (const idx of this.toasts.keys()) {
            const toasts = this.toasts.get(idx)!;
            toasts.forEach(t => {
                if (t.timeoutHandler != null) {
                    window.clearTimeout(t.timeoutHandler);
                }
                this.remove(t.toast);
            });
            this.toasts.set(idx, []);
        }
    }

    public remove(toast: Toast): void {
        const node = toast.domNode!;

        new Tween({ opacity: 1 })
            .to({ opacity: 0 }, this.fadeOutTime)
            .easing(Easing.Quartic.Out)
            .onUpdate(o => {
                DomUtils.setCss(node, "opacity", o.opacity);
            })
            .onComplete(o => {
                const parent = node.parentElement!;
                if (parent != null) {
                    DomUtils.remove(parent, node);
                }
                this.updateLayout();
            })
            .start();
    }

    public add(toast: Toast): void {
        const node = toast.domNode!;

        DomUtils.addClass(node, UiStyle.invisible);
        DomUtils.append(DomUtils.body(), toast.domNode!);

        if (toast.options.position === ToastPosition.CENTER) {
            const showPoint = this.targetPointForToast(toast);

            new Tween({ opacity: 0 })
                .to({ opacity: 1 }, this.fadeInTime)
                .easing(Easing.Quartic.Out)
                .onStart(o => {
                    DomUtils.setCss(node, "opacity", 0);
                    DomUtils.setCss(node, "left", showPoint.x + "px");
                    DomUtils.setCss(node, "top", showPoint.y + "px");
                    DomUtils.removeClass(node, UiStyle.invisible);
                })
                .onUpdate(o => {
                    DomUtils.setCss(node, "opacity", o.opacity);
                })
                .start();
        } else {
            const showPoint = this.targetPointForToast(toast);
            const startingPoint = this.originPointForToast(toast, showPoint);

            new Tween(startingPoint)
                .to(showPoint, this.enterTime)
                .easing(Easing.Quartic.Out)
                .onStart(o => {
                    switch (toast.options.position) {
                        case ToastPosition.TOP_LEFT:
                            DomUtils.setCss(node, "top", o.y + "px");
                            DomUtils.setCss(node, "left", o.x + "px");
                            break;
                        case ToastPosition.BOTTOM_LEFT:
                            DomUtils.setCss(node, "left", o.x + "px");
                            DomUtils.setCss(node, "bottom", o.y + "px");
                            break;
                        case ToastPosition.TOP_CENTER:
                            DomUtils.setCss(node, "top", o.y + "px");
                            DomUtils.setCss(node, "left", o.x + "px");
                            break;
                        case ToastPosition.BOTTOM_CENTER:
                            DomUtils.setCss(node, "bottom", o.y + "px");
                            DomUtils.setCss(node, "left", o.x + "px");
                            break;
                        case ToastPosition.TOP_RIGHT:
                            DomUtils.setCss(node, "top", o.y + "px");
                            DomUtils.setCss(node, "right", o.x + "px");
                            break;
                        case ToastPosition.BOTTOM_RIGHT:
                            DomUtils.setCss(node, "bottom", o.y + "px");
                            DomUtils.setCss(node, "right", o.x + "px");
                            break;
                    }
                    DomUtils.removeClass(node, UiStyle.invisible);
                })
                .onUpdate(o => {
                    switch (toast.options.position) {
                        case ToastPosition.TOP_LEFT:
                        case ToastPosition.BOTTOM_LEFT:
                            DomUtils.setCss(node, "left", o.x + "px");
                            break;
                        case ToastPosition.TOP_CENTER:
                            DomUtils.setCss(node, "top", o.y + "px");
                            break;
                        case ToastPosition.BOTTOM_CENTER:
                            DomUtils.setCss(node, "bottom", o.y + "px");
                            break;
                        case ToastPosition.TOP_RIGHT:
                        case ToastPosition.BOTTOM_RIGHT:
                            DomUtils.setCss(node, "right", o.x + "px");
                            break;
                    }
                })
                .start();
        }
    }

    public updateLayout(): void {
        for (const idx of this.toasts.keys()) {
            const toastInstances = this.toasts.get(idx)!;
            for (const t of toastInstances) {
                const toast = t.toast;
                const node = toast.domNode!;
                const tp = this.targetPointForToast(toast);
                const p = new Point(0, 0);

                switch (toast.options.position) {
                    case ToastPosition.TOP_LEFT:
                        p.x = parseInt(DomUtils.css(node, "left"));
                        p.y = parseInt(DomUtils.css(node, "top"));
                        break;
                    case ToastPosition.BOTTOM_LEFT:
                        p.x = parseInt(DomUtils.css(node, "left"));
                        p.y = parseInt(DomUtils.css(node, "bottom"));
                        break;
                    case ToastPosition.TOP_CENTER:
                        p.x = parseInt(DomUtils.css(node, "left"));
                        p.y = parseInt(DomUtils.css(node, "top"));
                        break;
                    case ToastPosition.BOTTOM_CENTER:
                        p.x = parseInt(DomUtils.css(node, "left"));
                        p.y = parseInt(DomUtils.css(node, "bottom"));
                        break;
                    case ToastPosition.TOP_RIGHT:
                        p.x = parseInt(DomUtils.css(node, "right"));
                        p.y = parseInt(DomUtils.css(node, "top"));
                        break;
                    case ToastPosition.BOTTOM_RIGHT:
                        p.x = parseInt(DomUtils.css(node, "right"));
                        p.y = parseInt(DomUtils.css(node, "bottom"));
                        break;
                }

                if (p.x != tp.x || p.y !== tp.y) {
                    new Tween(p)
                        .to(tp, this.updateTime)
                        .easing(Easing.Quartic.Out)
                        .onUpdate(o => {
                            switch (toast.options.position) {
                                case ToastPosition.TOP_LEFT:
                                case ToastPosition.TOP_CENTER:
                                case ToastPosition.TOP_RIGHT:
                                    DomUtils.setCss(node, "top", o.y + "px");
                                    break;
                                case ToastPosition.BOTTOM_LEFT:
                                case ToastPosition.BOTTOM_CENTER:
                                case ToastPosition.BOTTOM_RIGHT:
                                    DomUtils.setCss(node, "bottom", o.y + "px");
                                    break;
                            }
                        })
                        .start();
                }
            }
        }
    }

    public get hideAfter(): number {
        return this._hideAfter;
    }

    public set hideAfter(value: number) {
        this._hideAfter = value;
    }

    public get enterTime(): number {
        return this._enterTime;
    }

    public set enterTime(value: number) {
        this._enterTime = value;
    }

    public get fadeOutTime(): number {
        return this._fadeOutTime;
    }

    public set fadeOutTime(value: number) {
        this._fadeOutTime = value;
    }

    public get fadeInTime(): number {
        return this._fadeInTime;
    }

    public set fadeInTime(value: number) {
        this._fadeInTime = value;
    }

    public get updateTime(): number {
        return this._updateTime;
    }

    public set updateTime(value: number) {
        this._updateTime = value;
    }
}
