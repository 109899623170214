import ui from "gis3d/wf/ui/style/UiStyle";
import on from "gis3d/wf/core/On";
import dom from "gis3d/wf/util/DomUtils";
import run from "gis3d/wf/util/RuntimeUtils";

import { BaseUiComponent } from "gis3d/wf/ui/BaseUiComponent";
import { UiComponent } from "gis3d/wf/ui/UiComponent";
import { UiContainer } from "gis3d/wf/ui/UiContainer";
import { Size } from "gis3d/wf/ui/geom/Size";
import { Box } from "gis3d/wf/ui/geom/Box";
import { Pane } from "gis3d/wf/ui/layout/Pane";
import { Region } from "gis3d/wf/ui/layout/Region";
import { RegionAwareUiComponent } from "gis3d/wf/ui/layout/RegionAwareUiComponent";

export class Splitter extends BaseUiComponent implements RegionAwareUiComponent {
    protected _live: boolean = true;
    protected _pane: Pane | null = null;
    protected decorator: HTMLElement | null = null;
    protected ghostStartBox: Box = new Box();
    protected ghostElement: HTMLElement | null = null;
    protected vertical: boolean;
    protected handlers: Array<Function>;
    protected dragging: boolean = false;
    protected scaleFactor: number = 1;
    protected startPosition: number = 0;
    protected startBox: Box = new Box();
    protected resizeBox: Box = new Box();
    protected lastDelta: number = 0;
    protected maxGrowth: Size | null = null;
    protected growthMargin: number = 20;
    protected minimumSize: number = 20;

    public constructor(readonly region: Region) {
        super();
        this.vertical = region > Region.Bottom ? true : false;
        if (region == Region.Right || region == Region.Bottom) {
            this.scaleFactor = -1;
        }
        this.handlers = [];
    }

    public get pane(): Pane {
        return this._pane!;
    }

    public set pane(pane: Pane) {
        this._pane = pane;
    }

    public get live(): boolean {
        return this._live!;
    }

    public set live(live: boolean) {
        this._live = live;
    }

    public build(): void {
        const cssClass = ui.p("Splitter");
        this.domElementOptions.classes = [cssClass, ui.c(cssClass, this.vertical ? "vertical" : "horizontal")];
        super.build();
        this.decorator = dom.el("div", { classes: [ui.c(cssClass, "decorator")] });
        dom.append(this.domNode!, this.decorator);
        on.listen(this.domNode, "mousedown", run.bind(this, this.onMouseDown), true);
        on.listen(this.domNode, "mouseover", run.bind(this, this.onMouseOver), false);
        on.listen(this.domNode, "mouseleave", run.bind(this, this.onMouseLeave), false);
    }

    protected getMaxGrowth(): Size | null {
        let centerPane: Pane | null = null;
        let container: UiContainer = (<UiContainer>this.parent!);
        for (let c of container.children) {
            if (c instanceof Pane && (<Pane>c).region == Region.Center) {
                centerPane = c;
                break;
            }
        }
        if (centerPane != null) {
            let sz: Size = centerPane.resize(null)!;
            sz.w! -= this.growthMargin;
            sz.h! -= this.growthMargin;
            return sz;
        }
        return null;
    }

    protected updatePane(): void {
        let adjustedDelta = this.scaleFactor * this.lastDelta;
        this.resizeBox.reset();
        if (this.vertical) {
            adjustedDelta = Math.min(adjustedDelta, this.maxGrowth!.w!);
            this.resizeBox.w = Math.max(this.startBox.w! + adjustedDelta, this.minimumSize);
        } else {
            adjustedDelta = Math.min(adjustedDelta, this.maxGrowth!.h!);
            this.resizeBox.h = Math.max(this.startBox.h! + adjustedDelta, this.minimumSize);
        }
        this.pane.resize(this.resizeBox);
        (<UiContainer>this.parent!).layout();
    }

    protected createGhost(): void {
        const cssClass = ui.p("Splitter");
        this.ghostElement = dom.el("div", { classes: [cssClass, ui.c(cssClass, "ghost")] });
        dom.setBox(this.ghostElement, dom.marginBox(this.domNode!, null, this.ghostStartBox));
        dom.append(this.parent!.domNode!, this.ghostElement);
    }

    protected updateGhost(): void {
        let adjustedDelta = this.lastDelta;
        let direction = adjustedDelta > 0 ? 1 : -1;
        this.resizeBox.reset();
        if (this.vertical) {
            if (this.ghostStartBox.x! > this.startBox.x!) {
                // left
                if (adjustedDelta > 0) {
                    // growing
                    adjustedDelta = Math.min(direction * adjustedDelta, this.maxGrowth!.w!);
                    this.resizeBox.x = this.ghostStartBox.x! + direction * adjustedDelta;
                } else {
                    // shrinking     
                    this.resizeBox.x = this.ghostStartBox.x! + adjustedDelta;
                    let limit = this.ghostStartBox.x! - this.startBox!.w! + this.minimumSize;
                    if (this.resizeBox.x < limit) {
                        this.resizeBox.x = limit;
                    }
                }
            } else {
                // right
                if (adjustedDelta > 0) {
                    // shrinking
                    this.resizeBox.x = this.ghostStartBox.x! + adjustedDelta;
                    let limit = this.startBox.x! + this.startBox!.w! - this.minimumSize;
                    if (this.resizeBox.x > limit) {
                        this.resizeBox.x = limit;
                    }
                } else {
                    // growing     
                    adjustedDelta = Math.min(direction * adjustedDelta, this.maxGrowth!.w!);
                    this.resizeBox.x = this.ghostStartBox.x! + direction * adjustedDelta;
                }
            }
        } else {
            if (this.ghostStartBox.y! > this.startBox.y!) {
                // top
                if (adjustedDelta > 0) {
                    // growing
                    adjustedDelta = Math.min(direction * adjustedDelta, this.maxGrowth!.h!);
                    this.resizeBox.y = this.ghostStartBox.y! + direction * adjustedDelta;
                } else {
                    // shrinking     
                    this.resizeBox.y = this.ghostStartBox.y! + adjustedDelta;
                    let limit = this.ghostStartBox.y! - this.startBox!.h! + this.minimumSize;
                    if (this.resizeBox.y < limit) {
                        this.resizeBox.y = limit;
                    }
                }
            } else {
                // bottom
                if (adjustedDelta > 0) {
                    // shrinking
                    this.resizeBox.y = this.ghostStartBox.y! + adjustedDelta;
                    let limit = this.startBox.y! + this.startBox!.h! - this.minimumSize;
                    if (this.resizeBox.y > limit) {
                        this.resizeBox.y = limit;
                    }
                } else {
                    // growing
                    adjustedDelta = Math.min(direction * adjustedDelta, this.maxGrowth!.h!);
                    this.resizeBox.y = this.ghostStartBox.y! + direction * adjustedDelta;
                }
            }
        }
        dom.setBox(this.ghostElement!, this.resizeBox);
    }

    protected removeGhost(): void {
        if (this.ghostElement) {
            dom.remove(this.parent!.domNode!, this.ghostElement);
            this.ghostElement = null;
        }
    }

    protected onMouseDown(downEvent: MouseEvent): void {
        this.dragging = true;
        this.lastDelta = 0;
        this.startPosition = this.vertical ? downEvent.pageX : downEvent.pageY;
        dom.marginBox(this.pane.domNode!, null, this.startBox);
        this.maxGrowth = this.getMaxGrowth();
        if (!this.live) {
            this.createGhost();
        }
        const ownDoc: Document = this.domNode!.ownerDocument!;
        // prevent selection and dragstart
        this.handlers.push(on.listen(ownDoc, "dragstart", function (e: Event) {
            e.stopPropagation();
            e.preventDefault();
        }));
        this.handlers.push(on.listen(ownDoc.body, "selectstart", function (e: Event) {
            e.stopPropagation();
            e.preventDefault();
        }));
        // listen for mouse events
        this.handlers.push(on.listen(ownDoc, "mouseup", run.bind(this, this.onMouseUp)));
        this.handlers.push(on.listen(ownDoc, "mousemove", run.bind(this, this.onMouseMove)));
        // block bubbling
        downEvent.stopPropagation();
        downEvent.preventDefault();
    }

    protected onMouseMove(ev: MouseEvent): void {
        const position: number = this.vertical ? ev.pageX : ev.pageY;
        this.lastDelta = position - this.startPosition;
        if (this.live) {
            window.requestAnimationFrame(() => this.updatePane());
        } else {
            window.requestAnimationFrame(() => this.updateGhost());
        }
    }

    protected onMouseUp(ev: MouseEvent): void {
        this.dragging = false;
        if (!this.live) {
            window.requestAnimationFrame(() => {
                this.removeGhost();
                this.updatePane();
            });
        }
        this.clearHandlers();
        dom.removeClass(this.domNode!, "hover");
    }

    protected onMouseLeave(ev: MouseEvent): void {
        if (!this.dragging) {
            dom.removeClass(this.domNode!, "hover");
        }
    }

    protected onMouseOver(ev: MouseEvent): void {
        dom.addClass(this.domNode!, "hover");
    }

    protected clearHandlers(): void {
        let removeHandler = null;
        while (removeHandler = this.handlers.pop()) {
            removeHandler();
        }
    }
}