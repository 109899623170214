import ui from "gis3d/wf/ui/style/UiStyle";
import dom from "gis3d/wf/util/DomUtils";
import I18N from "gis3d/wf/i18n/I18N";
import { FloatingCard } from "gis3d/wf/ui/widget/FloatingCard";
import { Box } from "gis3d/wf/ui/geom/Box";
import { Size } from "gis3d/wf/ui/geom/Size";
import { Tree } from "gis3d/wf/ui/widget/Tree";
import { LayerTreeStore, LayerTreeNode } from "../core/three/scene/LayerTreeStore";

export class LayersWidget extends FloatingCard {
    protected tree!: Tree;
    private _store!: LayerTreeStore;
    public onLayerCheck: (node: LayerTreeNode) => void = node => {};

    public prepareBuildOptions(): void {
        super.prepareBuildOptions();
        this.box.w = 320;
        this.box.h = 400;
        this.closable = false;
        this.title = I18N.i("cityvu.gui.layersWidget.title");
        this.domElementOptions.classes!.push(ui.p("CityvuLayersWidget"));
    }

    public build(): void {
        super.build();
        this.displayed = false;

        this.tree = new Tree();
        this.tree.hideRoot = true;
        this.tree.expandOnStartup = true;
        this.tree.collapsible = false;
        this.tree.checkable = true;
        this.tree.onCheck = node => {
            this.onLayerCheck(node as LayerTreeNode);
        };
        this.tree.init();
        this.addChild(this.tree);
    }


    public resize(box: Box | null = null): Size | null {
        if (box != null && this.dragLastPosition[0] == 0 && this.dragLastPosition[1] == 0) {
            let bW = 0;
            let bH = 0;
            if (this.box.h == null || this.box.w == null) {
                const computedBox = dom.marginBox(this.domNode!);
                bW = computedBox.w!;
                bH = computedBox.h!;
            } else {
                bW = this.box.w!;
                bH = this.box.h!;
            }
            this.box.x = box.w! - bW - 20;
            this.box.y = box.h! - bH - 20;
        }
        return super.resize(box);
    }

    public get store(): LayerTreeStore {
        return this._store;
    }

    protected applyDisplayed(): void {
        super.applyDisplayed();
        this.render();
    }

    public set store(value: LayerTreeStore) {
        this._store = value;
        this.tree.store = this.store;
    }
}
