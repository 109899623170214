import { EmptyScene } from "./EmptyScene";
import { SceneDefinition } from "gis3d/cityvu/scene/definition/SceneDefinition";
import { GuiOptions } from "gis3d/cityvu/gui/GuiOptions";
import { SceneDefinitionGuiOptions } from "gis3d/cityvu/scene/definition/SceneDefinitionGuiOptions";
import { CrsFactory } from "../../geo/crs/CrsFactory";
import { DataSource } from "gis3d/cityvu/data/DataSource";
import { DataSourceFactory } from "gis3d/cityvu/data/DataSourceFactory";
import { Layer3d } from "./Layer3d";
import { LayerManager } from "gis3d/cityvu/scene/layer/LayerManager";
import { Layer3dFactory } from "gis3d/cityvu/scene/layer3d/Layer3dFactory";

export abstract class Cityvu3DScene extends EmptyScene {
    private _dataSources: Map<string, DataSource>;
    private _layerManager: LayerManager;

    public constructor(readonly definition: SceneDefinition) {
        super();
        this.crs = CrsFactory.create(this.definition.georeference.crs);
        this._layerManager = new LayerManager(this);
        this._dataSources = new Map();
        this.pose.animate = true;
        this.processDefinition();
    }

    public get guiOptions(): GuiOptions | null {
        if (this._guiOptions == null) {
            this._guiOptions = SceneDefinitionGuiOptions.get(this.definition);
        }
        return this._guiOptions;
    }

    public init(): void {
        if (!this.initialized) {
            super.init();
        }
    }

    protected processDefinition(): void {
        this.processDataSources();
        this.processSceneLayers();
    }

    protected processDataSources(): void {
        for (const srcDef of this.definition.dataSources) {
            if (srcDef.enabled) {
                const dataSource = DataSourceFactory.create(srcDef);
                if (dataSource != null) {
                    this.dataSources.set(srcDef.code, dataSource);
                } else {
                    throw Error("scene.cannotCreateDataSource");
                }
            }
        }
        console.log("DATASOURCES", this.dataSources);
    }

    protected processSceneLayers(): void {
        for (const layer of this.definition.layers) {
            if (layer.enabled === true) {
                let selectedSourceId = null;
                for (const source of layer.sources) {
                    const ds = this.dataSources.get(source.code);
                    if (ds != null) {
                        const layer3d = Layer3dFactory.create(layer.type, source.options, ds);
                        this.layerManager.register(layer.id, source.id, layer3d);
                        if (layer.selectedSource === source.code) {
                            selectedSourceId = source.id;
                        }
                    } else {
                        throw Error("scene.cannotFindDataSource");
                    }
                }

                if (selectedSourceId != null) {
                    this.layerManager.selectSource(layer.id, selectedSourceId);
                }

                this.layerManager.setVisibility(layer.id, layer.visible);
            }
        }
        console.log("LAYERS", this.layers);
    }

    public get dataSources(): Map<string, DataSource> {
        return this._dataSources;
    }

    public get layerManager(): LayerManager {
        return this._layerManager;
    }
}
