export class Size {
    public w: number | null;
    public h: number | null;
    public maxW?: number;
    public maxH?: number;
    public minW?: number;
    public minH?: number;

    public constructor(w: number | null = null, h: number | null = null) {
        this.w = w;
        this.h = h;
    }

    public reset(): void {
        this.w = null;
        this.h = null;
    }

    public copy(sz: Size): void {
        this.w = sz.w;
        this.h = sz.h;
    }
}
