import { SceneDefinitionLayer } from "../SceneDefinitionLayer";
import { SceneDefinitionLayerSource } from "../SceneDefinitionLayerSource";
import { SceneDefinitionLayerType } from "../SceneDefinitionLayerType";
import NumberUtils from "gis3d/wf/util/NumberUtils";

export class DefaultSceneDefinitionLayer implements SceneDefinitionLayer {
    private _id!: string;
    private _name!: string;
    private _label!: string;
    private _enabled: boolean = true;
    private _visible: boolean = true;
    private _type!: SceneDefinitionLayerType;
    private _sources!: Array<SceneDefinitionLayerSource>;
    private _selectedSource?: string | undefined;

    public get sources(): Array<SceneDefinitionLayerSource> {
        return this._sources;
    }

    public set sources(value: Array<SceneDefinitionLayerSource>) {
        this._sources = value;
    }

    public get type(): SceneDefinitionLayerType {
        return this._type;
    }

    public set type(value: SceneDefinitionLayerType) {
        this._type = value;
    }

    public get selectedSource(): string | undefined {
        return this._selectedSource;
    }

    public set selectedSource(value: string | undefined) {
        this._selectedSource = value;
    }

    public get id(): string {
        if (this._id == null) {
            this.id = NumberUtils.createUuid();
        }
        return this._id;
    }

    public set id(value: string) {
        this._id = value;
    }

    public get name(): string {
        return this._name;
    }

    public set name(value: string) {
        this._name = value;
    }

    public get label(): string {
        return this._label;
    }

    public set label(value: string) {
        this._label = value;
    }

    public get enabled(): boolean {
        return this._enabled;
    }

    public set enabled(value: boolean) {
        this._enabled = value;
    }

    public get visible(): boolean {
        return this._visible;
    }

    public set visible(value: boolean) {
        this._visible = value;
    }
}
