import dom from "gis3d/wf/util/DomUtils";
import ui from "gis3d/wf/ui/style/UiStyle";
import on from "gis3d/wf/core/On";

import { Box } from "gis3d/wf/ui/geom/Box";
import { Size } from "gis3d/wf/ui/geom/Size";
import { CanvasUiComponent } from "gis3d/wf/ui/CanvasUiComponent";

class Arrow {
    private onChange: () => void;
    private _enabled:boolean = false;
    public readonly vertices:Array<number> = [0,0,0,0,0,0];
    public onClick?: () => void;

    constructor(onChange:() => void) {
        this.onChange = onChange;
    }

    public get enabled() : boolean {
        return this._enabled;
    }

    public set enabled(v:boolean) {
        this._enabled = v;
        this.onChange();
    }
}

export class NavigatorArrows extends Array<Arrow> {
    public onChange: () => void = () => {};
    
    constructor() {
        super();
        for (let i = 0; i < 8; i++) {
            let a = new Arrow(() => this.onChange());
            this.push(a);
        }
    }
}

export class Navigator extends CanvasUiComponent {
    protected _canvas!:HTMLCanvasElement;
    protected _arrows:NavigatorArrows;
    protected size:Size = new Size();

    protected radius:number = 18;
    protected arrowWidth:number = 16;
    protected arrowHeigth:number = 14;
    protected arrowOffset:number = this.radius + 3.5;
    
    constructor() {
        super();
        this.domElementOptions = {
            classes : [ ui.p("CityvuNavigator") ]
        };
        this._arrows = new NavigatorArrows();
        this.arrows.onChange = () => {
            if (this.isStarted()) {
                this.render();
            }
        };
    }

    public build() : void {
        super.build();
    }

    protected attachEventListeners() : void {
        on.listen(this.canvas, "mouseup", (e:MouseEvent) => this.onMouseUp(e));
    }

    protected onMouseUp(e:MouseEvent) : void {
        let rect = this.canvas.getBoundingClientRect();
        const arrowIndex = this.findArrow(e.clientX - rect.left, e.clientY - rect.top);
        if (arrowIndex != null) {
            const arrow = this.arrows[arrowIndex];
            if (arrow.enabled && arrow.onClick != null) {
                arrow.onClick();
            }
        }
    }

    protected findArrow(x:number, y:number) : number | null {
        const cx = this.canvas.width / 2;
        const cy = this.canvas.height / 2;

        const ccx = x - cx;
        const ccy = cy - y;
        const dist = Math.sqrt(ccx*ccx + ccy*ccy);

        if (dist > this.arrowOffset && dist <= this.arrowOffset + this.arrowHeigth) {
            // find triangle
            for (let i = 0; i < this.arrows.length; i++) {
                const a = this.arrows[i];
                const p = a.vertices;
                // baricentric point in triangle check
                const v0 = [p[4] - p[0], p[5] - p[1]];
                const v1 = [p[2] - p[0], p[3] - p[1]];
                const v2 = [x - p[0], y - p[1]];
                const dot00 = this.dot2(v0[0], v0[1], v0[0], v0[1]);
                const dot01 = this.dot2(v0[0], v0[1], v1[0], v1[1]);
                const dot02 = this.dot2(v0[0], v0[1], v2[0], v2[1]);
                const dot11 = this.dot2(v1[0], v1[1], v1[0], v1[1]);
                const dot12 = this.dot2(v1[0], v1[1], v2[0], v2[1]);

                const invDenom = 1 / (dot00 * dot11 - dot01 * dot01);
                const u = (dot11 * dot02 - dot01 * dot12) * invDenom;
                const v = (dot00 * dot12 - dot01 * dot02) * invDenom;

                if ((u >= 0) && (v >= 0) && (u + v < 1)) {
                    // inside i
                    return i;
                }
            }
        }

        return null;
    }

    protected dot2(x1:number,y1:number,x2:number,y2:number) : number {
        return x1*x2 + y1*y2;
    }

    public resize(box : Box | null) : Size | null {
        // we do our own resizing
        return dom.marginBox(this.domNode!);
    }

    public render() : void {
        super.render();
        dom.size(this.domNode!, this.size);
        this.canvas.height = this.size.h!;
        this.canvas.width = this.size.w!;

        const cx = this.size.w! / 2;
        const cy = this.size.h! / 2;
        const ctx = this.canvas.getContext("2d")!;
        const radius = this.radius;
        const arrowWidth = this.arrowWidth;
        const arrowHeigth = this.arrowHeigth;
        const arrowOffset = this.arrowOffset;

        // circles
        ctx.clearRect(0, 0, this.size.w!, this.size.h!);
        ctx.beginPath();
        ctx.arc(cx, cy, radius, 0, 2 * Math.PI);
        ctx.fillStyle = "rgba(0,0,0,0.4)";
        ctx.fill();
        // north letter
        ctx.font = "bold 11px sans-serif";
        ctx.fillStyle = "white";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText("NAV", cx, cy);
        // arrows
        let p = null;
        for (let i = 0; i < 8; i++) {
            const a = -i * Math.PI / 4;
            const arrow = this.arrows[i];
            ctx.beginPath();
            p = this.transformPoint(-arrowWidth / 2, -arrowOffset, cx, cy, a);
            arrow.vertices[0] = p.x;
            arrow.vertices[1] = p.y;
            ctx.moveTo(p.x, p.y);

            p = this.transformPoint(arrowWidth / 2, -arrowOffset, cx, cy, a);
            arrow.vertices[2] = p.x;
            arrow.vertices[3] = p.y;
            ctx.lineTo(p.x, p.y);

            p = this.transformPoint(0, -arrowHeigth - arrowOffset, cx, cy, a);
            ctx.lineTo(p.x, p.y);
            arrow.vertices[4] = p.x;
            arrow.vertices[5] = p.y;

            p = this.transformPoint(-arrowWidth / 2, -arrowOffset, cx, cy, a);
            ctx.lineTo(p.x, p.y);
            ctx.fillStyle = arrow.enabled ? "white" : "rgba(0,0,0,0.4)";
            ctx.fill();
        }
    }

    protected transformPoint(x:number, y:number, tx:number, ty:number, r:number) : { x : number, y: number } {
        const s = Math.sin(-r);
        const c = Math.cos(-r);
        let x2 = x * c - y * s + tx;
        let y2 = x * s + y * c + ty; 

        return {
            x : x2,
            y : y2
        };
    }

    public get arrows() : NavigatorArrows {
        return this._arrows;
    }
}