import { Projection } from "./Projection";
import { Bounds } from "../../geometry/Bounds";
import { GeoUtils } from "../GeoUtils";
import proj4, { InterfaceCoordinates } from "proj4";
import { Vector3 } from "three";

export class Proj4Projection implements Projection {
    private _bounds!: Bounds;
    private proj4: proj4.Converter;

    constructor(toProj: string, fromProj?: string) {
        this.proj4 = proj4(fromProj ? fromProj : GeoUtils.epsg(4326), toProj);
    }

    public get name(): string {
        return "Proj4Projection";
    }

    public project(inp: Vector3, reuse?: Vector3): Vector3 {
        if (reuse == null) {
            reuse = new Vector3();
        }

        reuse.copy(this.proj4.forward(inp as InterfaceCoordinates) as Vector3);
        return reuse;
    }

    public unproject(inp: Vector3, reuse?: Vector3): Vector3 {
        if (reuse == null) {
            reuse = new Vector3();
        }
        reuse.copy(this.proj4.inverse(inp as InterfaceCoordinates) as Vector3);
        return reuse;
    }

    public get bounds(): Bounds {
        return this._bounds;
    }

    public set bounds(value: Bounds) {
        this._bounds = value;
    }
}