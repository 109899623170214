import { Layer3d } from "gis3d/cityvu/core/three/scene/Layer3d";
import { Cityvu3DScene } from "gis3d/cityvu/core/three/scene/Cityvu3DScene";

export class LayerManager {
    private _layerSourceLayer: Map<string, Map<string, Layer3d>>;
    private _layerActiveSource: Map<string, string>;
    private _activeLayers: Set<string>;

    public constructor(readonly scene: Cityvu3DScene) {
        this._layerSourceLayer = new Map();
        this._layerActiveSource = new Map();
        this._activeLayers = new Set();
    }

    public register(layerId: string, layerSourceId: string, layer3d: Layer3d): void {
        if (!this.layerSourceLayer.has(layerId)) {
            this.layerSourceLayer.set(layerId, new Map());
        }
        const map = this.layerSourceLayer.get(layerId)!;
        map.set(layerSourceId, layer3d);
        this.scene.add(layer3d);
    }

    public unregister(layerId: string, layerSourceId?: string): void {
        if (layerSourceId != null) {
            const map = this.layerSourceLayer.get(layerId);
            if (map) {
                const l3d = map.get(layerSourceId);
                if (l3d != null) {
                    this.scene.remove(l3d);
                    map.delete(layerSourceId);
                }
            }
            // TODO should check for hanging reference on layerSourceId. Postponed.
        } else {
            this.getLayer3dMappings(layerId).forEach(l3d => this.scene.remove(l3d));
            this.layerSourceLayer.delete(layerId);
            this.layerActiveSource.delete(layerId);
            this.activeLayers.delete(layerId);
        }
    }

    public get activeLayers(): Set<string> {
        return this._activeLayers;
    }

    public get layerSourceLayer(): Map<string, Map<string, Layer3d>> {
        return this._layerSourceLayer;
    }

    public get layerActiveSource(): Map<string, string> {
        return this._layerActiveSource;
    }

    public getLayer3dMapping(layerId: string, layerSourceId: string): Layer3d | undefined {
        const sourceMap = this.layerSourceLayer.get(layerId);
        if (sourceMap != null) {
            return sourceMap.get(layerSourceId);
        }
        return undefined;
    }

    public getLayer3dMappings(layerId: string): Array<Layer3d> {
        const sourceMap = this.layerSourceLayer.get(layerId);
        if (sourceMap != null) {
            return Array.from(sourceMap.values());
        }
        return [];
    }

    public setVisibility(layerId: string, visible: boolean): void {
        if (visible) {
            const activeLayer3d = this.getActiveLayer3d(layerId);
            if (activeLayer3d) {
                activeLayer3d.visible = true;
            }
            this.activeLayers.add(layerId);
        } else {
            const layer3ds = this.getLayer3dMappings(layerId);
            layer3ds.forEach(l3d => {
                l3d.visible = false;
            });
            this.activeLayers.delete(layerId);
        }
    }

    public selectSource(layerId: string, layerSourceId: string): void {
        const newActiveLayer3d = this.getLayer3dMapping(layerId, layerSourceId);
        if (newActiveLayer3d) {
            // hide old source
            const oldActiveLayer3d = this.getActiveLayer3d(layerId);
            if (oldActiveLayer3d) {
                oldActiveLayer3d.visible = false;
            } else {
                this.getLayer3dMappings(layerId).forEach(l3d => (l3d.visible = false));
            }
            // set new
            this.layerActiveSource.set(layerId, layerSourceId);
            // I must check if parent layer is visible in definition
            if (this.activeLayers.has(layerId)) {
                newActiveLayer3d.visible = true;
            }
        }
    }

    public getActiveLayer3d(layerId: string): Layer3d | undefined {
        const srcId = this.layerActiveSource.get(layerId);
        if (srcId != null) {
            return this.getLayer3dMapping(layerId, srcId);
        }
        return undefined;
    }
}
