export class CookieManager {

    public set(name: string, value: string, path?: string, duration?: number, domain?: string) : void {
        let cookieValue = name + "=" + encodeURIComponent(value);

        if (path) {
            cookieValue += " ;path=" + path;
        }

        if (duration && duration > 0) {
            cookieValue += " ;max-age=" + duration;
        }

        if (domain) {
            cookieValue += " ;domain=" + domain;
        }

        document.cookie = cookieValue;
    }


    public get(name: string) : string | null {
        const nameLenPlus = (name.length + 1);

        return document.cookie
            .split(";")
            .map(c => c.trim())
            .filter(cookie => {
                return cookie.substring(0, nameLenPlus) === `${name}=`;
            })
            .map(cookie => {
                return decodeURIComponent(cookie.substring(nameLenPlus));
            })[0] || null;
    }

    public delete(name: string) : void {
        document.cookie = name + "=; Max-Age=0";
    }
}