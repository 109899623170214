import { BaseUiComponent } from "gis3d/wf/ui/BaseUiComponent";
import ui from "gis3d/wf/ui/style/UiStyle";
import { StatsOptions } from "./StatsOptions";
import { StatsData } from "./StatsData";
import { StatsWidget } from "./StatsWidget";
import DomUtils from "../DomUtils";

export class StatsPanel extends BaseUiComponent {
    protected readonly WDG_FPS: string = "FPS";
    protected readonly WDG_TPF: string = "TPF";
    protected readonly WDG_MEM: string = "MEM";
    protected widgets: Map<string, StatsWidget>;

    public constructor(readonly opts: StatsOptions) {
        super();
        this.domElementOptions.classes = [ui.p("StatsPanel")];
        this.widgets = new Map();
    }

    public build(): void {
        super.build();
        if (this.opts.fps) {
            const w = new StatsWidget({
                title: "FPS",
                fgColor: "#FFFF00",
                bgColor: "#333333",
            });
            this.widgets.set(this.WDG_FPS, w);
            w.init();
            DomUtils.append(this.domNode!, w.domNode!);
        }

        if (this.opts.timePerFrame) {
            const w = new StatsWidget({
                title: "MS",
                fgColor: "#00FF00",
                bgColor: "#333333",
            });
            this.widgets.set(this.WDG_TPF, w);
            w.init();
            DomUtils.append(this.domNode!, w.domNode!);
        }

        if (this.opts.memory) {
            const w = new StatsWidget({
                title: "MB",
                fgColor: "#0099FF",
                bgColor: "#333333",
            });
            this.widgets.set(this.WDG_MEM, w);
            w.init();
            DomUtils.append(this.domNode!, w.domNode!);
        }
    }

    public update(data: StatsData): any {
        if (this.isStarted()) {
            if (this.opts.fps) {
                const w = this.widgets.get(this.WDG_FPS)!;
                w.update(data.fps, 70);
            }

            if (this.opts.timePerFrame) {
                const w = this.widgets.get(this.WDG_TPF)!;
                w.update(data.timePerFrame, 100);
            }

            if (this.opts.memory) {
                const w = this.widgets.get(this.WDG_MEM)!;
                w.update(data.memoryHeap, data.memoryLimit);
            }
        }
    }
}
