import runtime from "gis3d/wf/util/RuntimeUtils";
import dom from "gis3d/wf/util/DomUtils";

export default class On {
    public static readonly listen = (target:any, type:string, listener:Function, useCapture:boolean = false, customAddListener?:Function) : Function => {
        if (customAddListener == null) {
            if (target.addEventListener) {
                target.addEventListener(type, listener, useCapture);
                return () => target.removeEventListener(type, listener, useCapture);
            }
            throw new Error("Target has not custom listener nor addEventListener method");
        } 
        return customAddListener!(target, type, listener, useCapture);
    }

    public static readonly emit = (target:any, type:string, event:any, ...args:any[]) : boolean => {
        if (target.dispatchEvent && dom.doc().createEvent) {
            // lets use native events
            let owner = target.ownerDocument || dom.doc();
            let nativeEvent = owner.createEvent("HTMLEvents");
            nativeEvent.initEvent(type, !!event.bubbles, !!event.cancelable);
            runtime.extend(nativeEvent, event);
            return target.dispatchEvent(nativeEvent) && nativeEvent;
        }
        // simulate using custom events
        const method = "on" + type;
        // check for dom node
        if ("parentNode" in target) {
            const newEvent : any = runtime.extend({}, event);
            newEvent.preventDefault = () => {
                newEvent.cancelable = false;
                newEvent.defaultPrevented = true;
            };
            newEvent.stopPropagation = () => {
                newEvent.bubbles = false;
            };
            newEvent.target = target;
            newEvent.type = type;
            event = newEvent;
        }

        let eventArgs : Array<any> = [ event ];
        if (args != null) {
            eventArgs.push.apply(eventArgs, args);
        }

        do {
            target[method] && target[method].apply(target, eventArgs);
            // prepare for bubbling
            target = target.parentNode;
        } while (event && event.bubbles && target != null);

        return event && event.cancelable;
    }
}