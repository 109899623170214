import { AnimatedControls } from "gis3d/cityvu/core/three/controls/AnimatedControls";

// TODO keep ConstrainedControls for last
export class ConstrainedControls extends AnimatedControls {
    protected userInput: boolean = false;

    public enable(): void {
        this.stop();
        this.attachKeyboardEvents();
        this.attachMouseEvents();
    }

    protected attachMouseEvents() {
        //this.addEventListener(this.drag, InputManagerEvent.DRAG);
        //this.addEventListener(this.drop, InputManagerEvent.DROP);
        //this.addEventListener(this.wheel, InputManagerEvent.MOUSEWHEEL);
    }

    protected attachKeyboardEvents() {
        // this.addEventListener(this.keyDown, InputManagerEvent.KEYDOWN);
        // this.addEventListener(this.keyUp, InputManagerEvent.KEYUP);
    }

    public disable(): void {
        this.removeEventListeners();
    }

    public stop(): void {
        super.stop();
        // this.rotationDelta.set(0, 0, 0);
        // this.panDelta.set(0, 0, 0);
        // this.worldDelta.set(0, 0, 0);
    }

    public update(delta: number): void {
        if (this.scene == null) {
            return;
        }

        // remove automations if user input has been detected
        if (this.userInput) {
            this.clearTweens();
            this.userInput = false;
        }
    }
}