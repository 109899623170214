import { Bounds } from "gis3d/cityvu/core/geometry/Bounds";
import { FixedGrid } from "gis3d/cityvu/core/geometry/FixedGrid";
import { Tile } from "gis3d/cityvu/core/geometry/Tile";
import { Layer3d } from "gis3d/cityvu/core/three/scene/Layer3d";
import { HashSet } from "gis3d/cityvu/core/util/HashSet";
import { Pool } from "gis3d/cityvu/core/util/Pool";
import NumberUtils from "gis3d/wf/util/NumberUtils";
import { Vector3 } from "three";

export class FixedGridLayer3d extends Layer3d {
    protected pool: Pool<Tile>;
    private _grid!: FixedGrid;

    protected cameraLastPosition: Vector3 = new Vector3();
    protected cameraLastYaw: number = Infinity;
    protected cameraBounds: Bounds = new Bounds();
    protected cameraBuffer: Vector3 = new Vector3(100, 100, 100);
    protected shouldUpdateTiles: boolean = true;
    protected cameraThreshold: number = NumberUtils.EPSILON2;

    protected activeTiles: HashSet<Tile> = new HashSet<Tile>();
    protected toBeRemovedTiles: HashSet<Tile> = new HashSet<Tile>();

    public constructor(pool?: Pool<Tile>) {
        super();
        this.pool = pool != null ? pool : new Pool<Tile>(Tile, 32, 16);
    }

    public onEngineAssigment(): void {
        if (this.grid == null) {
            throw Error("fixedGridLayer3d.error.missingGrid");
        }
        super.onEngineAssigment();
    }

    public get grid(): FixedGrid {
        return this._grid;
    }

    public set grid(value: FixedGrid) {
        this._grid = value;
    }

    public update(delta: number): void {
        const pose = this.engine.scene.pose;
        const cPos = pose.position;
        this.shouldUpdateTiles = this.shouldUpdateTiles || cPos.distanceToSquared(this.cameraLastPosition) > this.cameraThreshold;
        if (this.shouldUpdateTiles) {
            this.cameraLastPosition.copy(cPos);
            this.cameraLastYaw = pose.yaw;
            this.cameraBounds.setFromCenterAndSize(cPos, this.cameraBuffer);
            const tileChanged = this.grid.updateTilesInBounds(this.cameraBounds, this.pool, this.activeTiles, this.toBeRemovedTiles);
            this.onTileUpdate(tileChanged);
            for (const t of this.toBeRemovedTiles.items()) {
                this.pool.release(t);
            }
            this.toBeRemovedTiles.clear();
            this.shouldUpdateTiles = false;
        } else {
            if (Math.abs(this.cameraLastYaw - pose.yaw) > NumberUtils.EPSILON6) {
                this.onTileUpdate(true);
                this.cameraLastYaw = pose.yaw;
            }
        }
    }

    protected onTileUpdate(tileChanged: boolean): void {}
}
