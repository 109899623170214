import { SignalingAction } from "./SignalingAction";
import { SignalingMessage } from "gis3d/wf/net/rtc/SignalingMessage";
import TokenManager from "gis3d/cityvu/core/util/TokenManager";

export class SignalingMessageFactory {
    private application: any;
    private userId: any;
    private windowId: any;
    private targetApplication: any;
    private targetUserId: any;
    private targetWindowId: any;

    public configureSender(app:string, uid:any, wid:any) {
        this.application = app;
        this.userId = uid;
        this.windowId = wid;
    }

    public configureDestination(app:string, uid:any, wid:any) {
        this.targetApplication = app;
        this.targetUserId = uid;
        this.targetWindowId = wid;
    }

    public hasDestination() : boolean {
        return this.targetApplication != null && this.targetUserId != null;
    }

    public get(action : SignalingAction = SignalingAction.REGISTER) : SignalingMessage {
        const m = new SignalingMessage();
        m.action = action;
        m.application = this.application;
        m.userId = this.userId;
        m.windowId = this.windowId;
        m.token = TokenManager.token;
        if (action != SignalingAction.REGISTER && action != SignalingAction.UNREGISTER) {
            m.targetApplication = this.targetApplication;
            m.targetUserId = this.targetUserId;
            m.targetWindowId = this.targetWindowId;
        }
        return m;
    }
}