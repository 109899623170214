import { PointCloudOctree, Potree } from "./potree";
import { Camera, WebGLRenderer } from "three";
import { Pool } from "../../util/Pool";
import { Features } from "gis3d/wf/util/Features";

export class CloudEngine {
    private _pointBudget: number = 3 * 1000 * 1000;
    private _nodeLoadingConcurrency: number = 4;
    private _edl: boolean = false;
    private _edlRadius: number = 1.4;
    private _edlStrenght: number = 0.4;
    private _pointClouds: Array<PointCloudOctree>;
    private _potree: Potree;
    private _binaryDecoderPool: Pool<Worker>;

    constructor() {
        this._pointClouds = [];
        this._potree = new Potree();
        this.potree.pointBudget = this.pointBudget;
        this.potree.maxNumNodesLoading = this.nodeLoadingConcurrency;
        // thread pool
        const binaryDecoderCtor = require("worker-loader?inline!gis3d/cityvu/core/three/cloud/potree/workers/binary-decoder-worker.js");
        const coreNum = Features.cpuCores;
        this._binaryDecoderPool = new Pool<Worker>(binaryDecoderCtor, coreNum, coreNum);
        this._binaryDecoderPool.infinite = false;
    }

    public add(pco: PointCloudOctree): void {
        this.pointClouds.push(pco);
    }

    public remove(pco: PointCloudOctree, dispose: boolean = true): void {
        this._pointClouds = this.pointClouds.filter(p => p !== pco);
        if (dispose) {
            pco.dispose();
        }
    }

    public clear(): void {
        this.pointClouds.forEach(pco => {
            pco.dispose();
        });

        this._pointClouds = [];
    }

    public update(camera: Camera, renderer: WebGLRenderer): void {
        this.potree.updatePointClouds(this.pointClouds, camera, renderer);
    }

    public get potree(): Potree {
        return this._potree;
    }

    public get pointClouds(): Array<PointCloudOctree> {
        return this._pointClouds;
    }

    public get pointBudget(): number {
        return this._pointBudget;
    }

    public set pointBudget(value: number) {
        this._pointBudget = value;
        this.potree.pointBudget = value;
    }

    public get nodeLoadingConcurrency(): number {
        return this._nodeLoadingConcurrency;
    }

    public set nodeLoadingConcurrency(value: number) {
        this._nodeLoadingConcurrency = value;
        this.potree.maxNumNodesLoading = value;
    }

    public get edl(): boolean {
        return this._edl;
    }

    public set edl(value: boolean) {
        this._edl = value;
    }

    public get edlRadius(): number {
        return this._edlRadius;
    }

    public set edlRadius(value: number) {
        this._edlRadius = value;
    }

    public get edlStrenght(): number {
        return this._edlStrenght;
    }

    public set edlStrenght(value: number) {
        this._edlStrenght = value;
    }

    public get binaryDecoderPool(): Pool<Worker> {
        return this._binaryDecoderPool;
    }
}
